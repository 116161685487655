import { useState, useCallback, useSyncExternalStore } from 'react';
import { IconSparkles } from '@tabler/icons-react';
import {
  assetsTextDestroy,
  assetsSavedSearchRelevantSearchSourcesV1Retrieve,
  assetsSavedSearchGenerateAssetsCreate
} from '@goldcast/api/content';
import PostListItem from './PostListItem';
import { PostListProps, TextAsset } from '../types';
import { POST_TYPE_LABELS, POST_TYPE_ICONS, POST_TYPES } from '../constants';
import PostListSkeletonLoader from './PostListSkeletonLoader';
import PostAssetGenerationEmptyState from './PostAssetGenerationEmptyState';
import GenerateMoreAITextButton from './GenerateMoreAITextButton';
import Button from '@/components/atoms/Button/Button';
import Icon from '@/components/atoms/Icon';
import { classnames } from '@/libs/utils';
import useElementScrolledHook from '@/hooks/useElementScrolledHook';
import IconButton from '@/components/atoms/Button/IconButton';
import AssetRatingDialog from '@/components/molecules/AssetRating/AssetRatingDialog';
import useSavedSearch from '@/hooks/useSavedSearch';
import SourceSelectionDialog from '@/Pages/Sessions/RecentEvents/AiClips/SourceSelectionDialog';
import useDialog from '@/components/organisms/useDialog';
import { currentUser } from '@/stores/user';
import { savedAISearch } from '@/stores/savedAISearch';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';
import { AI_SEARCH_ERROR_CODES } from '@/Pages/Sessions/RecentEvents/AiClips/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export default function PostList({
  posts,
  selectedPost,
  postType,
  isGeneratingPost,
  isLoading,
  isCollapsed,
  onDelete,
  onPostSelected,
  onGeneratePost,
  toggleCollapse,
  isGeneratingAssets,
  refetch
}: PostListProps) {
  const [relevantSourceList, setRelevantSourceList] = useState({
    contents: [],
    search_term: '',
    metadata: null
  });
  const [generatingMoreClip, setGeneratingMoreClip] = useState(false);

  const { isScrolled, handleOnScroll } = useElementScrolledHook();
  const { isSavedSearchPage } = useSavedSearch();
  const savedAiSearchStore = useSyncExternalStore(savedAISearch.subscribe, savedAISearch.getSnapshot);

  const { logger } = useAppContext();

  const {
    isOpen: isSourceSelectionDialogOpen,
    closeDialog: closeSourceSelectionDialog,
    openDialog: openSourceSelectionDialog
  } = useDialog();

  const deletePost = async (postId: string) => {
    assetsTextDestroy({ id: postId }).then(() => {
      onDelete(postId);
    });
  };

  const onGenerateMoreClip = useCallback(() => {
    return assetsSavedSearchRelevantSearchSourcesV1Retrieve({
      queryParams: {
        saved_search: savedAISearch.getSnapshot()!.id,
        organization: currentUser.getSnapshot()?.organization as string,
        generation_type: POST_TYPES[postType]
      }
    })
      .then((sourceList: any) => {
        setRelevantSourceList(sourceList);
        openSourceSelectionDialog();
      })
      .catch(err => {
        if (err && err.toString() === AI_SEARCH_ERROR_CODES.RELEVANT_SOURCES_NOT_FOUND) {
          showErrorToast('No relevant sources found.');
        }
        closeSourceSelectionDialog();
      });
  }, [savedAISearch, currentUser, openSourceSelectionDialog, closeSourceSelectionDialog]);

  const handleSelectedSource = useCallback(
    (selectedSource: string[]) => {
      setGeneratingMoreClip(true);
      assetsSavedSearchGenerateAssetsCreate({
        id: savedAiSearchStore!.id,
        queryParams: {
          limit: 5
        },
        body: { generation_type: POST_TYPES[postType], selected_sources: selectedSource }
      })
        .then(() => {
          onPostSelected(null);
          refetch();
          EventBus.dispatch(CustomEvents.ReloadAllClips);
        })
        .catch(e => {
          showErrorToast(
            'Whoops! We encountered an issue while attempting to generate more Social Posts. Please try again.'
          );
          logger.error(`Error generating more AI Social Posts: ${e}`);
        })
        .finally(() => {
          closeSourceSelectionDialog();
          setGeneratingMoreClip(false);
        });
    },
    [refetch, savedAiSearchStore, closeSourceSelectionDialog, onPostSelected]
  );

  return (
    <>
      <div className="absolute bottom-0 h-[69vh] w-full bg-gradient-to-t from-slate-950/[0.13] to-slate-950/0 blur"></div>
      <div className="z-20 flex w-full shrink-0 flex-col items-stretch overflow-hidden rounded-2xl border !border-slate-100 bg-white shadow-xl shadow-slate-200/10 transition-all duration-[400ms]">
        <div className="flex h-full flex-col">
          <div
            className={classnames(
              'sticky top-0 z-20 flex h-14 w-full items-center justify-between bg-white/90 p-4 px-5 backdrop-blur transition-all duration-150 ease-in-out',
              {
                'shadow-lg shadow-slate-900/[0.075]': isScrolled
              }
            )}
          >
            <div className="flex items-center space-x-2">
              <span className="text-lg font-medium">{POST_TYPE_LABELS[postType]}</span>
              {!isLoading && (
                <span className="inline-flex rounded-full bg-slate-200 px-2.5 py-0.5 text-xs font-medium">
                  {posts.length}
                </span>
              )}
            </div>
            <div className="flex space-x-1.5">
              <IconButton
                icon={isCollapsed ? 'IconArrowBarRight' : 'IconArrowBarLeft'}
                title={isCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
                variation="text"
                trackingId="clips-list-collapse-button"
                onClick={toggleCollapse}
              />
            </div>
          </div>
          <div className="flex grow flex-col overflow-y-auto overflow-x-hidden" onScroll={handleOnScroll}>
            {isLoading ? (
              <PostListSkeletonLoader isCollapsed={isCollapsed} />
            ) : (
              <>
                {!posts.length &&
                  (!isGeneratingAssets || isSavedSearchPage ? (
                    <div className="flex h-full w-full grow items-center justify-center">
                      <div className="">
                        <div className="flex w-full justify-center">
                          <Icon name={POST_TYPE_ICONS[postType]} className="h-7 w-7 text-slate-400" />
                        </div>
                        <div className="mt-2.5 px-4 text-center text-sm text-slate-400">
                          <div className="font-medium">No {POST_TYPE_LABELS[postType]} post, yet.</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <PostAssetGenerationEmptyState postType={postType} />
                  ))}
              </>
            )}
            {posts.length && !isLoading ? (
              posts.map((post: TextAsset) => (
                <div key={post.id}>
                  <PostListItem
                    post={post}
                    selected={selectedPost?.id === post.id}
                    isCollapsed={isCollapsed}
                    handleSelectedPost={onPostSelected}
                    onDelete={deletePost}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          {isSavedSearchPage ? (
            <GenerateMoreAITextButton
              generatePost={onGenerateMoreClip}
              buttonTextContent={!posts.length ? 'Generate Post' : 'Generate More'}
            />
          ) : (
            !isGeneratingAssets && (
              <div className="flex items-center justify-center border-t bg-white px-6 py-3">
                <Button
                  className="h-12 w-full px-6 py-2 !text-sm"
                  variation="filled"
                  onClick={onGeneratePost}
                  trackingId="generate-post-click"
                  disabled={isGeneratingPost}
                >
                  <IconSparkles size={24} stroke={2} className="mr-1" /> Generate {POST_TYPE_LABELS[postType]} Post
                </Button>
              </div>
            )
          )}
        </div>
      </div>
      <AssetRatingDialog />
      <SourceSelectionDialog
        isOpen={isSourceSelectionDialogOpen}
        sourceList={relevantSourceList?.contents}
        isSearching={generatingMoreClip}
        closeDialog={closeSourceSelectionDialog}
        onSourceChanged={handleSelectedSource}
      />
    </>
  );
}
