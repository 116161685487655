import { useMemo } from 'react';
import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { Transition } from '@headlessui/react';
import moment from 'moment';
import { ContentStatesEnum, IconMap } from './constants';
import { VideoImportProgressToastProps } from './types';
import IconButtonGroup from '@/components/atoms/Button/IconButtonGroup';
import { classnames } from '@/libs/utils';

export default function VideoImportProgressToast({
  type,
  onCancel,
  message,
  isVisible,
  title,
  activeState,
  totalPercentageUploaded,
  currentProcessingTime
}: VideoImportProgressToastProps) {
  const Icon = IconMap[type];

  const { isActive, isProcessing, isGenerating, isUploading } = useMemo(() => {
    return {
      isActive: type === activeState,
      isProcessing: type === ContentStatesEnum.Processing,
      isGenerating: type === ContentStatesEnum.Generating,
      isUploading: type === ContentStatesEnum.Uploading
    };
  }, [type, activeState]);

  const { iconClassName, backgroundClassName, zIndexClassName, translateClassName } = useMemo(() => {
    return {
      iconClassName: classnames({
        'text-slate-300': !isActive,
        'animate-spin text-green-500': isActive && isProcessing,
        'animate-pulse': isActive && isGenerating
      }),
      backgroundClassName: classnames({
        'bg-white': isActive,
        'bg-white/90 backdrop-blur': isProcessing && !isActive,
        'bg-white/70 backdrop-blur': isGenerating && !isActive
      }),
      zIndexClassName: classnames({
        'z-30': isUploading,
        'z-20': isProcessing,
        'z-10': isGenerating
      }),
      translateClassName: classnames({
        'mt-2.5 -translate-y-6': isProcessing,
        'scale-[0.97]': isProcessing && !isActive,
        'scale-[1]': isProcessing && isActive
      })
    };
  }, [isActive, isProcessing, isGenerating, isUploading]);

  const humanizedDuration = useMemo(() => {
    return moment.duration(currentProcessingTime, 'seconds').humanize();
  }, [currentProcessingTime]);

  return (
    <Transition
      appear
      show={isVisible}
      className={classnames(
        'relative w-full overflow-hidden transition-all duration-500',
        zIndexClassName,
        translateClassName,
        { 'mt-3': !isUploading }
      )}
      enterFrom="transform opacity-0 max-h-0"
      enterTo="transform opacity-100 max-h-96"
      leaveFrom="transform opacity-100 max-h-96"
      leaveTo="transform opacity-0 max-h-0"
    >
      <div className="flex w-full flex-col justify-center px-12">
        <div
          className={classnames('pointer-events-auto rounded-lg border p-4', backgroundClassName, {
            '!p-3': isUploading
          })}
        >
          <div
            className={classnames('flex w-full items-center justify-between transition-all duration-150 ease-in-out')}
          >
            <div
              className={classnames('flex items-center space-x-2', {
                'flex-col !items-start !space-x-0': isUploading
              })}
            >
              {Icon && <Icon size={24} stroke={2} className={iconClassName} />}
              <span
                className={classnames('text-sm font-medium', {
                  'text-slate-400': !isActive,
                  'animate-pulse': isGenerating && isActive
                })}
              >
                {title}
                {(isProcessing || isGenerating) &&
                  isActive &&
                  (!!currentProcessingTime || currentProcessingTime === 0) && (
                    <span className="ml-2 text-slate-400">
                      {currentProcessingTime > 1
                        ? ` ~ ${humanizedDuration} remaining`
                        : '~ Taking longer than expected'}
                    </span>
                  )}
                {isUploading && isActive && (
                  <span className="ml-2 text-slate-400">{`${totalPercentageUploaded}%`}</span>
                )}
              </span>
              {isUploading && isActive && (
                <div className="mt-1 flex items-start text-xs font-normal text-slate-600">
                  <IconInfoCircle size={16} stroke={2} className="mr-1 text-slate-400" />
                  {message}
                </div>
              )}
            </div>
            {!isUploading && isActive && (
              <div className="mt-1 flex items-center text-xs font-normal text-slate-600">
                <IconInfoCircle size={16} stroke={2} className="mr-1.5 text-slate-400" />
                {message}
              </div>
            )}
            {isUploading && onCancel && (
              <div className="inline-flex h-8 divide-x rounded-lg text-xs">
                <IconButtonGroup
                  variation="list"
                  buttons={[
                    {
                      content: '',
                      description: 'Cancel',
                      icon: IconX,
                      action: onCancel,
                      trackingId: 'generate-cancel-button'
                    }
                  ]}
                />
              </div>
            )}
          </div>
          {isUploading && (
            <div className="mt-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
              <div
                className="h-2 rounded-full bg-deep-orange p-0.5 text-center text-xs font-medium leading-none text-blue-100 transition-all duration-500 ease-in-out"
                style={{ width: `${totalPercentageUploaded}%` }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
}
