import { useCallback, useSyncExternalStore, useMemo } from 'react';
import {
  TextTypeEnum,
  multiModalTextToClipCreate,
  ClipAssetResponse,
  ClipInfo,
  assetsTextTextToClipForSavedSearchCreate
} from '@goldcast/api/content';

import { postState, PostState, ClipAssetMetadata } from '@/stores/postStore';
import { extractClipObjectFromText, createPostTextFromClipObject } from '@/hooks/utils/postHookUtil';
import { TextAsset } from '@/Pages/PostsListPage/types';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export default function usePost(): {
  isReplaceClipDialogOpen: boolean;
  clips: { [id: string]: any };
  selectedBlockId: string;
  selectedPost: TextAsset | null;
  openReplaceClipDialog: (blockId?: string) => void;
  closeReplaceClipDialog: () => void;
  extractClipDetailsFromPostText: (blockId: string, text: string) => void;
  onActiveClipChange: (blockId: string, activeClipIndex: number) => string;
  removeClipFromPost: (blockId: string) => void;
  updateClipDetails: (blockId: string, updateClipDetails: ClipAssetMetadata) => string;
  textToClipGeneration: (text: string) => Promise<string>;
  handleSelectedPostChange: (selectedPost: TextAsset | null) => void;
  getClipAssetIds: (clipDetails) => string[];
  getSelectedClipId: (clipDetails) => string | undefined;
  getUpdatedAssetOnReplace: (
    selectedClip,
    postAssetMetaData
  ) => {
    clip_asset_ids: string[];
    currentClipIndex?: number;
  };
  onBlogClipSelection: (selectedClip) => void;
  textToClipSavedSearchGeneration: (text: string) => Promise<string>;
  createClipFromTextForSavedSearch: (text: string) => Promise<ClipAssetResponse>;
} {
  const postStore = useSyncExternalStore(postState.subscribe, postState.getSnapshot);

  const clipMetaData = useMemo(() => postStore.clips, [postStore.clips]);
  const selectedPost = useMemo(() => postStore.selectedPost, [postStore.selectedPost]);

  const extractClipDetailsFromPostText = useCallback((blockId: string, text: string) => {
    const clipDetails = extractClipObjectFromText(text);
    if (!clipDetails) return;

    postState.update((state: PostState) => {
      state.clips[blockId] = clipDetails;
    });
  }, []);

  const handleSelectedPostChange = (selectedPost: TextAsset | null) => {
    postState.update((state: PostState) => {
      state.selectedPost = selectedPost;
    });
  };

  const onActiveClipChange = (blockId: string, currentClipIndex: number) => {
    const { clips } = postStore;
    const selectedClip = { ...clips[blockId] };
    selectedClip.currentClipIndex = currentClipIndex;

    postState.update((state: PostState) => {
      state.clips[blockId] = selectedClip;
    });

    return createPostTextFromClipObject(selectedClip);
  };

  const updateClipDetails = useCallback((blockId: string, updatedClipDetails: ClipAssetMetadata) => {
    postState.update((state: PostState) => {
      state.clips[blockId] = updatedClipDetails;
    });

    return createPostTextFromClipObject(updatedClipDetails);
  }, []);

  const textToClipGeneration = (text: string) => {
    const { selectedPost } = postStore;
    if (!selectedPost) return Promise.resolve(text);
    const textToClipRequestBody = {
      content_id: selectedPost.content?.id || '',
      content_type: 'BLOG' as TextTypeEnum,
      organization_id: selectedPost.content?.organization || '',
      project_id: selectedPost?.content?.project.id || '',
      text_or_prompt: text,
      num_clips: 1
    };
    return multiModalTextToClipCreate({
      queryParams: {},
      body: textToClipRequestBody
    })
      .then((response: any) => {
        const clipAssetIds = [] as string[];
        const nextClipInfo = [] as number[][];
        response.clips.forEach((clip: ClipAssetResponse) => {
          clipAssetIds.push(clip.id);
        });
        response.next_clip_info.forEach((next_clip_info: ClipInfo) => {
          nextClipInfo.push([next_clip_info.start_time, next_clip_info.end_time]);
        });
        const newClipObject = {
          clip_asset_ids: clipAssetIds,
          currentClipIndex: 0,
          activeClipIndex: 0,
          next_clip_info: nextClipInfo
        };
        const updatedText = createPostTextFromClipObject(newClipObject);
        EventBus.dispatch(CustomEvents.ReloadAllClips);
        return updatedText;
      })
      .catch(err => {
        throw err;
      });
  };

  const removeClipFromPost = useCallback((blockId: string) => {
    postState.update((state: PostState) => {
      delete state.clips[blockId];
    });
  }, []);

  const openReplaceClipDialog = useCallback(blockId => {
    postState.update((state: PostState) => {
      state.isReplaceClipDialogOpen = true;
      state.selectedBlockId = blockId;
    });
  }, []);

  const closeReplaceClipDialog = useCallback(() => {
    postState.update((state: PostState) => {
      state.isReplaceClipDialogOpen = false;
    });
  }, []);

  const onBlogClipSelection = useCallback(
    selectedClip => {
      const { selectedBlockId, clips } = postStore;
      const updatedAssetMetadata = {
        ...clips[selectedBlockId],
        ...getUpdatedAssetOnReplace(selectedClip, clips[selectedBlockId])
      };
      updateClipDetails(selectedBlockId, updatedAssetMetadata);
      closeReplaceClipDialog();
    },
    [postStore]
  );

  const getClipAssetIds = useCallback((clipDetails: ClipAssetMetadata) => {
    if (!clipDetails) return [];
    const { clip_asset_ids, activeClipIndex } = clipDetails || {};
    return clip_asset_ids?.slice(0, activeClipIndex + 1) || [];
  }, []);

  const getSelectedClipId = useCallback((clipDetails: ClipAssetMetadata) => {
    if (!clipDetails) return undefined;
    const { clip_asset_ids, currentClipIndex, activeClipIndex } = clipDetails || {};
    if (!clip_asset_ids?.length) {
      return undefined;
    }

    const selectedClipIndex = currentClipIndex ?? activeClipIndex;
    return clip_asset_ids?.[selectedClipIndex];
  }, []);

  const getUpdatedAssetOnReplace = useCallback((selectedClip, postAssetMetaData) => {
    const { clip_asset_ids, currentClipIndex, activeClipIndex } = postAssetMetaData;
    let updatedAssetMetadata = {} as { clip_asset_ids: string[]; currentClipIndex?: number };
    if (!clip_asset_ids || clip_asset_ids?.length === 0) {
      updatedAssetMetadata = {
        clip_asset_ids: [selectedClip.id],
        currentClipIndex: 0
      };
      return updatedAssetMetadata;
    }
    let updatedClipAssetIds = [...clip_asset_ids];
    const clipIndex = currentClipIndex ?? activeClipIndex;
    updatedClipAssetIds[clipIndex] = selectedClip.id;
    updatedAssetMetadata = {
      clip_asset_ids: updatedClipAssetIds
    };

    return updatedAssetMetadata;
  }, []);

  const createClipFromTextForSavedSearch = useCallback((text: string) => {
    const textToClipRequestBody = {
      asset: selectedPost?.id ?? '',
      text: text
    };
    return assetsTextTextToClipForSavedSearchCreate({
      queryParams: {},
      body: textToClipRequestBody
    });
  }, []);

  const textToClipSavedSearchGeneration = useCallback(async (text: string) => {
    const { selectedPost } = postStore;
    if (!selectedPost) return Promise.resolve(text);
    try {
      const clipAssetResponse = await createClipFromTextForSavedSearch(text);
      const newClipObject = {
        clip_asset_ids: [clipAssetResponse.id],
        currentClipIndex: 0,
        activeClipIndex: 0
      };
      const updatedText = createPostTextFromClipObject(newClipObject);
      EventBus.dispatch(CustomEvents.ReloadAllClips);
      return updatedText;
    } catch (error) {
      throw error;
    }
  }, []);

  return {
    isReplaceClipDialogOpen: postStore.isReplaceClipDialogOpen,
    clips: clipMetaData,
    selectedBlockId: postStore.selectedBlockId,
    selectedPost,
    openReplaceClipDialog,
    closeReplaceClipDialog,
    extractClipDetailsFromPostText,
    onActiveClipChange,
    removeClipFromPost,
    updateClipDetails,
    getClipAssetIds,
    getSelectedClipId,
    textToClipGeneration,
    handleSelectedPostChange,
    getUpdatedAssetOnReplace,
    onBlogClipSelection,
    textToClipSavedSearchGeneration,
    createClipFromTextForSavedSearch
  };
}
