export const PORTRAIT_COMPILED_CONFIG = {
  GRID: {
    FIT: {
      'with-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '22.708%',
              left: '1.481%',
              width: '97.037%',
              height: '54.583%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.562%',
              left: '1.481%',
              width: '47.777%',
              height: '26.875%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.562%',
              left: '50.74%',
              width: '47.777%',
              height: '26.875%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '22.384%',
              left: '1.481%',
              width: '47.777%',
              height: '26.875%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '22.384%',
              left: '50.74%',
              width: '47.777%',
              height: '26.875%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '26.111%',
              width: '47.777%',
              height: '26.875%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '22.384%',
              left: '1.481%',
              width: '47.777%',
              height: '26.875%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '22.384%',
              left: '50.74%',
              width: '47.777%',
              height: '26.875%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '1.481%',
              width: '47.777%',
              height: '26.875%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.74%',
              width: '47.777%',
              height: '26.875%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '31.62%',
              left: '1.481%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '31.62%',
              left: '34.32%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '31.62%',
              left: '67.16%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '17.9%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.74%',
              width: '31.358%',
              height: '17.638%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '31.621%',
              left: '1.481%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '31.621%',
              left: '34.32%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '31.621%',
              left: '67.16%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '1.481%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '34.32%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '67.16%',
              width: '31.357%',
              height: '17.638%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.238%',
              left: '1.481%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.238%',
              left: '26.111%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.238%',
              left: '50.74%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.238%',
              left: '75.37%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '13.796%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '38.425%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '63.055%',
              width: '23.148%',
              height: '13.02%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.238%',
              left: '1.481%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.238%',
              left: '26.111%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.238%',
              left: '50.74%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '36.238%',
              left: '75.37%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '1.481%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '26.111%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.74%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '75.37%',
              width: '23.148%',
              height: '13.02%'
            }
          }
        ]
      },
      'without-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '21.875%',
              left: '0%',
              width: '100%',
              height: '56.25%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '0%',
              width: '50%',
              height: '28.125%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '50%',
              width: '50%',
              height: '28.125%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '21.875%',
              left: '0%',
              width: '50%',
              height: '28.125%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '21.875%',
              left: '50%',
              width: '50%',
              height: '28.125%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '25%',
              width: '50%',
              height: '28.125%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '21.875%',
              left: '0%',
              width: '50%',
              height: '28.125%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '21.875%',
              left: '50%',
              width: '50%',
              height: '28.125%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '28.125%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '28.125%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '31.25%',
              left: '0%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '31.25%',
              left: '33.333%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '31.25%',
              left: '66.666%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '16.666%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '33.333%',
              height: '18.75%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '31.25%',
              left: '0%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '31.25%',
              left: '33.333%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '31.25%',
              left: '66.666%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '33.333%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '66.666%',
              width: '33.333%',
              height: '18.75%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '0%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '25%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '50%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '75%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '12.5%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '37.5%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '62.5%',
              width: '25%',
              height: '14.062%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '0%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '25%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '50%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '35.937%',
              left: '75%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '25%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '75%',
              width: '25%',
              height: '14.062%'
            }
          }
        ]
      }
    },
    FILL: {
      'with-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '1.481%',
              width: '97.037%',
              height: '97.037%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '1.481%',
              width: '47.777%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '50.74%',
              width: '47.777%',
              height: '97.037%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '1.481%',
              width: '47.777%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '50.74%',
              width: '47.777%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '26.111%',
              width: '47.777%',
              height: '47.777%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '1.481%',
              width: '47.777%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '50.74%',
              width: '47.777%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '1.481%',
              width: '47.777%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.74%',
              width: '47.777%',
              height: '47.777%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '1.481%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '34.32%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '67.16%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '17.9%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.74%',
              width: '31.358%',
              height: '47.777%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '1.481%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '34.32%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '67.16%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '1.481%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '34.32%',
              width: '31.357%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '67.16%',
              width: '31.357%',
              height: '47.777%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '1.481%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '26.111%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '50.74%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '75.37%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '13.796%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '38.425%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '63.055%',
              width: '23.148%',
              height: '47.777%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '1.481%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '26.111%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '50.74%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '75.37%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '1.481%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '26.111%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.74%',
              width: '23.148%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '75.37%',
              width: '23.148%',
              height: '47.777%'
            }
          }
        ]
      },
      'without-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '100%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '100%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '25%',
              width: '50%',
              height: '50%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '33.333%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '66.666%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '16.666%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '33.333%',
              height: '50%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '33.333%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '66.666%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '33.333%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '66.666%',
              width: '33.333%',
              height: '50%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '25%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '75%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '12.5%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '37.5%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '62.5%',
              width: '25%',
              height: '50%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '25%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '75%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '25%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '25%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '75%',
              width: '25%',
              height: '50%'
            }
          }
        ]
      }
    }
  },
  FOCUS: {
    FIT: {
      'with-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '2.573%',
              left: '1.481%',
              width: '97.037%',
              height: '53.37%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '57.425%',
              left: '14.444%',
              width: '71.111%',
              height: '40%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '9.136%',
              left: '1.481%',
              width: '97.037%',
              height: '53.37%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.987%',
              left: '1.481%',
              width: '47.777%',
              height: '26.875%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.987%',
              left: '50.74%',
              width: '47.777%',
              height: '26.875%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '13.755%',
              left: '1.481%',
              width: '97.037%',
              height: '53.37%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '68.606%',
              left: '1.483%',
              width: '31.356%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '68.606%',
              left: '34.321%',
              width: '31.356%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '68.606%',
              left: '67.159%',
              width: '31.356%',
              height: '17.638%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.481%',
              width: '97.037%',
              height: '53.37%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '56.333%',
              left: '13.078%',
              width: '36.18%',
              height: '20.351%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '56.333%',
              left: '50.74%',
              width: '36.18%',
              height: '20.351%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '13.078%',
              width: '36.18%',
              height: '20.351%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '50.74%',
              width: '36.18%',
              height: '20.351%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '4.194%',
              left: '1.481%',
              width: '97.037%',
              height: '53.37%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '59.045%',
              left: '1.481%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '59.045%',
              left: '34.32%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '59.045%',
              left: '67.16%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '17.9%',
              width: '31.357%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '50.74%',
              width: '31.357%',
              height: '17.638%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '4.194%',
              left: '1.481%',
              width: '97.037%',
              height: '53.37%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '59.046%',
              left: '1.483%',
              width: '31.356%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '59.046%',
              left: '34.321%',
              width: '31.356%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '59.046%',
              left: '67.159%',
              width: '31.356%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '1.483%',
              width: '31.356%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '34.321%',
              width: '31.356%',
              height: '17.638%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '67.159%',
              width: '31.356%',
              height: '17.638%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '8.812%',
              left: '1.481%',
              width: '97.037%',
              height: '53.37%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.663%',
              left: '1.481%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.663%',
              left: '26.111%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.663%',
              left: '50.74%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.663%',
              left: '75.37%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '13.796%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '38.425%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '63.055%',
              width: '23.148%',
              height: '13.02%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '8.812%',
              left: '1.481%',
              width: '97.037%',
              height: '53.37%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.663%',
              left: '1.481%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.663%',
              left: '26.111%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.663%',
              left: '50.74%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '63.663%',
              left: '75.37%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '1.481%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '26.111%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '50.74%',
              width: '23.148%',
              height: '13.02%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '78.166%',
              left: '75.37%',
              width: '23.148%',
              height: '13.02%'
            }
          }
        ]
      },
      'without-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '2.5%',
              left: '0%',
              width: '100%',
              height: '55%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '57.499%',
              left: '14.444%',
              width: '71.111%',
              height: '40%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '8.437%',
              left: '0%',
              width: '100%',
              height: '55%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '0%',
              width: '50%',
              height: '28.125%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '50%',
              width: '50%',
              height: '28.125%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '13.125%',
              left: '0%',
              width: '100%',
              height: '55%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '68.125%',
              left: '0%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '68.125%',
              left: '33.333%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '68.125%',
              left: '66.666%',
              width: '33.333%',
              height: '18.75%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '55%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '55%',
              left: '10%',
              width: '40%',
              height: '22.5%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '55%',
              left: '50%',
              width: '40%',
              height: '22.5%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '10%',
              width: '40%',
              height: '22.5%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '50%',
              width: '40%',
              height: '22.5%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '3.75%',
              left: '0%',
              width: '100%',
              height: '55%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '58.75%',
              left: '0%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '58.75%',
              left: '33.333%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '58.75%',
              left: '66.666%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '16.666%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '50%',
              width: '33.333%',
              height: '18.75%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '3.75%',
              left: '0%',
              width: '100%',
              height: '55%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '58.75%',
              left: '0%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '58.75%',
              left: '33.333%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '58.75%',
              left: '66.666%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '0%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '33.333%',
              width: '33.333%',
              height: '18.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '66.666%',
              width: '33.333%',
              height: '18.75%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '8.437%',
              left: '0%',
              width: '100%',
              height: '55%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '0%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '25%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '50%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '75%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '12.5%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '37.5%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '62.5%',
              width: '25%',
              height: '14.062%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '8.437%',
              left: '0%',
              width: '100%',
              height: '55%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '0%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '25%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '50%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '63.437%',
              left: '75%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '0%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '25%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '50%',
              width: '25%',
              height: '14.062%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '77.5%',
              left: '75%',
              width: '25%',
              height: '14.062%'
            }
          }
        ]
      }
    },
    FILL: {
      'with-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.711%',
              width: '65.014%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.207%',
              width: '30.081%',
              height: '97.037%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.711%',
              width: '65.014%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.207%',
              width: '30.081%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.207%',
              width: '30.081%',
              height: '47.777%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.711%',
              width: '65.014%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.207%',
              width: '30.081%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '68.207%',
              width: '30.081%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '68.207%',
              width: '30.081%',
              height: '31.357%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.711%',
              width: '65.014%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.207%',
              width: '30.081%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '68.207%',
              width: '30.081%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.207%',
              width: '30.081%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.37%',
              left: '68.207%',
              width: '30.081%',
              height: '23.148%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.711%',
              width: '65.014%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.207%',
              width: '30.081%',
              height: '18.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '21.184%',
              left: '68.207%',
              width: '30.081%',
              height: '18.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.888%',
              left: '68.207%',
              width: '30.081%',
              height: '18.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '60.592%',
              left: '68.207%',
              width: '30.081%',
              height: '18.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '80.295%',
              left: '68.207%',
              width: '30.081%',
              height: '18.222%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.711%',
              width: '65.014%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.207%',
              width: '14.299%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '83.988%',
              width: '14.299%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '68.207%',
              width: '14.299%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '83.988%',
              width: '14.299%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '68.207%',
              width: '14.299%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '83.988%',
              width: '14.299%',
              height: '31.357%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.711%',
              width: '65.014%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.207%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '83.988%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '68.207%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '83.988%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.207%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '83.988%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.37%',
              left: '68.207%',
              width: '14.299%',
              height: '23.148%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.711%',
              width: '65.014%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.207%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '83.988%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '68.207%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '83.988%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.207%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '83.988%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.37%',
              left: '68.207%',
              width: '14.299%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.37%',
              left: '83.988%',
              width: '14.299%',
              height: '23.148%'
            }
          }
        ]
      },
      'without-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '67.999%',
              width: '30.999%',
              height: '100%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '67.999%',
              width: '30.999%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '67.999%',
              width: '30.999%',
              height: '50%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '67.999%',
              width: '30.999%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '67.999%',
              width: '30.999%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '67.999%',
              width: '30.999%',
              height: '33.333%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '67.999%',
              width: '30.999%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '67.999%',
              width: '30.999%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '67.999%',
              width: '30.999%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '67.999%',
              width: '30.999%',
              height: '25%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '67.999%',
              width: '30.999%',
              height: '20%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '20%',
              left: '67.999%',
              width: '30.999%',
              height: '20%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40%',
              left: '67.999%',
              width: '30.999%',
              height: '20%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '60%',
              left: '67.999%',
              width: '30.999%',
              height: '20%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '80%',
              left: '67.999%',
              width: '30.999%',
              height: '20%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '67.999%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '83.499%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '67.999%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '83.499%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '67.999%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '83.499%',
              width: '15.499%',
              height: '33.333%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '67.999%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '83.499%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '67.999%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '83.499%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '67.999%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '83.499%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '67.999%',
              width: '15.499%',
              height: '25%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '67.999%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '83.499%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '67.999%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '83.499%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '67.999%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '83.499%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '67.999%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '83.499%',
              width: '15.499%',
              height: '25%'
            }
          }
        ]
      }
    }
  }
};
