import { Popover } from '@headlessui/react';
import { memo, useCallback, useState } from 'react';
import { LANGUAGE_OPTIONS, LanguageOption } from './types';
import SelectDropdown from '@/components/atoms/SelectDropdown';
import Button from '@/components/atoms/Button/Button';

function CaptionLanguageSelectorPopup() {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption | null>(null);

  const handleLanguageChange = useCallback((selected: string) => {
    const language = LANGUAGE_OPTIONS.find(lang => lang.name === selected);
    if (language) {
      setSelectedLanguage(language);
    }
  }, []);

  return (
    <Popover className="absolute -top-64 right-20 flex max-h-[54vh] w-[23rem] flex-col rounded-lg border bg-white shadow">
      <div className="sticky top-0 z-10 flex-col items-center justify-between bg-white px-4 py-3">
        <div className="mb-4">
          <div className="text-lg font-medium">Translate</div>
          <div className="mt-1 text-sm text-slate-600">
            Select a language to translate your transcript and captions into.
          </div>
        </div>
        <div className="relative flex gap-2">
          <SelectDropdown
            options={LANGUAGE_OPTIONS}
            selected={selectedLanguage?.name ?? ''}
            onChange={selected => handleLanguageChange(selected)}
            menuClassName="fixed"
            placeholder="Select language"
            label="Available Languages"
          />
          <Button variation="filled" disabled={!selectedLanguage} trackingId="translate-button" type="button">
            Translate
          </Button>
        </div>
      </div>
    </Popover>
  );
}

export default memo(CaptionLanguageSelectorPopup);
