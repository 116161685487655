import React, { useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { IconEdit, IconList } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSelectionHighlightSpecs,
  getSelectionDuration,
  getWordIdFromElement,
  getParagraphIndexByTime,
  parseTranscriptToParagraphs
} from './utils';
import SpeakerRow from './SpeakerRow';
import TranscriptWord from './TranscriptWord';
import { HighlightedSearchParagraph, SearchParagraphWord, TranscriptParagraph, TranscriptSelection } from './types';
import TranscriptSkeleton from './TranscriptSkeleton';
import { CLIP_MIN_LENGTH, VIRTUAL_CONTAINER_EXTEND_BY_SIZE, VIRTUAL_VIEWPORT_EXTENDED_BY } from './constants';
import useTranscriptHighlightHook from './useTranscriptHighlightHook';
import TranscriptSearch from './TranscriptSearch';
import TranscriptDownloadButton from './TranscriptDownloadButton';
import EditTranscriptMenu from './EditTranscriptMenu';
import CreateClipPopup from './CreateClipPopup';
import useFullClipTranscriptHighlight from './useFullClipTranscriptHighlight';
import TranscriptInlineEdit from './TranscriptInlineEdit/TranscriptInlineEdit';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { TranscriptContextType } from '@/context/TranscriptContext/TranscriptContextTypes';
import { classnames, preventDefault } from '@/libs/utils';
import { ClipPlayer, play, updatePlayerTime } from '@/stores/player';
import { Word, WordType } from '@/domains/transcript';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import useElementScrolledHook from '@/hooks/useElementScrolledHook';
import { currentClip } from '@/stores/clip';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { ClipPlayerStore } from '@/stores/playerV2';
import Loader from '@/components/atoms/Loader';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import useIsScrollingElement from '@/hooks/useIsScrollingElement';
import ChapterActionsMenu from '@/Pages/Clip/ClipPlayer/ClipTimeline/ChapterActionsMenu';
import { formatTime } from '@/libs/core';
import { ChapterType } from '@/Pages/Clip/ClipPlayer/ClipTimeline/ClipTimelineTypes';
import useTranscriptPage from '@/Pages/TranscriptPage/useTranscriptPage';

export default function FullTranscript({
  videoAssetId,
  title = 'Transcript',
  playerStore,
  onCreateClip,
  onTimeUpdate,
  onPause = () => null,
  fullClipOffset = 0
}: {
  videoAssetId?: string;
  title?: string;
  playerStore: ClipPlayer | ClipPlayerStore;
  onCreateClip: (start_time: number, end_time: number) => Promise<void>;
  onTimeUpdate?: (time: number, startPlaying?: boolean) => void;
  onPause?: () => void;
  fullClipOffset?: number;
}) {
  const navigate = useNavigate();
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();

  const virtuoso = useRef<VirtuosoHandle>(null);
  const container = useRef<HTMLDivElement>(null);
  const virtuosoWrapperRef = useRef<HTMLDivElement>(null);
  const createClipWrapper = useRef<HTMLDivElement>(null);
  const createClipPopup = useRef<HTMLDivElement>(null);
  const chapterButtonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [transcriptSelection, setTranscriptSelection] = useState<TranscriptSelection | null>();
  const [anchorNode, setAnchorNode] = useState<Node | null>(null);
  const [anchorNodeCandidates, setAnchorNodeCandidates] = useState<Node[]>([]);
  const [focusNode, setFocusNode] = useState<Node | null>(null);
  const [lastFocusNodeVisited, setLastFocusNodeVisited] = useState<Node | null>(null);
  const [lastFocusNodeOffset, setLastFocusNodeOffset] = useState<number | null>(null);
  const [highlightedParagraph, setHighlightedParagraph] = useState<HighlightedSearchParagraph | null>(null);
  const transcriptStore = useTranscriptContext() as TranscriptContextType;
  const [isCreatingClip, setIsCreatingClip] = useState<boolean | undefined>(undefined);

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isChapterizationEnabled = featureFlags[FeatureFlagKeys.Use_CL_Chapterization];

  const transcript = useMemo(() => transcriptStore.transcript, [transcriptStore.transcript]);
  const chapters = useMemo(
    () => (isChapterizationEnabled ? transcriptStore.chapterData : []),
    [transcriptStore.chapterData, isChapterizationEnabled]
  );
  const { isScrolled, handleOnScroll: handleScrolled } = useElementScrolledHook();
  const { isScrolling, handleOnScroll: handleScrolling } = useIsScrollingElement();
  const editPopupRef = useRef<HTMLDivElement>(null);
  const allClips = useSyncExternalStore(currentClip.subscribe, currentClip.getSnapshot);
  const { clipCaptionsLoading } = useClipsContext();
  const [showEditMenu, setShowEditMenu] = useState(false);
  const { updateChapter, createNewChapter, selectedChapter, setSelectedChapter } = useTranscriptPage();

  const [isEditing, setIsEditing] = useState(false);
  const [editingStartTime, setEditingStartTime] = useState<number | undefined>();
  const [editingContent, setEditingContent] = useState<string | undefined>();
  const editingTranscriptRef = useRef<HTMLInputElement>(null);

  const handleOnScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      handleScrolled(e);
      handleScrolling();
    },
    [handleScrolled, handleScrolling]
  );

  const isEdit = useMemo(() => {
    return !!videoAssetId;
  }, [videoAssetId]);

  const videoAssetClip = useMemo(() => {
    return !!videoAssetId ? allClips[videoAssetId] : undefined;
  }, [allClips, videoAssetId]);

  const { paragraphs, firstIndex }: { paragraphs: TranscriptParagraph[]; firstIndex: number } = useMemo(() => {
    const paragraphs = parseTranscriptToParagraphs(
      transcript,
      transcriptStore?.speakersWithDetails,
      videoAssetClip?.asset_metadata,
      chapters
    );
    // In case of the edit full recording, scroll to first non-deleted word
    const firstIndex = isEdit ? paragraphs.findIndex(p => p.words.some(w => !w.is_deleted)) : 0;
    return { paragraphs, firstIndex: firstIndex };
  }, [
    transcript,
    transcriptStore?.speakersWithDetails,
    videoAssetClip?.asset_metadata.deletes,
    videoAssetClip?.asset_metadata.edits,
    videoAssetClip?.asset_metadata.merges,
    chapters,
    isEdit
  ]);

  const { highlightActiveWord } = useTranscriptHighlightHook();
  const { highlightFullClipActiveWord } = useFullClipTranscriptHighlight();

  const speakerSegmentContextData = useSpeakerSegmentContext();

  useEffect(() => {
    isEdit
      ? highlightFullClipActiveWord(virtuosoWrapperRef, playerStore, isScrolling, isEditing, fullClipOffset)
      : highlightActiveWord(container, playerStore);
  }, [
    playerStore.currentTime,
    playerStore.currentSrtIndex,
    playerStore.duration,
    playerStore.paused,
    highlightActiveWord,
    isEdit,
    isScrolling,
    highlightFullClipActiveWord,
    playerStore,
    fullClipOffset
  ]);

  const updateTime = useCallback(
    (time: number) => {
      if (!isEdit) updatePlayerTime(time);
    },
    [isEdit]
  );
  /**
   * playFromWord is different from playFromTime as it does not consider if player is paused or not
   */
  const playFromWord = useCallback(
    (wordStartTime: number) => {
      updateTime(wordStartTime);
      play(wordStartTime);
    },
    [updateTime]
  );

  const scrollToVirtuosoIndex = useCallback(
    ({ index, words }: { index: number; words: Word[] | SearchParagraphWord[] }) => {
      // Scroll to the paragraph (needed because this is a virtualized list)
      virtuoso?.current?.scrollToIndex({
        index,
        align: 'center',
        behavior: 'auto'
      });

      requestAnimationFrame(() => {
        // Scroll to the first searched word
        const paragraph = paragraphs[index];
        const firstSearchedWord = words[0];
        if (!firstSearchedWord) return;
        const firstSearchedWordIndex = paragraph.words?.find(w => w.content === firstSearchedWord.content)?.index;

        if (!firstSearchedWordIndex || firstSearchedWordIndex === -1) return;

        const firstSearchedWordElement = document.querySelector(`[data-word-id="${firstSearchedWordIndex}"]`);

        if (!firstSearchedWordElement) return;

        firstSearchedWordElement.scrollIntoView({
          behavior: 'auto',
          block: 'center'
        });
      });
    },
    [paragraphs]
  );

  const onTimelineChanged = useCallback(
    ({ time }: { time: number }) => {
      const closestStart = transcript.reduce((prev, { start_time: curr, type }) => {
        return Math.abs(curr - time) < Math.abs(prev - time) && type !== WordType.Punctuation ? curr : prev;
      }, 0);
      const paragraphIndex = getParagraphIndexByTime(closestStart, paragraphs);
      if (paragraphIndex >= 0) {
        scrollToVirtuosoIndex({ index: paragraphIndex, words: paragraphs[paragraphIndex].words });
        updateTime(time);
      }
      if (isEdit) {
        highlightActiveWord(container, playerStore);
      }
    },
    [transcript, paragraphs, isEdit, scrollToVirtuosoIndex, updateTime, highlightActiveWord, playerStore]
  );

  useEffect(() => {
    if (!!firstIndex) {
      setTimeout(() => {
        scrollToVirtuosoIndex({ index: firstIndex, words: paragraphs[firstIndex]?.words || [] });
      }, 500);
    }
  }, [firstIndex]);

  const cleanUpSelection = useCallback(() => {
    createClipPopup.current?.classList.add('invisible');
    setShowEditMenu(false);
    document.getSelection()?.removeAllRanges();
    setTranscriptSelection(null);
    setAnchorNode(null);
    setIsEditing(false);
    setAnchorNodeCandidates([]);
    setFocusNode(null);
    setLastFocusNodeVisited(null);
    setLastFocusNodeOffset(null);
  }, []);

  const showCreateClipPopup = useCallback(
    (selectionWords: Word[], left: number, top: number) => {
      if (!isEdit && selectionWords.length < CLIP_MIN_LENGTH) {
        cleanUpSelection();
        return;
      }

      const anchorNodeBoundaries = [
        parseFloat(
          anchorNode?.parentElement?.closest('[data-word-start-time]')?.getAttribute('data-word-start-time') || '0'
        ),
        parseFloat(
          anchorNode?.parentElement?.closest('[data-word-end-time]')?.getAttribute('data-word-end-time') || '0'
        )
      ];
      const focusNodeBoundaries = [
        parseFloat(
          focusNode?.parentElement?.closest('[data-word-start-time]')?.getAttribute('data-word-start-time') || '0'
        ),
        parseFloat(focusNode?.parentElement?.closest('[data-word-end-time]')?.getAttribute('data-word-end-time') || '0')
      ];

      setTranscriptSelection({
        words: selectionWords,
        duration: getSelectionDuration(selectionWords),
        isSingleParagraphSelection:
          anchorNode?.parentElement?.closest('[data-item-index]') ===
          focusNode?.parentElement?.closest('[data-item-index]'),
        selectionStartTime: Math.min(
          anchorNodeBoundaries[0],
          anchorNodeBoundaries[1],
          focusNodeBoundaries[0],
          focusNodeBoundaries[1]
        ),
        selectionEndTime: Math.max(
          anchorNodeBoundaries[0],
          anchorNodeBoundaries[1],
          focusNodeBoundaries[0],
          focusNodeBoundaries[1]
        )
      });

      const element = createClipPopup.current;
      if (element) {
        element.classList.remove('invisible');
        element.style.left = `${left}px`;
        element.style.top = `${top}px`;
      }
      setShowEditMenu(true);
    },
    [anchorNode, cleanUpSelection, focusNode, isEdit]
  );

  const setFocusNodeFromSelection = useCallback(() => {
    const selection = document.getSelection();
    if (
      !selection?.focusNode?.parentElement?.attributes?.getNamedItem('data-word-id')?.value &&
      !lastFocusNodeVisited
    ) {
      return;
    }

    if (focusNode) {
      return;
    }

    const isUsingFallback = !selection?.focusNode?.parentElement?.attributes?.getNamedItem('data-word-id')?.value;
    const focusNodeWithFallback = isUsingFallback ? lastFocusNodeVisited : selection?.focusNode;
    const focusNodeOffset = focusNodeWithFallback ? lastFocusNodeOffset : selection?.focusOffset;
    const anchorNodeIndex = parseInt(selection?.anchorNode?.parentElement?.getAttribute('data-word-id') || '-1', 10);
    const focusNodeIndex = parseInt(focusNodeWithFallback?.parentElement?.getAttribute('data-word-id') || '-1', 10);

    if (focusNodeIndex === -1 || anchorNodeIndex === -1) return;

    if (
      focusNodeOffset &&
      focusNodeOffset >= (focusNodeWithFallback?.textContent || '').trim().length &&
      anchorNodeIndex > focusNodeIndex
    ) {
      const nextParentSibling = focusNodeWithFallback?.parentElement?.nextElementSibling;
      if (nextParentSibling && nextParentSibling.attributes?.getNamedItem('data-word-id')?.value) {
        setFocusNode(nextParentSibling.childNodes[0]);
      } else {
        setFocusNode(focusNodeWithFallback);
      }
    } else {
      setFocusNode(focusNodeWithFallback);
    }
  }, [focusNode, lastFocusNodeVisited]);

  const onPointerUp = useCallback(
    (ev: MouseEvent) => {
      if (isEditing) return;
      if ((ev.target as HTMLElement)?.dataset?.testId === 'create-clip-button') {
        return;
      }
      if (editingTranscriptRef.current?.contains(ev.target as Node)) {
        return;
      }

      if (
        !(createClipWrapper.current?.contains(ev.target as Node) || editPopupRef.current?.contains(ev.target as Node))
      ) {
        setTranscriptSelection(null);
      }

      setFocusNodeFromSelection();
    },
    [isEditing, setFocusNodeFromSelection]
  );

  const onKeyUp = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        cleanUpSelection();
        return;
      }

      if (ev.shiftKey && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(ev.key)) {
        setFocusNodeFromSelection();
      }
    },
    [cleanUpSelection, setFocusNodeFromSelection]
  );

  const onClipboardCopy = useCallback(
    (event: ClipboardEvent) => {
      if (!anchorNode || !focusNode) return;

      event.preventDefault();

      const wordBounds = getSelectionHighlightSpecs(anchorNode, focusNode);

      if (!wordBounds) return;

      const wordsToSelect = transcript.slice(wordBounds.startIndex, wordBounds.endIndex + 1);
      const textToCopy = wordsToSelect.map(word => word.content).join(' ');
      navigator.clipboard.writeText(textToCopy);
    },
    [anchorNode, focusNode, transcript]
  );

  useEffect(() => {
    if (isCreatingClip === undefined || isCreatingClip) {
      return;
    }

    navigate(`/${eventId}/${broadcastId}/clips`);
  }, [isCreatingClip, eventId, broadcastId]);

  useEffect(() => {
    // Disable scroll when create clip popup is visible
    const virtuosoContainer = document.querySelector(`[data-test-id="virtuoso-scroller"]`);
    if (transcriptSelection) {
      if (!isEdit || isEditing) {
        virtuosoContainer?.addEventListener('scroll', preventDefault, false);
        virtuosoContainer?.addEventListener('mousewheel', preventDefault, false);
        virtuosoContainer?.addEventListener('touchmove', preventDefault, false);
      }

      document.addEventListener('copy', onClipboardCopy);
    }
    return () => {
      if (transcriptSelection) {
        if (!isEdit || isEditing) {
          virtuosoContainer?.removeEventListener('scroll', preventDefault, false);
          virtuosoContainer?.removeEventListener('mousewheel', preventDefault, false);
          virtuosoContainer?.removeEventListener('touchmove', preventDefault, false);
        }
        document.removeEventListener('copy', onClipboardCopy);
      }
    };
  }, [transcriptSelection, isEditing]);

  const createFullRecordingClip = useCallback(
    (start: number, end: number) => {
      setIsCreatingClip(true);
      onCreateClip(start, end).then(() => {
        cleanUpSelection();
        setIsCreatingClip(false);
      });
    },
    [cleanUpSelection, onCreateClip]
  );

  const onClickCreateClipButton = useCallback(() => {
    if (transcriptSelection?.words.length) {
      const { start_time } = transcriptSelection.words[0];
      const { end_time = 0 } = transcriptSelection.words.at(-1) ?? {};
      createFullRecordingClip(start_time, end_time);
    }
  }, [transcriptSelection?.words, createFullRecordingClip]);

  const onSelectionChange = useCallback(() => {
    const selection = document.getSelection();

    if (!selection?.anchorNode?.parentElement?.attributes?.getNamedItem('data-word-id')?.value) {
      return;
    }

    if (anchorNode) {
      const focusNodeWordId = selection?.focusNode?.parentElement?.attributes?.getNamedItem('data-word-id')?.value;
      if (focusNodeWordId) {
        setLastFocusNodeVisited(selection?.focusNode);
        setLastFocusNodeOffset(selection?.focusOffset);
      }
      return;
    }

    setLastFocusNodeVisited(null);
    setLastFocusNodeOffset(null);
    if (
      selection?.anchorOffset &&
      selection?.anchorOffset >= (selection?.anchorNode?.textContent || '').trim().length
    ) {
      const nextParentSibling = selection?.anchorNode?.parentElement?.nextElementSibling;
      if (
        nextParentSibling &&
        nextParentSibling.attributes?.getNamedItem('data-word-id')?.value &&
        nextParentSibling.childNodes?.[0]
      ) {
        // We're keeping both nodes to later check which one is the correct one based on whether focusNode is before or after them
        setAnchorNodeCandidates([selection?.anchorNode, nextParentSibling.childNodes[0]]);
      } else if (selection?.anchorNode) {
        setAnchorNode(selection?.anchorNode);
      }
    } else if (selection?.anchorNode) {
      setAnchorNode(selection?.anchorNode);
    }
  }, [anchorNode]);

  useEffect(() => {
    if (!anchorNodeCandidates.length || anchorNodeCandidates.length <= 1 || !focusNode || anchorNode) {
      return;
    }

    const focusNodeIndex = parseInt(focusNode?.parentElement?.getAttribute('data-word-id') || '-1', 10);
    const anchorNode0Index = parseInt(anchorNodeCandidates[0].parentElement?.getAttribute('data-word-id') || '-1', 10);
    const anchorNode1Index = parseInt(anchorNodeCandidates[1].parentElement?.getAttribute('data-word-id') || '-1', 10);

    if (focusNodeIndex === -1 || anchorNode0Index === -1 || anchorNode1Index === -1) return;

    if (focusNodeIndex > anchorNode1Index) {
      setAnchorNode(anchorNodeCandidates[1]);
    } else {
      setAnchorNode(anchorNodeCandidates[0]);
    }
  }, [anchorNode, anchorNodeCandidates, focusNode]);

  useEffect(() => {
    if (!anchorNode?.parentElement || !focusNode?.parentElement) {
      return;
    }

    if (!isEdit && anchorNode === focusNode) {
      const wordId = getWordIdFromElement(anchorNode.parentElement);
      if (!isNaN(wordId)) {
        const wordStartTime = transcript[wordId].start_time;
        const segmentWord = speakerSegmentContextData?.captions.find(w => w.start_time === wordStartTime);
        playFromWord(segmentWord?.processed_start_time ?? transcript[wordId].start_time);
      }
      return;
    }

    const wordBounds = getSelectionHighlightSpecs(anchorNode, focusNode);

    if (!wordBounds) {
      return;
    }

    const selection = document.getSelection();
    if (selection) {
      selection.setBaseAndExtent(anchorNode, 0, focusNode, 0);
    }

    const wordsToSelect = transcript.slice(wordBounds.startIndex, wordBounds.endIndex + 1);

    let addendum = 0;
    if (focusNode.compareDocumentPosition(anchorNode) === Node.DOCUMENT_POSITION_PRECEDING) {
      addendum = 20;
    } else {
      addendum = -60; // with height of create clip popup
    }

    const boundingRect = focusNode.parentElement.getBoundingClientRect();
    const x = boundingRect.x;
    const y = boundingRect.y + addendum;

    showCreateClipPopup(wordsToSelect, x, y);
  }, [anchorNode, focusNode, transcript, playFromWord, showCreateClipPopup]);

  const onPointerDown = useCallback(
    (e: MouseEvent) => {
      if (isEditing) return;
      if (
        !(createClipWrapper.current?.contains(e.target as Node) || editPopupRef.current?.contains(e.target as Node))
      ) {
        cleanUpSelection();
      }
    },
    [cleanUpSelection, isEditing]
  );

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.TimelineChanged, onTimelineChanged);
    const clipEndedEventListener = EventBus.on(CustomEvents.ClipEnded, () => {
      scrollToVirtuosoIndex({ index: firstIndex, words: [] });
    });

    document.addEventListener('pointerup', onPointerUp);
    document.addEventListener('selectionchange', onSelectionChange);
    document.addEventListener('pointerdown', onPointerDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      EventBus.off(CustomEvents.TimelineChanged, eventListener);
      EventBus.off(CustomEvents.ClipEnded, clipEndedEventListener);

      document.removeEventListener('pointerup', onPointerUp);
      document.removeEventListener('selectionchange', onSelectionChange);
      document.removeEventListener('pointerdown', onPointerDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [
    transcript,
    onPointerUp,
    onTimelineChanged,
    onPointerDown,
    onKeyUp,
    setFocusNodeFromSelection,
    onSelectionChange,
    scrollToVirtuosoIndex,
    firstIndex
  ]);

  const handleEditSpeaker = useCallback(speakerId => {
    EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId });
  }, []);

  const forceHighlightRange = useMemo(() => {
    if (!focusNode || !anchorNode) return [-1, -1];
    if (focusNode === anchorNode && !isEdit) return [-1, -1];

    const focusNodeIndex = parseInt(focusNode.parentElement?.getAttribute('data-word-id') || '-1', 10);
    const anchorNodeIndex = parseInt(anchorNode.parentElement?.getAttribute('data-word-id') || '-1', 10);

    if (focusNodeIndex === -1 || anchorNodeIndex === -1) return [-1, -1];

    return [focusNodeIndex, anchorNodeIndex];
  }, [focusNode, anchorNode]);

  const [selectionStartIndex, selectionEndIndex] = useMemo(() => {
    const [focusIndex, anchorIndex] = forceHighlightRange;

    return [Math.min(focusIndex, anchorIndex), Math.max(focusIndex, anchorIndex)];
  }, [forceHighlightRange]);

  const handleRenameChapter = useCallback(chapter => {
    setSelectedChapter(chapter);
  }, []);

  const handleRenameSubmit = useCallback(async () => {
    if (!selectedChapter) return;

    await updateChapter(selectedChapter.id, selectedChapter.title);
    setSelectedChapter(null);
  }, [selectedChapter]);

  const startEditing = useCallback(() => {
    setIsEditing(true);
    setShowEditMenu(false);
  }, [transcriptSelection?.words]);

  const cancelEditing = useCallback(() => {
    setIsEditing(false);
    cleanUpSelection();
  }, []);

  useEffect(() => {
    if (isEditing) {
      const editingContent = Array.from(document.querySelectorAll('.bg-deep-orange.word'))
        .map(el => el.textContent)
        .join('')
        .trim();
      setEditingContent(editingContent);
      setEditingStartTime(transcriptSelection?.words[0]?.start_time);
    } else {
      setEditingContent('');
      setEditingStartTime(undefined);
    }
  }, [isEditing]);

  const onCreateNewChapter = useCallback(async (words, index) => {
    const button = chapterButtonRefs.current[index];
    const endTime = words[words.length - 1].end_time;

    if (button) {
      button.disabled = true;
      button.classList.add('bg-gray-300', 'text-gray-500', 'cursor-not-allowed');
      button.classList.remove('bg-slate-100', 'hover:bg-slate-200');
    }
    try {
      await createNewChapter('New Chapter', endTime);
    } finally {
      if (button) {
        button.disabled = true;
        button.classList.add('bg-slate-100', 'hover:bg-slate-200');
        button.classList.remove('bg-gray-300', 'text-gray-500', 'cursor-not-allowed');
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className="z-20 flex h-full w-[34rem] py-2 transition-all duration-[400ms] ease-in-out">
        <div
          className="relative w-full overflow-hidden rounded-2xl border !border-slate-100 bg-white shadow-xl shadow-slate-200/10"
          ref={container}
          data-testid="transcript-container"
        >
          {/* Loader for edit full recording only when captions are updating */}
          {clipCaptionsLoading && (
            <div className="absolute z-[70] flex h-full w-full items-center justify-center bg-white/70">
              <Loader />
            </div>
          )}
          <div
            className={classnames('sticky top-0 z-20 flex h-14 w-full items-center justify-between bg-white px-5', {
              'shadow-lg shadow-slate-900/[0.075]': isScrolled
            })}
          >
            <span className="select-none text-lg font-medium leading-tight">{title}</span>
            <div className="flex items-center gap-2">
              <TranscriptSearch
                paragraphs={paragraphs}
                onOccurrencesChange={scrollToVirtuosoIndex}
                setHighlightedParagraph={setHighlightedParagraph}
              />
              <TranscriptDownloadButton />
            </div>
          </div>
          {!paragraphs.length ? (
            <TranscriptSkeleton />
          ) : (
            <div
              style={{
                height: 'calc(100% - 56px)'
              }}
              ref={virtuosoWrapperRef}
            >
              <Virtuoso
                overscan={{
                  main: VIRTUAL_CONTAINER_EXTEND_BY_SIZE,
                  reverse: VIRTUAL_CONTAINER_EXTEND_BY_SIZE
                }}
                increaseViewportBy={{
                  bottom: VIRTUAL_VIEWPORT_EXTENDED_BY,
                  top: 0
                }}
                // 93% height because of search
                style={{
                  height: `${paragraphs.length ? '100%' : '0px'}`,
                  overflowX: 'hidden',
                  marginLeft: '1.25rem',
                  marginRight: '0px'
                }}
                className={classnames('leading-[25px] text-slate-700 selection:rounded-sm  selection:text-white')}
                data={paragraphs}
                ref={virtuoso}
                onScroll={handleOnScroll}
                itemContent={(index: number, paragraph: TranscriptParagraph) => {
                  const prevParagraph = paragraphs[index - 1];
                  const nextParagraph = paragraphs[index + 1];

                  if (paragraph.speakerDetails) {
                    return (
                      <SpeakerRow
                        speakerDetails={paragraph.speakerDetails}
                        index={index}
                        wordId={paragraphs[index + 1]?.words[0]?.index}
                        handleEditSpeaker={() => handleEditSpeaker(paragraph.speakerDetails?.speaker?.id)}
                      />
                    );
                  }

                  if (paragraph.chapterDetails && isChapterizationEnabled) {
                    const chapterStartTime = paragraph?.chapterDetails?.start;
                    const chapterEndTime = paragraph?.chapterDetails?.end || playerStore.duration;
                    return (
                      <div
                        className="group my-2 mr-4 flex cursor-pointer select-none items-center justify-between rounded-lg py-1 pr-1.5 hover:bg-slate-100"
                        onClick={() => onTimeUpdate?.(chapterStartTime, false)}
                      >
                        <div className="flex w-full items-center space-x-3 truncate">
                          <div className="rounded-full bg-slate-100 px-2 py-1 text-xs text-slate-400">
                            {formatTime(chapterStartTime)}
                          </div>
                          <div className="grow truncate text-lg font-semibold">
                            {paragraph?.chapterDetails?.id === selectedChapter?.id ? (
                              <input
                                className="w-full truncate rounded border-none bg-transparent px-1 font-medium text-black selection:bg-blue-300 selection:text-black focus:border-none focus:outline-none focus:ring-0"
                                value={selectedChapter?.title}
                                data-testid="transcript-chapter-rename-input"
                                onChange={e => setSelectedChapter({ ...selectedChapter, title: e.target.value })}
                                onBlur={handleRenameSubmit}
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    handleRenameSubmit();
                                  }
                                }}
                                autoFocus
                              />
                            ) : (
                              <p className="w-full select-none truncate rounded border-none bg-transparent px-1 font-medium text-black focus:border-none focus:outline-none focus:ring-0">
                                {paragraph?.chapterDetails?.title}
                              </p>
                            )}
                          </div>
                        </div>
                        <ChapterActionsMenu
                          type={ChapterType.TRANSCRIPT}
                          chapterId={paragraph.chapterDetails.id}
                          chapterStartTime={chapterStartTime}
                          chapterEndTime={chapterEndTime}
                          onRenameChapter={() => handleRenameChapter(paragraph.chapterDetails)}
                        />
                      </div>
                    );
                  }

                  if (paragraph.words) {
                    return (
                      <div
                        className={classnames('pl-16 pr-4', {
                          '-mt-10': prevParagraph?.speakerDetails,
                          'pb-3': nextParagraph?.words,
                          'pb-8':
                            nextParagraph?.speakerDetails || !(nextParagraph?.words || nextParagraph?.speakerDetails)
                        })}
                      >
                        {prevParagraph?.speakerDetails ? (
                          <div className="mb-2 select-none leading-tight">
                            <button
                              data-testid="transcript-speaker-name-button"
                              className={`group -ml-1.5 flex items-center space-x-1 text-sm text-slate-700`}
                              onClick={() => handleEditSpeaker(prevParagraph?.speakerDetails?.speaker?.id)}
                            >
                              <span
                                className={`${prevParagraph?.speakerDetails?.speaker?.bgColor} ${prevParagraph?.speakerDetails?.speaker?.color} rounded-full bg-opacity-10 px-1.5 py-0.5 font-medium`}
                              >{`${prevParagraph?.speakerDetails?.speaker?.first_name} ${prevParagraph?.speakerDetails?.speaker.last_name}`}</span>
                              <IconEdit className="hidden h-4 w-4 group-hover:flex" />
                            </button>
                            <div className="text-xs text-slate-500">
                              {prevParagraph?.speakerDetails?.start} - {prevParagraph?.speakerDetails?.end}
                            </div>
                          </div>
                        ) : null}
                        {paragraph.words?.map((word, i) => {
                          if (
                            isEditing &&
                            transcriptSelection &&
                            !!(transcriptSelection?.words || []).find(w => w.start_time === word.start_time)
                          ) {
                            if (editingStartTime === word.start_time && editingContent) {
                              return (
                                <TranscriptInlineEdit
                                  key={word.content + i}
                                  editingTranscriptRef={editingTranscriptRef}
                                  paragraphs={paragraphs}
                                  transcriptSelection={transcriptSelection}
                                  editingContent={editingContent}
                                  onCancelEditing={cancelEditing}
                                  transcriptContainer={container}
                                  onEditActionTriggered={onPause}
                                />
                              );
                            }
                            return <span key={word.content + i}></span>;
                          }
                          const shouldHighlightWord =
                            forceHighlightRange.includes(word.index) ||
                            (word.index > selectionStartIndex && word.index < selectionEndIndex);
                          return (
                            <React.Fragment key={word.content + i}>
                              <TranscriptWord
                                word={word}
                                foundWords={highlightedParagraph?.index === index ? highlightedParagraph?.words : []}
                                forceHighlight={shouldHighlightWord}
                                isEdit={isEdit}
                              />
                            </React.Fragment>
                          );
                        })}

                        {!nextParagraph?.chapterDetails &&
                          isChapterizationEnabled &&
                          paragraphs.length !== index + 1 && (
                            <div
                              className={classnames('group flex h-4 w-full items-center justify-center ', {
                                'transition-all delay-700 duration-700 ease-in-out hover:h-10':
                                  selectionStartIndex === -1 && selectionEndIndex === -1
                              })}
                            >
                              {selectionStartIndex === -1 && selectionEndIndex === -1 && (
                                <button
                                  ref={el => {
                                    chapterButtonRefs.current[index] = el;
                                  }}
                                  className="flex w-full items-center justify-center rounded-lg bg-slate-100 py-1.5 text-center text-xs font-medium opacity-0 transition-all delay-700 duration-700 ease-in-out group-hover:opacity-100"
                                  onClick={() => onCreateNewChapter(paragraph.words, index)}
                                >
                                  <IconList className="mr-1 h-4 w-4" />
                                  <span>New Chapter</span>
                                </button>
                              )}
                            </div>
                          )}
                      </div>
                    );
                  }

                  return null;
                }}
              />
            </div>
          )}
        </div>
      </div>
      {isEdit ? (
        <EditTranscriptMenu
          editPopupRef={editPopupRef}
          isCreatingClip={isCreatingClip}
          transcriptSelection={transcriptSelection}
          show={showEditMenu}
          onCreate={createFullRecordingClip}
          onClose={cleanUpSelection}
          onPlay={onTimeUpdate}
          onStartEditing={startEditing}
          onEditActionTriggered={onPause}
        />
      ) : (
        <CreateClipPopup
          createClipPopup={createClipPopup}
          createClipWrapper={createClipWrapper}
          transcriptSelection={transcriptSelection}
          onCreate={onClickCreateClipButton}
          isCreatingClip={isCreatingClip}
        />
      )}
    </React.Fragment>
  );
}
