import { alignedRecordings } from '../../../stores/alignedRecordings';
import { ClipDeletes } from '@/domains/asset';

export const getVideosAndTimeRanges = (): {
  videoId: string;
  ranges: { start: number; end: number }[];
  isScreenshare: boolean;
}[] => {
  const alignedRecordingsStore = alignedRecordings.getSnapshot();

  return alignedRecordingsStore.map(recording => {
    const { stage_time_spans, id: videoId, entity } = recording;
    const isScreenshare = entity === 'aligned_screenshare_feed';

    const ranges: { start: number; end: number }[] = (stage_time_spans || []).map(times => ({
      start: times.start_time,
      end: times.end_time
    }));

    return {
      videoId,
      ranges,
      isScreenshare
    };
  });
};

export const getFlattenedDeletes = ({ clipDeletes, fps }: { clipDeletes: ClipDeletes; fps: number }) => {
  if (clipDeletes === undefined) return [];
  return Object.values(clipDeletes)
    .map(data => ({
      fromFrame: data.bounds[0] * fps,
      toFrame: data.bounds[1] * fps
    }))
    .filter(data => data.toFrame !== data.fromFrame)
    .sort((a, b) => a.fromFrame - b.fromFrame);
};

// This is required to adjust the frame of the main segment to account for the deletes
export const getAdjustedFrame = (frame: number, deletes: ReturnType<typeof getFlattenedDeletes>, start: number) => {
  let adjustment = 0;

  for (const deleteData of deletes) {
    if (deleteData.fromFrame >= frame) break;
    if (deleteData.toFrame <= frame) {
      adjustment += deleteData.toFrame - deleteData.fromFrame;
    }
  }

  return frame - adjustment - start;
};

// This is required to get the available ranges after the deletes
export const getAvailableRanges = (
  totalRange: [number, number],
  deletedRanges: ReturnType<typeof getFlattenedDeletes>
) => {
  // Extract the start and end of the total range
  const [totalStart, totalEnd] = totalRange;

  // Sort the deleted ranges by their start value
  deletedRanges.sort((a, b) => a.fromFrame - b.fromFrame);

  const remainingRanges: [number, number][] = [];

  // Track the end of the last deleted range
  let lastEnd = totalStart;

  // Loop through the deleted ranges and find gaps
  for (let i = 0; i < deletedRanges.length; i++) {
    const { fromFrame, toFrame } = deletedRanges[i];

    // If there is a gap before the current deleted range
    if (fromFrame > lastEnd) {
      remainingRanges.push([lastEnd, fromFrame]);
    }

    // Update the last end point to the end of the current deleted range
    lastEnd = Math.max(lastEnd, toFrame);
  }

  // If there is any remaining range after the last deleted range
  if (lastEnd < totalEnd) {
    remainingRanges.push([lastEnd, totalEnd]);
  }

  return remainingRanges;
};
