export const LANDSCAPE_COMPILED_CONFIG = {
  GRID: {
    FIT: {
      'with-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '0.833%',
              width: '98.333%',
              height: '98.333%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '25.624%',
              left: '0.833%',
              width: '48.75%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '25.624%',
              left: '50.416%',
              width: '48.75%',
              height: '48.75%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.509%',
              left: '0.833%',
              width: '48.75%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.509%',
              left: '50.416%',
              width: '48.75%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '25.624%',
              width: '48.75%',
              height: '48.75%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.509%',
              left: '0.833%',
              width: '48.75%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.509%',
              left: '50.416%',
              width: '48.75%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '0.833%',
              width: '48.75%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.416%',
              width: '48.75%',
              height: '48.75%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '17.037%',
              left: '0.833%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '17.037%',
              left: '33.888%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '17.037%',
              left: '66.944%',
              width: '32.222%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '17.36%',
              width: '32.222%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.416%',
              width: '32.222%',
              height: '32.221%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '17.037%',
              left: '0.833%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '17.037%',
              left: '33.888%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '17.037%',
              left: '66.944%',
              width: '32.222%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '0.833%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '33.888%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '66.944%',
              width: '32.222%',
              height: '32.222%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.185%',
              left: '0.833%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.185%',
              left: '33.888%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.185%',
              left: '66.944%',
              width: '32.222%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '0.833%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '33.888%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '66.944%',
              width: '32.222%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.592%',
              left: '33.888%',
              width: '32.222%',
              height: '32.221%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.185%',
              left: '0.833%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.185%',
              left: '33.888%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.185%',
              left: '66.944%',
              width: '32.222%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '0.833%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '33.888%',
              width: '32.222%',
              height: '32.221%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '66.944%',
              width: '32.222%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.592%',
              left: '17.36%',
              width: '32.222%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.592%',
              left: '50.416%',
              width: '32.222%',
              height: '32.221%'
            }
          }
        ]
      },
      'without-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '100%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '25%',
              width: '50%',
              height: '50%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '16.666%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '16.666%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '16.666%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '16.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '33.333%',
              height: '33.333%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '16.666%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '16.666%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '16.666%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '16.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '50%',
              width: '33.333%',
              height: '33.333%'
            }
          }
        ]
      }
    },
    FILL: {
      'with-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '0.833%',
              width: '98.333%',
              height: '97.037%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '0.833%',
              width: '48.75%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '50.416%',
              width: '48.75%',
              height: '97.037%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '0.833%',
              width: '48.75%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '50.416%',
              width: '48.75%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '25.624%',
              width: '48.75%',
              height: '47.777%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '0.833%',
              width: '48.75%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '50.416%',
              width: '48.75%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '0.833%',
              width: '48.75%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.416%',
              width: '48.75%',
              height: '47.777%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '0.833%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '33.888%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '66.944%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '17.36%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '50.416%',
              width: '32.222%',
              height: '47.777%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '0.833%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '33.888%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '66.944%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '0.833%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '33.888%',
              width: '32.222%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '66.944%',
              width: '32.222%',
              height: '47.777%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '0.833%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '33.888%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '66.944%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '0.833%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '33.888%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '66.944%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '33.888%',
              width: '32.222%',
              height: '31.357%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '0.833%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '33.888%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '66.944%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '0.833%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '33.888%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '66.944%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '17.36%',
              width: '32.222%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '50.416%',
              width: '32.222%',
              height: '31.357%'
            }
          }
        ]
      },
      'without-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '100%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '100%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '25%',
              width: '50%',
              height: '50%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '33.333%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '66.666%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '16.666%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '33.333%',
              height: '50%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '33.333%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '66.666%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '33.333%',
              width: '33.333%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '66.666%',
              width: '33.333%',
              height: '50%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '0%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '33.333%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '66.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '16.666%',
              width: '33.333%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '50%',
              width: '33.333%',
              height: '33.333%'
            }
          }
        ]
      }
    }
  },
  FOCUS: {
    FIT: {
      'with-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '17.058%',
              left: '1.4%',
              width: '65.882%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.758%',
              left: '68.116%',
              width: '30.482%',
              height: '30.482%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '17.058%',
              left: '1.4%',
              width: '65.882%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.776%',
              left: '68.116%',
              width: '30.482%',
              height: '30.482%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.116%',
              width: '30.482%',
              height: '30.482%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '17.058%',
              left: '1.4%',
              width: '65.882%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '2.794%',
              left: '68.116%',
              width: '30.482%',
              height: '30.482%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.758%',
              left: '68.116%',
              width: '30.482%',
              height: '30.482%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.722%',
              left: '68.116%',
              width: '30.482%',
              height: '30.482%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '17.058%',
              left: '1.4%',
              width: '65.882%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.434%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.434%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '17.058%',
              left: '1.4%',
              width: '65.882%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.281%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.281%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '42.587%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '42.587%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '58.893%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '17.058%',
              left: '1.4%',
              width: '65.882%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.281%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.281%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '42.587%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '42.587%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '58.893%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '58.893%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '17.058%',
              left: '1.4%',
              width: '65.882%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.127%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.127%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.434%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.434%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.047%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '17.058%',
              left: '1.4%',
              width: '65.882%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.127%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.127%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.434%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.434%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.047%',
              left: '68.116%',
              width: '14.824%',
              height: '14.824%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.047%',
              left: '83.774%',
              width: '14.825%',
              height: '14.824%'
            }
          }
        ]
      },
      'without-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '16.5%',
              left: '1%',
              width: '66.999%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.5%',
              left: '68%',
              width: '30.999%',
              height: '30.999%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '16.5%',
              left: '1%',
              width: '66.999%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '19%',
              left: '68%',
              width: '30.999%',
              height: '30.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '68%',
              width: '30.999%',
              height: '30.999%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '16.5%',
              left: '1%',
              width: '66.999%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '3.5%',
              left: '68%',
              width: '30.999%',
              height: '30.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.5%',
              left: '68%',
              width: '30.999%',
              height: '30.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '65.499%',
              left: '68%',
              width: '30.999%',
              height: '30.999%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '16.5%',
              left: '1%',
              width: '66.999%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.5%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.5%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '16.5%',
              left: '1%',
              width: '66.999%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '26.749%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '26.749%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '42.249%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '42.249%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '57.749%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '16.5%',
              left: '1%',
              width: '66.999%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '26.749%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '26.749%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '42.249%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '42.249%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '57.749%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '57.749%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '16.5%',
              left: '1%',
              width: '66.999%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '19%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '19%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.5%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.5%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '65.499%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '16.5%',
              left: '1%',
              width: '66.999%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '19%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '19%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.5%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.5%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '65.499%',
              left: '68%',
              width: '15.499%',
              height: '15.499%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '65.499%',
              left: '83.499%',
              width: '15.5%',
              height: '15.499%'
            }
          }
        ]
      }
    },
    FILL: {
      'with-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.4%',
              width: '65.882%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.116%',
              width: '30.482%',
              height: '97.037%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.4%',
              width: '65.882%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.116%',
              width: '30.482%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.116%',
              width: '30.482%',
              height: '47.777%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.4%',
              width: '65.882%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.116%',
              width: '30.482%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '68.116%',
              width: '30.482%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '68.116%',
              width: '30.482%',
              height: '31.357%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.4%',
              width: '65.882%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.116%',
              width: '14.824%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '83.774%',
              width: '14.825%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.116%',
              width: '14.824%',
              height: '47.777%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '83.774%',
              width: '14.825%',
              height: '47.777%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.4%',
              width: '65.882%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.116%',
              width: '14.824%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '83.774%',
              width: '14.825%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '68.116%',
              width: '14.824%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '83.774%',
              width: '14.825%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '68.116%',
              width: '14.824%',
              height: '31.357%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.4%',
              width: '65.882%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.116%',
              width: '14.824%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '83.774%',
              width: '14.825%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '68.116%',
              width: '14.824%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.32%',
              left: '83.774%',
              width: '14.825%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '68.116%',
              width: '14.824%',
              height: '31.357%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.16%',
              left: '83.774%',
              width: '14.825%',
              height: '31.357%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.4%',
              width: '65.882%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.116%',
              width: '14.824%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '83.774%',
              width: '14.825%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '68.116%',
              width: '14.824%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '83.774%',
              width: '14.825%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.116%',
              width: '14.824%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '83.774%',
              width: '14.825%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.37%',
              left: '68.116%',
              width: '14.824%',
              height: '23.148%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.481%',
              left: '1.4%',
              width: '65.882%',
              height: '97.037%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '68.116%',
              width: '14.824%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '1.481%',
              left: '83.774%',
              width: '14.825%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '68.116%',
              width: '14.824%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.111%',
              left: '83.774%',
              width: '14.825%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '68.116%',
              width: '14.824%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.74%',
              left: '83.774%',
              width: '14.825%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.37%',
              left: '68.116%',
              width: '14.824%',
              height: '23.148%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.37%',
              left: '83.774%',
              width: '14.825%',
              height: '23.148%'
            }
          }
        ]
      },
      'without-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '68%',
              width: '30.999%',
              height: '100%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '68%',
              width: '30.999%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '68%',
              width: '30.999%',
              height: '50%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '68%',
              width: '30.999%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '68%',
              width: '30.999%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '68%',
              width: '30.999%',
              height: '33.333%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '68%',
              width: '15.499%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '83.499%',
              width: '15.5%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '68%',
              width: '15.499%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '83.499%',
              width: '15.5%',
              height: '50%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '68%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '83.499%',
              width: '15.5%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '68%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '83.499%',
              width: '15.5%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '68%',
              width: '15.499%',
              height: '33.333%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '68%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '83.499%',
              width: '15.5%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '68%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '83.499%',
              width: '15.5%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '68%',
              width: '15.499%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '83.499%',
              width: '15.5%',
              height: '33.333%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '68%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '83.499%',
              width: '15.5%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '68%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '83.499%',
              width: '15.5%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '68%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '83.499%',
              width: '15.5%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '68%',
              width: '15.499%',
              height: '25%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '1%',
              width: '66.999%',
              height: '100%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '68%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '83.499%',
              width: '15.5%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '68%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '83.499%',
              width: '15.5%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '68%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '83.499%',
              width: '15.5%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '68%',
              width: '15.499%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '83.499%',
              width: '15.5%',
              height: '25%'
            }
          }
        ]
      }
    }
  }
};
