import { MediaContentConfig } from '@goldcast/api/content';
import createStore from './store';
import { ContentStatesEnum, ContentError } from '@/Pages/VideoImportPage/constants';

export interface ContentUploadError {
  type: ContentError;
  message: string;
}

export type ContentUploadListItem = {
  projectId: string;
  contentId: string;
  duration?: number;
  file?: File;
  contentUrl?: string | null;
  contentState: ContentStatesEnum;
  error?: ContentUploadError | null;
  av_type: string;
  title: string;
  totalPercentageUploaded?: number;
  videoProcessingTime?: string;
  contentUploadedTime?: string;
  clipGenerationEstimatedTime?: string;
  showConfetti?: boolean;
};

export type ContentUploadState = {
  contentUploadList: { [contentId: string]: ContentUploadListItem };
  currentMediaContentConfig: MediaContentConfig | null;
};

export const contentUploadState = createStore<ContentUploadState>({
  contentUploadList: {},
  currentMediaContentConfig: null
});
