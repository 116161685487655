export interface LanguageOption {
  code: string;
  name: string;
}

export const Languages: Record<string, LanguageOption> = {
  ENGLISH: { code: 'en', name: 'English' },
  SPANISH: { code: 'es', name: 'Spanish' },
  FRENCH: { code: 'fr', name: 'French' },
  ITALIAN: { code: 'it', name: 'Italian' },
  PORTUGUESE: { code: 'pt', name: 'Portuguese' },
  DUTCH: { code: 'nl', name: 'Dutch' },
  GERMAN: { code: 'de', name: 'German' }
} as const;

export type LanguageCode = (typeof Languages)[keyof typeof Languages]['code'];
export const LANGUAGE_OPTIONS: LanguageOption[] = Object.values(Languages);
