import { resetFullLengthClip } from './clipContentUtil';
import EventBus from './eventBus/eventBus';
import { CustomEvents } from './eventBus/constants';
import { trackClipUpdate } from '@/stores/clip';

export function resetFullClipChanges(videoAssetId: string) {
  resetFullLengthClip(videoAssetId).then(() => {
    EventBus.dispatch(CustomEvents.ReloadClip, { showLoader: true });
  });

  trackClipUpdate(videoAssetId, 'RESET_FULL_RECORDING_CHANGES');
}
