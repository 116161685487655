import { useEffect, useState } from 'react';
import { loadFont } from '@remotion/fonts';
import { continueRender, delayRender } from 'remotion';
import { Page, RemotionConfig } from '../../types';
import CaptionsPage from './CaptionsPage';

export default function CaptionsPages({
  pages,
  onOutlineRelease,
  CaptionsMenu,
  canResizeCaptions,
  drift,
  canEditCaptions,
  font
}: Pick<RemotionConfig, 'onOutlineRelease' | 'CaptionsMenu' | 'canResizeCaptions' | 'drift' | 'canEditCaptions'> & {
  pages: Page[];
  font?: { name: string; location: string };
}) {
  const [handle] = useState(() => delayRender());
  useEffect(() => {
    if (font?.location && font.name) {
      loadFont({
        family: font.name,
        url: font.location
      })
        .then(() => {
          // eslint-disable-next-line no-console
          console.log('Font loaded - ', font.name);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error loading font - ', font.name, font.location, error);
        })
        .finally(() => {
          continueRender(handle);
        });
    } else {
      continueRender(handle);
    }
  }, [font?.location, font?.name]);

  return pages.map((page, index) => (
    <CaptionsPage
      key={index}
      page={page}
      onOutlineRelease={onOutlineRelease}
      canResizeCaptions={canResizeCaptions}
      canEditCaptions={canEditCaptions}
      CaptionsMenu={CaptionsMenu}
      drift={drift}
    />
  ));
}
