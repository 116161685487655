import { memo, useCallback, useMemo } from 'react';
import { FIT_OPTIONS } from './constants';
import ClipCustomizerLayoutIconButton from './ClipCustomizerLayoutIconButton';
import { selectFitMode } from '@/stores/clip';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { FitType } from '@/domains/asset';

function ClipCustomizerFitOption({ fitOption }: { fitOption: Extract<FitType, 'FIT' | 'FILL'> }) {
  const { clipId, clipData } = useClipsContext();

  const fitMode = useMemo(() => {
    return clipData.asset_metadata.fit;
  }, [clipData.asset_metadata.fit]);

  const updateFitMode = useCallback(() => {
    selectFitMode(clipId, fitOption);
  }, [clipId, fitOption]);

  return (
    <>
      <ClipCustomizerLayoutIconButton
        optionDetails={FIT_OPTIONS[fitOption]}
        trackingId={`customizer-fit-mode-${fitOption}`}
        onClick={updateFitMode}
        isActive={fitMode === fitOption}
      />
    </>
  );
}

export default memo(ClipCustomizerFitOption);
