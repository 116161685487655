import { Popover, Transition } from '@headlessui/react';
import { memo, useMemo } from 'react';
import VideosToggler from './VideosToggler';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import Toggle from '@/components/atoms/Toggle';
import { toggleSpeakerLabels } from '@/stores/clip';

function ClipCustomizerSpeakersPopup() {
  const { clipId, clipData } = useClipsContext();

  const clipLayout = useMemo(() => clipData.asset_metadata.layout, [clipData.asset_metadata.layout]);

  const shouldShowSpeakers = useMemo(() => {
    return clipLayout !== 'SPEAKER';
  }, [clipLayout]);

  function onSpeakerLabelsToggle(value: boolean) {
    toggleSpeakerLabels(clipId, value);
  }

  return (
    <Popover className="absolute -top-40 right-20 flex max-h-[54vh] w-[23rem] flex-col overflow-y-auto rounded-lg border bg-white shadow">
      <div className="py-2.5">
        <div className="flex flex-col px-4">
          <div className="flex items-center justify-between rounded-md py-3.5">
            <div className="text-sm">Show Speaker Labels</div>
            <Toggle on={!!clipData.asset_metadata.magicLayout?.showSpeakerLabels} onToggle={onSpeakerLabelsToggle} />
          </div>

          <Transition
            show={shouldShowSpeakers}
            enter="transition transform duration-300 ease-out"
            enterFrom="-translate-y-full opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition transform duration-300 ease-in"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-full opacity-0"
          >
            <VideosToggler />
          </Transition>
        </div>
      </div>
    </Popover>
  );
}

export default memo(ClipCustomizerSpeakersPopup);
