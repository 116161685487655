import { memo, useEffect, useMemo, useRef, useState } from 'react';
import ClipShareDownloadTab from './ClipShareDownloadTab';
import ClipShareLinkTab from './ClipShareLinkTab';
import useClipShareHook from './useClipShareHook';
import SharePopup, { SharePopupTab } from '../SharePopup';
import { useAppContext } from '@/context/AppContext/AppContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { ButtonVariation } from '@/components/atoms/Button/ButtonTypes';
import { shouldUseNewPlayerConfig } from '@/stores/clip';

const tabs: SharePopupTab[] = [
  { key: 'clip-share-link-tab', title: 'Link', tabComponent: <ClipShareLinkTab /> },
  { key: 'clip-share-download-tab', title: 'Download', tabComponent: <ClipShareDownloadTab /> }
];

const ClipSharePopup = ({
  activeClipId,
  downloadIntent,
  iconButtonVariation
}: {
  activeClipId?: string;
  downloadIntent?: boolean;
  iconButtonVariation?: ButtonVariation;
}) => {
  const sharePopupRef = useRef(null) as React.MutableRefObject<{ openSharePopup: () => void } | null>;

  const { adminAppStore } = useAppContext();
  const { setCurrentClipAsShared } = useClipShareHook();
  const { clipData } = useClipsContext();

  const useNewPlayer = useMemo(() => {
    return shouldUseNewPlayerConfig(clipData);
  }, [clipData]);

  const [allTabs, setAllTabs] = useState(tabs);
  const [currentTab, setCurrentTab] = useState(useNewPlayer ? tabs[1] : tabs[0]);

  const beforeOpen = () => {
    adminAppStore.dispatch?.('user/showForceChangePasswordDialogIfRequired');

    if (useNewPlayer) {
      setAllTabs([tabs[1]]);
      setCurrentTab(tabs[1]);
      return;
    }

    if (downloadIntent) {
      setCurrentTab(tabs[1]);
    } else {
      setCurrentTab(tabs[0]);
    }

    setCurrentClipAsShared();
  };

  useEffect(() => {
    if (activeClipId === clipData.id && downloadIntent) {
      sharePopupRef.current?.openSharePopup();
    }
  }, [downloadIntent, activeClipId, clipData.id]);

  return (
    <SharePopup
      ref={sharePopupRef}
      currentTab={currentTab}
      tabs={allTabs}
      iconButtonVariation={iconButtonVariation}
      beforeOpen={beforeOpen}
      setCurrentTab={setCurrentTab}
      content={useNewPlayer ? 'Download' : 'Share'}
    />
  );
};

export default memo(ClipSharePopup);
