import { alignedRecordingIdToSpeakerKeyMap } from '../../../stores/alignedRecordings';
import { getVideosAndTimeRanges } from './utils';
import { getActiveSpeakersWithinTimeRange } from '../../activeSpeaker';
import { tempSpeakerStore } from '@/context/TranscriptContext/TranscriptContext';
import { Clip } from '@/domains/asset';

interface SpeakerOverlap {
  fromFrame: number;
  toFrame: number;
  videoIds: string[];
  hasScreenshare: boolean;
}

export const getSpeakerOverlaps = ({ clip, fps }: { clip: Clip; fps: number }) => {
  const alignedRecordingIdToSpeakerKeyMapStore = alignedRecordingIdToSpeakerKeyMap.getSnapshot();
  const tempSpeaker = tempSpeakerStore.getSnapshot();

  const videosAndTimeRanges = getVideosAndTimeRanges();

  const speakerOverlaps: SpeakerOverlap[] = [];

  const allFramePoints = new Set<number>();

  videosAndTimeRanges.forEach(video => {
    video.ranges.forEach(range => {
      allFramePoints.add(range.start);
      allFramePoints.add(range.end);
    });
  });

  let sortedFramePoints = Array.from(allFramePoints).sort((a, b) => a - b);

  if (clip.asset_metadata.layout !== 'GRID') {
    for (let i = 0; i < sortedFramePoints.length - 1; i++) {
      const fromFrame = sortedFramePoints[i];
      const toFrame = i === sortedFramePoints.length - 1 ? clip.asset_metadata.duration || 0 : sortedFramePoints[i + 1];

      const activeSpeakers = getActiveSpeakersWithinTimeRange(fromFrame, toFrame, tempSpeaker);

      activeSpeakers.forEach(speaker => {
        if (speaker) {
          allFramePoints.add(speaker.start);
          allFramePoints.add(speaker.end);
        }
      });
    }

    sortedFramePoints = Array.from(allFramePoints).sort((a, b) => a - b);
  }

  for (let i = 0; i < sortedFramePoints.length - 1; i++) {
    const fromFrame = sortedFramePoints[i];
    const toFrame = i === sortedFramePoints.length - 1 ? clip.asset_metadata.duration || 0 : sortedFramePoints[i + 1];

    const activeSpeakers = getActiveSpeakersWithinTimeRange(fromFrame, toFrame, tempSpeaker);

    const allPresentSpeakers = videosAndTimeRanges.filter(video => {
      if (!video.ranges.length) {
        return false;
      }

      if (
        clip.asset_metadata.layout !== 'SPEAKER' &&
        clip.asset_metadata.visible_video_ids &&
        !clip.asset_metadata.visible_video_ids.includes(video.videoId)
      ) {
        return false;
      }

      return video.ranges.some(({ start, end }) => {
        return start <= fromFrame && end >= toFrame;
      });
    });

    const isFirstScreenshare = !!allPresentSpeakers[0]?.isScreenshare;

    if (!isFirstScreenshare && allPresentSpeakers.length > 1 && clip.asset_metadata.layout !== 'GRID') {
      const currentActiveSpeaker = activeSpeakers[0];

      if (currentActiveSpeaker) {
        // move to start of array in allPresentSpeakers
        const speakerIdsOfAllPresent = allPresentSpeakers.map(s => alignedRecordingIdToSpeakerKeyMapStore[s.videoId]);

        allPresentSpeakers.unshift(
          allPresentSpeakers.splice(speakerIdsOfAllPresent.indexOf(currentActiveSpeaker.speakerId), 1)[0]
        );
      }
    }

    speakerOverlaps.push({
      fromFrame: fromFrame * fps,
      toFrame: toFrame * fps,
      videoIds: allPresentSpeakers.map(s => s.videoId),
      hasScreenshare: isFirstScreenshare
    });
  }

  return speakerOverlaps;
};
