import { useMutation } from 'react-query';
import { assetsClipPublishToLibraryCreate } from '@goldcast/api/content';
import { useMemo, useSyncExternalStore } from 'react';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { useAppContext } from '@/context/AppContext/AppContext';
import useAnalytics from '@/hooks/useAnalytics';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import { updateClipMetadataWithId } from '@/stores/clip';
import { FeatureFlagKeys } from '@/services/featureFlag';
import featureFlagStore from '@/stores/featureFlagStore';

export default function usePublishAsset() {
  const { clipId, clipData } = useClipsContext();
  const { logger } = useAppContext();
  const { trackVideoPublished } = useAnalytics();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);

  const shouldEnableLayers = useMemo(() => {
    const useRemotionCaptions = featureFlags[FeatureFlagKeys.Use_CL_Remotion_Captions];
    const useSpeakerLabelsForRecordings = featureFlags[FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings];
    const useSpeakerLabelsForUploads = featureFlags[FeatureFlagKeys.Use_CL_Clip_Player_V2];

    const clipLayout = clipData?.asset_metadata?.layout;
    const isAudiogram = clipLayout === 'AUDIOGRAM';

    if (isAudiogram) return false;

    const isSpeakerLayout = clipLayout === 'SPEAKER' || clipLayout === 'GRID';
    const showSpeakerLabels = !!clipData?.asset_metadata?.magicLayout?.showSpeakerLabels;

    const isGoldcastRecording = clipData?.content?.media_source_type === 'RECORDING';
    const isUpload = clipData?.content?.media_source_type === 'UPLOAD';

    return (
      (((isGoldcastRecording && useSpeakerLabelsForRecordings) || (isUpload && useSpeakerLabelsForUploads)) &&
        isSpeakerLayout &&
        showSpeakerLabels) ||
      (useRemotionCaptions && !!clipData?.asset_metadata?.captions?.length)
    );
  }, [
    clipData?.asset_metadata?.captions,
    clipData?.asset_metadata?.layout,
    clipData?.asset_metadata?.magicLayout?.showSpeakerLabels,
    clipData?.content?.media_source_type,
    featureFlags
  ]);

  const { mutateAsync: publishAsset, isLoading: isPublishing } = useMutation(
    async () => {
      const res = await assetsClipPublishToLibraryCreate({
        id: clipId,
        body: {
          enable_layers: shouldEnableLayers
        }
      });
      return res;
    },
    {
      onSuccess: res => {
        updateClipMetadataWithId(clipId, res.asset_metadata);
        showSuccessToast('Publishing started. This might take a few minutes.');
        trackVideoPublished({ id: clipId, isSuccess: true });
      },
      onError: err => {
        logger.error('Failed to publish clip to library:', err);
        showErrorToast('Something went wrong while publishing recording. Please try again later.');
        trackVideoPublished({ id: clipId, isSuccess: false });
      }
    }
  );

  return { publishAsset, isPublishing };
}
