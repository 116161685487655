import { memo, useCallback, useMemo, useSyncExternalStore } from 'react';
import { IconSlash } from '@tabler/icons-react';
import useClipTranscriptPlayPause from '../ClipPlayerControls/useClipTranscriptPlayPause';
import {
  ClipPlayerSection,
  useIntroRefReturn,
  useMainSectionRefReturn,
  useOutroRefReturn
} from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import { convertSecondsToTimeString } from '../../SideBar/ClipDuration/utils';
import PlaybackRateButton from '../PlaybackRateButton';
import PlayPause from '@/components/molecules/PlayPause';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import IconButton from '@/components/atoms/Button/IconButton';
import { PLAYER_SKIP_DURATION } from '@/Pages/TranscriptPage/constants';
import { roundToNDecimalPlaces } from '@/libs/utils';
import { core } from '@/stores/core';
import { shouldUseNewRecordingsPlayer } from '@/stores/clip';

function SpeakersTimelinePlaybackControls({
  outroPlayerProps,
  mainPlayerProps,
  introPlayerProps,
  currentSection,
  setCurrentSectionAndPlay
}: {
  outroPlayerProps: useOutroRefReturn;
  mainPlayerProps: useMainSectionRefReturn;
  introPlayerProps: useIntroRefReturn;
  currentSection: ClipPlayerSection;
  setCurrentSectionAndPlay: (section: ClipPlayerSection, shouldPlay: boolean, time?: number) => void;
}) {
  const { clipData } = useClipsContext();
  const { isPaused, playCurrentSection, pauseCurrentSection } = useClipTranscriptPlayPause({
    currentSection,
    introPlayerProps,
    mainPlayerProps,
    outroPlayerProps
  });

  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const { outroDuration, outroCurrentTime } = outroPlayerProps;
  const { mainSectionCurrentTime, mainSectionRef } = mainPlayerProps;
  const { introDuration, introCurrentTime } = introPlayerProps;

  const fullRecordingDuration = useMemo(() => {
    return mainPlayerProps.mainSectionRef.current?.duration || 0;
  }, [mainPlayerProps.mainSectionRef.current?.duration]);

  const durations = useMemo(() => {
    return {
      intro: clipData.asset_metadata.intro ? introDuration : 0,
      main: fullRecordingDuration,
      outro: clipData.asset_metadata.outro ? outroDuration : 0
    };
  }, [
    clipData.asset_metadata.intro,
    clipData.asset_metadata.outro,
    introDuration,
    outroDuration,
    fullRecordingDuration
  ]);

  const currentTimes = useMemo(() => {
    return {
      intro: introCurrentTime,
      main: roundToNDecimalPlaces(mainPlayerProps.mainSectionCurrentTime, 3),
      outro: outroCurrentTime
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clipData.asset_metadata.start,
    introCurrentTime,
    mainSectionCurrentTime,
    outroCurrentTime,
    mainSectionRef,
    mainSectionRef.current
  ]);

  const skipToStart = useCallback(() => {
    pauseCurrentSection();
    setCurrentSectionAndPlay(currentSection, false, 0);
  }, [currentSection, pauseCurrentSection, setCurrentSectionAndPlay]);

  const skipToEnd = useCallback(() => {
    pauseCurrentSection();
    // 0.1 to prevent player going to the start
    let duration = durations[currentSection];
    if (currentSection === 'main') duration = clipData.asset_metadata.end - clipData.asset_metadata.start;
    setCurrentSectionAndPlay(currentSection, false, duration - 0.1);
  }, [
    clipData.asset_metadata.end,
    clipData.asset_metadata.start,
    currentSection,
    durations,
    pauseCurrentSection,
    setCurrentSectionAndPlay
  ]);

  const skipBack = useCallback(() => {
    let currentTime = currentTimes[currentSection];
    if (currentSection === 'main') currentTime -= clipData.asset_metadata.start;
    let newTime = currentTime - PLAYER_SKIP_DURATION;
    if (currentSection === 'main') {
      const exclude = (clipData.asset_metadata.excludes || []).find(
        e => e[0] - clipData.asset_metadata.start <= newTime && e[1] - clipData.asset_metadata.start >= newTime
      );
      // Add 1 second offset to prevent player getting back to the exclude end
      if (!!exclude) newTime = exclude[0] - clipData.asset_metadata.start - 1;
    }
    setCurrentSectionAndPlay(currentSection, !isPaused, Math.max(newTime, 0));
  }, [
    clipData.asset_metadata.excludes,
    clipData.asset_metadata.start,
    currentSection,
    currentTimes,
    isPaused,
    setCurrentSectionAndPlay
  ]);

  const skipForward = useCallback(() => {
    let currentTime = currentTimes[currentSection];
    if (currentSection === 'main') currentTime -= clipData.asset_metadata.start;
    let newTime = currentTime + PLAYER_SKIP_DURATION;
    let duration = durations[currentSection];
    if (currentSection === 'main') {
      const exclude = (clipData.asset_metadata.excludes || []).find(
        e => e[0] - clipData.asset_metadata.start <= newTime && e[1] - clipData.asset_metadata.start >= newTime
      );
      if (!!exclude) newTime = exclude[1] - clipData.asset_metadata.start;
      // 0.1 to prevent player going to the start
      duration = clipData.asset_metadata.end - clipData.asset_metadata.start;
    }
    setCurrentSectionAndPlay(currentSection, !isPaused, Math.min(newTime, duration));
  }, [
    clipData.asset_metadata.end,
    clipData.asset_metadata.excludes,
    clipData.asset_metadata.start,
    currentSection,
    currentTimes,
    durations,
    isPaused,
    setCurrentSectionAndPlay
  ]);

  const useNewPlayer = useMemo(() => {
    return shouldUseNewRecordingsPlayer({
      event_type: coreStore.content?.event_type,
      live_end_time: coreStore.content?.live_end_time,
      individual_recording_status: coreStore.content?.individual_recording_status || 'UNAVAILABLE'
    });
  }, [coreStore.content?.event_type, coreStore.content?.live_end_time, coreStore.content?.individual_recording_status]);

  return (
    <div className="flex items-center justify-center gap-1">
      <IconButton
        buttonClassName="w-10 h-10 border-none group"
        icon="IconRewindBackward10"
        trackingId="full-recording-skip-back"
        variation="checkbox"
        onClick={skipBack}
      />
      <IconButton
        buttonClassName="w-10 h-10 border-none group"
        icon="IconPlayerSkipBack"
        trackingId="full-recording-skip-to-start"
        variation="checkbox"
        onClick={skipToStart}
      />
      {!useNewPlayer && <PlaybackRateButton />}
      <PlayPause paused={isPaused} onPlay={playCurrentSection} onPause={pauseCurrentSection} disabled={false} />
      <div className="mx-1.5 mt-[1px] text-sm tabular-nums text-slate-600">
        {convertSecondsToTimeString(Math.max(0, currentTimes[currentSection]), 'HH:mm:ss')}
        <span className="text-slate-400">
          <IconSlash size={20} stroke={1.5} className="ml-1 inline-flex -translate-y-[1.5px]" />{' '}
          {convertSecondsToTimeString(durations[currentSection], 'HH:mm:ss')}
        </span>
      </div>
      <IconButton
        buttonClassName="ml-2.5 w-10 h-10 border-none group"
        icon="IconPlayerSkipForward"
        trackingId="full-recording-skip-to-end"
        variation="checkbox"
        onClick={skipToEnd}
      />
      <IconButton
        buttonClassName="w-10 h-10 border-none group"
        icon="IconRewindForward10"
        trackingId="full-recording-skip-forward"
        variation="checkbox"
        onClick={skipForward}
      />
    </div>
  );
}

export default memo(SpeakersTimelinePlaybackControls);
