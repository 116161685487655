import { AbsoluteFill, getRemotionEnvironment, OffthreadVideo, Video } from 'remotion';
import { useMemo } from 'react';
import HlsVideo from '../HlsVideo';
import SpeakerLabel from './SpeakerLabel';
import { VideoElement } from '@/App/remotion/MainPlayer/types/Element/VideoElement';

const VideoElementComponent: React.FC<{
  element: VideoElement;
  disableMediaFragmentHints?: boolean;
  ignoreEndAt?: boolean;
}> = ({ element, disableMediaFragmentHints = false, ignoreEndAt = false }) => {
  const { fromFrame, toFrame, details, style, containerStyle } = element;

  const isPreview = !getRemotionEnvironment().isRendering;

  const VideoComp = useMemo(() => {
    if (!isPreview) {
      return OffthreadVideo;
    }

    if (details.src.includes('hls')) {
      return HlsVideo;
    }

    return Video;
  }, [details.src, isPreview]);

  const endAt = useMemo(() => {
    if (ignoreEndAt) return undefined;

    return toFrame;
  }, [ignoreEndAt, toFrame]);

  const videoSrc: string = useMemo(() => {
    if (isPreview && details.preview) {
      return details.preview;
    }

    return details.src;
  }, [details.src, isPreview, details.preview]);

  return (
    <AbsoluteFill style={{ ...containerStyle, containerType: 'inline-size' }}>
      <VideoComp
        startFrom={fromFrame}
        endAt={endAt}
        src={disableMediaFragmentHints ? `${videoSrc}#disable` : videoSrc}
        volume={Math.round(details.volume[0].value / 100)}
        style={{ pointerEvents: 'none', ...style }}
        onError={e => console.error(e)}
        pauseWhenBuffering
      />
      <SpeakerLabel speakerLabel={element.speakerLabel} />
    </AbsoluteFill>
  );
};

export default VideoElementComponent;
