import React, { useState, useCallback, useRef, useEffect, memo } from 'react';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';

interface SelectDropdownProps {
  options: Array<{ code: string; name: string }> | string[];
  selected: string;
  label?: string;
  onChange: (selected: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  menuClassName?: string;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  options,
  selected,
  label,
  onChange,
  placeholder = 'Select...',
  className = '',
  disabled = false,
  menuClassName = ''
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent): void => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mouseup', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  const handleButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!disabled) {
        setIsOpen(!isOpen);
      }
    },
    [disabled, isOpen]
  );

  const handleSelect = useCallback(
    (option: string): void => {
      onChange(option);
      setIsOpen(false);
    },
    [onChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent, value: string) => {
      if (e.key === 'Enter' || e.key === ' ') {
        handleSelect(value);
      }
    },
    [handleSelect]
  );

  return (
    <div ref={containerRef} className={`relative w-full ${className}`}>
      <button
        data-testid="select-dropdown-button"
        className={`inline-flex h-10 w-full items-center justify-between gap-2 whitespace-nowrap rounded-lg border bg-white px-4 py-2 text-sm font-medium transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 ${
          disabled ? 'pointer-events-none opacity-50' : ''
        }`}
        onClick={handleButtonClick}
      >
        <span className="block truncate">{selected || placeholder}</span>
        <IconChevronDown className="h-4 w-4 opacity-50" />
      </button>

      {isOpen && !disabled && (
        <div
          className={`absolute left-0 top-full z-[9999] mt-1 w-full overflow-hidden rounded-md border bg-white p-1 shadow-md transition-all ${menuClassName}`}
        >
          {/* Label */}
          {label && <div className="px-2 py-1.5 text-sm font-semibold">{label}</div>}

          {/* Separator */}
          {label && <hr className="-mx-1 my-1 h-px border-0 bg-gray-200" />}

          {/* Options */}
          {options.length > 0 ? (
            options.map((option, index) => {
              const value = typeof option === 'string' ? option : option.name;
              return (
                <div
                  key={value}
                  role="menuitem"
                  tabIndex={index}
                  className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors hover:bg-gray-100"
                  onClick={() => handleSelect(value)}
                  onKeyDown={e => handleKeyDown(e, value)}
                >
                  {selected === value && (
                    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                      <IconCheck className="h-4 w-4" />
                    </span>
                  )}
                  {value}
                </div>
              );
            })
          ) : (
            <div className="px-2 py-1.5 text-sm text-gray-500">No options available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(SelectDropdown);
