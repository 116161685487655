import { DEFAULT_LAYOUT_TYPE, DEFAULT_FIT_TYPE, DEFAULT_HIDE_BORDERS, DEFAULT_SIZE_TYPE } from '../../../constants';
import { LayoutChange } from '../../../types/Segment/LayoutChange';
import { PlayRanges } from '../../../types/Segment/PlayRange';
import { getSpeakerOverlaps } from './speakerOverlaps';
import { getAdjustedFrame, getFlattenedDeletes, getAvailableRanges } from './utils';
import { Clip } from '@/domains/asset';

export const getLayoutChangesAndPlayRanges = ({
  clip,
  fps,
  layout = DEFAULT_LAYOUT_TYPE,
  size = DEFAULT_SIZE_TYPE,
  fitMode = DEFAULT_FIT_TYPE,
  hideBorders = DEFAULT_HIDE_BORDERS
}: Pick<LayoutChange, 'layout' | 'size' | 'fitMode' | 'hideBorders'> & {
  clip: Clip;
  fps: number;
}) => {
  const speakerOverlaps = getSpeakerOverlaps({ clip, fps });

  let start = 0;
  const end = clip.asset_metadata.end * fps;

  let flattenedDeletes = getFlattenedDeletes({ clipDeletes: clip.asset_metadata.deletes, fps });
  if (flattenedDeletes.length > 0 && flattenedDeletes[0].fromFrame === 0) {
    start = flattenedDeletes[0].toFrame;
  }

  flattenedDeletes = flattenedDeletes.filter(deleteData => deleteData.fromFrame >= start && deleteData.toFrame <= end);

  const filteredSpeakerOverlaps = speakerOverlaps
    .filter(overlap => overlap.toFrame > start && overlap.fromFrame < end)
    .map(overlap => ({
      ...overlap,
      fromFrame: Math.max(overlap.fromFrame, start),
      toFrame: Math.min(overlap.toFrame, end)
    }));

  const layoutChanges: LayoutChange[] = [];
  const playRanges: PlayRanges = [];

  for (const overlap of filteredSpeakerOverlaps) {
    let finalLayout = layout;
    let finalVideoIds: string[] = overlap.videoIds;

    if (overlap.hasScreenshare && finalLayout !== 'SPEAKER') {
      finalLayout = 'FOCUS';
    }

    if (finalLayout === 'SPEAKER' || finalVideoIds.length === 1) {
      finalVideoIds = [finalVideoIds[0]];
      finalLayout = 'GRID';
    }

    const deletesCreepingIntoRange = flattenedDeletes.filter(
      deleteData => deleteData.toFrame > overlap.fromFrame && deleteData.fromFrame < overlap.toFrame
    );

    let overlaps: { fromFrame: number; toFrame: number; hasScreenshare: boolean }[] = [
      {
        fromFrame: overlap.fromFrame,
        toFrame: overlap.toFrame,
        hasScreenshare: overlap.hasScreenshare
      }
    ];

    if (deletesCreepingIntoRange.length > 0) {
      const availableRanges = getAvailableRanges([overlap.fromFrame, overlap.toFrame], deletesCreepingIntoRange);

      overlaps = availableRanges.map(range => ({
        fromFrame: range[0],
        toFrame: range[1],
        hasScreenshare: overlap.hasScreenshare
      }));
    }

    overlaps.forEach(overlap => {
      layoutChanges.push({
        fromFrame: getAdjustedFrame(overlap.fromFrame, flattenedDeletes, start),
        toFrame: getAdjustedFrame(overlap.toFrame, flattenedDeletes, start),
        videoIds: finalVideoIds,
        layout: finalLayout,
        size,
        fitMode,
        hideBorders,
        hasScreenshare: overlap.hasScreenshare
      });
      playRanges.push({
        fromFrame: Math.round(overlap.fromFrame),
        toFrame: Math.round(overlap.toFrame)
      });
    });
  }

  if (playRanges.length && playRanges[0].fromFrame !== 0 && start === 0) {
    playRanges.unshift({
      fromFrame: 0,
      toFrame: playRanges[0].fromFrame
    });
  }

  return { layoutChanges, playRanges };
};
