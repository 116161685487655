import { Sequence } from '../types/Sequence';

export function getAbsolutePlayerTime({ time, deleteBounds }: { time: number; deleteBounds: Sequence[] }) {
  if (!deleteBounds.length) {
    return time;
  }

  return (
    time -
    deleteBounds.reduce((acc, curr) => {
      if (curr.toFrame <= time) {
        acc += curr.toFrame - curr.fromFrame;
      }

      return acc;
    }, 0)
  );
}

export function getRelativePlayerTime({
  currentTime,
  deleteBounds
}: {
  currentTime: number;
  deleteBounds: Sequence[];
}) {
  if (!deleteBounds.length) {
    return currentTime;
  }

  let playerTime = currentTime;

  for (const deleteRange of deleteBounds) {
    if (playerTime >= deleteRange.fromFrame) {
      playerTime += deleteRange.toFrame - deleteRange.fromFrame;
    }
  }

  return playerTime;
}
