import createStore from './store';
import { FeatureFlagTypeMap } from '@/services/featureFlag';

export type FeatureFlagStore = {
  [K in keyof FeatureFlagTypeMap]?: FeatureFlagTypeMap[K];
} & {
  areFlagsLoaded: boolean;
};

const featureFlagStore = createStore<FeatureFlagStore>({
  areFlagsLoaded: false
});

export default featureFlagStore;
