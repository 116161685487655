import { memo } from 'react';
import { IconBrandYoutube, IconCalendar, IconCalendarEvent, IconLoader2 } from '@tabler/icons-react';
import Button from '@/components/atoms/Button/Button';
import usePublishAsset from '@/hooks/usePublishAsset';

function RecordingPublishDialog({ onClose }: { onClose: () => void }) {
  const { publishAsset, isPublishing } = usePublishAsset();

  const handlePublish = async () => {
    await publishAsset();
    onClose();
  };

  return (
    <div className="relative flex flex-col overflow-y-auto overflow-x-hidden">
      <div className="grow px-5 pb-5">
        <h1 className="font-medium">Publish</h1>
        <div className="mt-1.5 w-full text-sm leading-tight text-slate-600">
          You're about to publish and update this video. The processing time will be approximately equal to the video
          length.
          <br />
          <br />
          The changes will only apply to English captions, and other language subtitles will no longer be available for
          your video.
          <br />
          <br />
          This will replace the current version wherever it is being used, including:
          <div className="mt-5">
            <button className="group flex w-full items-center space-x-3 rounded-lg p-2 py-3 pl-3 text-left">
              <div>
                <IconCalendar size={20} />
              </div>
              <div className="grow truncate">
                <div className="text-sm font-medium leading-tight">On-demand pages</div>
              </div>
            </button>
            <button className="group flex w-full items-center space-x-3 rounded-lg p-2 py-3 pl-3 text-left">
              <div>
                <IconBrandYoutube size={20} />
              </div>
              <div className="grow truncate">
                <div className="text-sm font-medium leading-tight">Video Hubs</div>
              </div>
            </button>
            <button className="group flex w-full items-center space-x-3 rounded-lg p-2 py-3 pl-3 text-left">
              <div>
                <IconCalendarEvent size={20} />
              </div>
              <div className="grow truncate">
                <div className="text-sm font-medium leading-tight">Events and Booths</div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-3 border-t border-t-slate-200 px-5 pb-3 pt-2.5">
        <Button variation="outline" trackingId="cancel-publishing" onClick={onClose}>
          Cancel
        </Button>
        <Button variation="filled" trackingId="publish-content" disabled={isPublishing} onClick={handlePublish}>
          Publish changes
          {isPublishing && <IconLoader2 className="ml-2 animate-spin" size={16} />}
        </Button>
      </div>
    </div>
  );
}

export default memo(RecordingPublishDialog);
