import React, { useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { Location, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserWorkspaceResponse } from '@goldcast/api/backend';
import { getBaseUrlPath } from './utils';
import Loader from '@/components/atoms/Loader';
import Clip from '@/Pages/Clip';
import SessionDetail from '@/Pages/SessionDetail';
import TranscriptPage from '@/Pages/TranscriptPage';
import Sessions from '@/Pages/Sessions/Sessions';
import { initUser } from '@/stores/user';
import { initializeFeatureFlags } from '@/services/featureFlag';
import ErrorBoundary from '@/App/ErrorBoundary/ErrorBoundary';
import { useAppContext } from '@/context/AppContext/AppContext';
import Homepage from '@/Pages/Homepage/Homepage';
import BrandKit from '@/Pages/BrandKit/BrandKit';
import featureFlagStore from '@/stores/featureFlagStore';
import { loadOrgUsageSummary } from '@/stores/orgUsageSummary';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import ClipsListPage from '@/Pages/ClipsListPage';
import { POST_TYPES } from '@/Pages/PostsListPage/constants';
import useAnalytics from '@/hooks/useAnalytics';
import { getEnvConfig } from '@/constants';
import VideoImportPage from '@/Pages/VideoImportPage/VideoImportPage';
import VideoImportProcessingPage from '@/Pages/VideoImportPage/VideoImportProcessingPage';
import { initFirstTimeUserStore } from '@/stores/firstTimeUserStore';
import SavedSearch from '@/Pages/SavedSearch';
import PostsListPageWrapper from '@/Pages/PostsListPage/PostsListPageWrapper';
import SavedSearchPostsListPageWrapper from '@/Pages/SavedSearch/SavedSearchPostsListPageWrapper';
import { VideoImportContextProvider } from '@/Pages/VideoImportPage/VideoImportContext';
import RecordingsPage from '@/Pages/RecordingsPage';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

let previousLocation: Location;

export default function Home(): JSX.Element {
  const [user, setUser] = useState<UserWorkspaceResponse | undefined>();
  const [loading, setLoading] = useState(true);
  const { logger, adminAppStore, adminAppRouter } = useAppContext();

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);

  const { isUsageSummaryLoading } = useFreeTrialHook();
  const { trackRouteChange, trackOrgUsage } = useAnalytics();

  const router = createBrowserRouter(
    [
      {
        path: '/',
        ErrorBoundary,
        element: <Homepage />,
        children: [
          {
            path: '/',
            element: <Sessions />
          },
          {
            path: '/brand-kit',
            element: <BrandKit />
          },
          {
            path: '/import',
            element: (
              <VideoImportContextProvider>
                <VideoImportPage />
              </VideoImportContextProvider>
            ),
            children: [
              {
                path: '/import/:eventId/:broadcastId',
                element: <VideoImportProcessingPage />
              }
            ]
          }
        ]
      },
      {
        path: '/:eventId/:broadcastId',
        element: <SessionDetail />,
        ErrorBoundary,
        children: [
          {
            path: '/:eventId/:broadcastId/recordings',
            element: <RecordingsPage />
          },
          {
            path: '/:eventId/:broadcastId/clips',
            element: <ClipsListPage />
          },
          {
            path: '/:eventId/:broadcastId/social',
            element: <PostsListPageWrapper postType={POST_TYPES.SOCIAL} />
          },
          {
            path: '/:eventId/:broadcastId/blog',
            element: <PostsListPageWrapper postType={POST_TYPES.BLOG} />
          },
          {
            path: '/:eventId/:broadcastId/email',
            element: <PostsListPageWrapper postType={POST_TYPES.EMAIL} />
          },
          {
            path: '/:eventId/:broadcastId/takeaways',
            element: <PostsListPageWrapper postType={POST_TYPES.TAKEAWAYS} />
          },
          {
            path: '/:eventId/:broadcastId/custom',
            element: <PostsListPageWrapper postType={POST_TYPES.CUSTOM} />
          },
          {
            path: '/:eventId/:broadcastId/transcript',
            element: <TranscriptPage />
          }
        ]
      },
      {
        path: '/search',
        element: <SavedSearch />,
        ErrorBoundary,
        children: [
          {
            path: '/search/:id/clips',
            element: <ClipsListPage />
          },
          {
            path: '/search/:id/social',
            element: <SavedSearchPostsListPageWrapper postType={POST_TYPES.SOCIAL} />
          },
          {
            path: '/search/:id/blog',
            element: <SavedSearchPostsListPageWrapper postType={POST_TYPES.BLOG} />
          }
        ]
      },
      {
        path: '/:eventId/:broadcastId/clips/:clipId',
        element: <Clip />,
        ErrorBoundary
      },
      {
        /** This is for manually handling any errors that come in code
         * For example, when clip has been deleted and we move to /:clipId
         * Or if eventId or broadcastId is invalid and we move to /:eventId/:broadcastId */
        path: '/error/:code',
        element: <ErrorBoundary />
      }
    ],
    {
      basename: getBaseUrlPath()
    }
  );

  router.subscribe(({ location: toLocation }) => {
    trackRouteChange(previousLocation, toLocation);

    previousLocation = toLocation;
  });

  const isContentAppLoading = useMemo(() => {
    if (loading) {
      return true;
    }

    if (user && !featureFlags.areFlagsLoaded) {
      return true;
    }

    if (isUsageSummaryLoading) {
      return true;
    }

    return false;
  }, [loading, user, featureFlags.areFlagsLoaded, isUsageSummaryLoading]);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.SignOut, triggerSignOut);
    return () => {
      EventBus.off(CustomEvents.SignOut, eventListener);
    };
  }, []);

  function triggerSignOut() {
    adminAppStore.dispatch?.('user/signUserOut').then(() => {
      adminAppRouter.push({
        name: adminAppStore.user?.is_content_lab_standalone ? 'ContentLabLogin' : 'Login'
      });
    });
  }

  useEffect(() => {
    if (!window.configs) {
      return;
    }
    window.configs['CONTENT_LAB_BUILD_NUMBER'] = getEnvConfig('CONTENT_LAB_BUILD_NUMBER');
  }, []);

  useEffect(() => {
    initFirstTimeUserStore(!!adminAppStore.currentOrg?.newbie_content_user);
    initUser(adminAppStore)
      .then(user => {
        setUser(user);
        loadOrgUsageSummary(user.organization).then(() => trackOrgUsage());
        initializeFeatureFlags(user);
        return user;
      })
      .catch(e => {
        adminAppRouter.push({
          name: 'Login'
        });
        logger.warn('Failed to initialize user', e?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [adminAppStore]);

  return (
    <div className="content-lab-root">
      {isContentAppLoading ? (
        <div className="absolute inset-0 z-10 flex h-screen items-center justify-center">
          <Loader />
        </div>
      ) : (
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      )}
    </div>
  );
}
