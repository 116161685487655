import { IconEdit } from '@tabler/icons-react';
import { useCallback, useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import Avatar from '@/components/atoms/Avatar/Avatar';
import Toggle from '@/components/atoms/Toggle';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { classnames, getSpeakerImage } from '@/libs/utils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { updateVisibleVideoIds } from '@/stores/clip';
import { TrackType } from '@/App/remotion/MainPlayer/types/Track/Type';
import { SegmentType } from '@/App/remotion/MainPlayer/types/Segment/Type';
import { SegmentsTrack } from '@/App/remotion/MainPlayer/types/Track/SegmentsTrack';
import { CompositeSegment } from '@/App/remotion/MainPlayer/types/Segment/CompositeSegment';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { alignedRecordingIdToSpeakerKeyMap } from '@/App/remotion/MainPlayer/stores/alignedRecordings';
import Icon from '@/components/atoms/Icon';

export default function VideosToggler() {
  const [allVideoIds, setAllVideoIds] = useState<string[]>([]);

  const videoIdToSpeakerKeyMap = useSyncExternalStore(
    alignedRecordingIdToSpeakerKeyMap.subscribe,
    alignedRecordingIdToSpeakerKeyMap.getSnapshot
  );

  const transcriptStore = useTranscriptContext();
  const { clipId, clipData } = useClipsContext();

  const visibleVideoIds = clipData?.asset_metadata?.visible_video_ids;

  useEffect(() => {
    const segmentTrack = clipData.asset_metadata.player_config?.playerConfig.tracks.find(
      t => t.type === TrackType.SEGMENTS
    ) as SegmentsTrack;

    const compositeSegment = segmentTrack?.segments.find(s => s.type === SegmentType.COMPOSITE) as CompositeSegment;

    const videoIds = compositeSegment?.videos.map(v => v.id);

    if (!visibleVideoIds?.length) {
      updateVisibleVideoIds(clipId, videoIds || []);
    }

    setAllVideoIds(videoIds || []);
  }, [visibleVideoIds]);

  const [screenshareVideos, nonScreenshareVideos] = useMemo(
    () => [
      allVideoIds.filter(id => videoIdToSpeakerKeyMap[id]?.includes('Screenshare')),
      allVideoIds.filter(id => !videoIdToSpeakerKeyMap[id]?.includes('Screenshare'))
    ],
    [allVideoIds, videoIdToSpeakerKeyMap]
  );

  const setVisibleVideoId = useCallback(
    (videoId?: string) => {
      if (!videoId || !visibleVideoIds) return;

      const speakerId = videoIdToSpeakerKeyMap[videoId];
      const isScreenshare = speakerId?.includes('Screenshare');

      // If toggling screenshare, handle all screenshare videos together
      if (isScreenshare) {
        const areAllScreenshareVisible = screenshareVideos.every(id => visibleVideoIds.includes(id));

        const newVisibleVideoIds = [
          ...(areAllScreenshareVisible ? [] : screenshareVideos),
          ...nonScreenshareVideos.filter(id => visibleVideoIds.includes(id))
        ];
        updateVisibleVideoIds(clipId, newVisibleVideoIds);
        return;
      }

      // Handle non-screenshare video toggle
      const newVisibleVideoIds = [
        ...screenshareVideos.filter(id => visibleVideoIds.includes(id)), // Preserve current screenshare state
        ...nonScreenshareVideos.filter(
          id =>
            (visibleVideoIds.includes(id) && id !== videoId) || // Keep other visible videos
            (!visibleVideoIds.includes(id) && id === videoId) // Toggle the clicked video
        )
      ];
      updateVisibleVideoIds(clipId, newVisibleVideoIds);
    },
    [clipId, visibleVideoIds, screenshareVideos, nonScreenshareVideos, videoIdToSpeakerKeyMap]
  );

  const triggerSpeakerEdit = useCallback((speakerId?: string) => {
    if (!speakerId) return;

    EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId });
  }, []);

  const allAvailableSpeakers = transcriptStore.speakersWithDetails;

  const areAllScreenshareVideosVisible =
    screenshareVideos.length > 0 && screenshareVideos.every(id => visibleVideoIds?.includes(id));

  return (
    <div className="mt-1 max-h-[260px] rounded-md border-t py-2">
      {/* Render screenshare toggle first */}
      {screenshareVideos.length > 0 && (
        <div className="flex items-center justify-between rounded-md py-2.5">
          <div className="flex items-center gap-2">
            <div className="flex w-full flex-row items-center gap-2 truncate">
              <Icon name="IconScreenShare" className="mx-1" />
              <span className="truncate text-sm">Screenshare</span>
            </div>
          </div>
          <Toggle
            on={areAllScreenshareVideosVisible}
            onToggle={() => setVisibleVideoId(screenshareVideos[0])}
            disabled={false}
          />
        </div>
      )}

      {nonScreenshareVideos.map(videoId => {
        const isToggleOn = visibleVideoIds?.find(id => id === videoId) !== undefined;
        const toggleDisabled = visibleVideoIds?.length === 1 && isToggleOn;

        const speakerId = videoIdToSpeakerKeyMap[videoId];
        let label = 'Map Missing';
        let isLabelEditDisabled = true;
        const speaker = Object.values(allAvailableSpeakers).find(s => s.key === speakerId);

        if (speaker) {
          label = speaker?.first_name + ' ' + speaker?.last_name;
          isLabelEditDisabled = false;
        }

        return (
          <div className="flex items-center justify-between rounded-md py-2.5" key={videoId}>
            <div className="flex items-center gap-2">
              <div
                className={classnames(
                  'flex w-full flex-row items-center gap-2 truncate',
                  isLabelEditDisabled ? 'cursor-not-allowed' : 'group cursor-pointer'
                )}
                onClick={() => {
                  if (isLabelEditDisabled) return;
                  triggerSpeakerEdit(speakerId);
                }}
              >
                <Avatar className="shrink-0" name={label} imageUrl={getSpeakerImage(speaker?.profile_picture_url)} />
                <span className="truncate text-sm">{label}</span>
                <div className="hidden group-hover:block">
                  <IconEdit size={16} />
                </div>
              </div>
            </div>

            <Toggle
              on={isToggleOn}
              onToggle={() => {
                if (toggleDisabled) return;
                setVisibleVideoId(videoId);
              }}
              disabled={toggleDisabled}
            />
          </div>
        );
      })}
    </div>
  );
}
