export const SQUARE_COMPILED_CONFIG = {
  GRID: {
    FIT: {
      'with-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.648%',
              left: '1.481%',
              width: '97.037%',
              height: '30.703%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.88%',
              left: '1.481%',
              width: '97.037%',
              height: '30.703%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '1.481%',
              width: '97.037%',
              height: '30.703%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '3.111%',
              left: '1.481%',
              width: '97.037%',
              height: '30.703%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.648%',
              left: '1.481%',
              width: '97.037%',
              height: '30.703%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.184%',
              left: '1.481%',
              width: '97.037%',
              height: '30.703%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.466%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.466%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.49%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.49%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '42.441%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '42.441%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '58.391%',
              left: '26.111%',
              width: '47.777%',
              height: '15.117%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.49%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '26.49%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '42.441%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '42.441%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '58.391%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '58.391%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.515%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.515%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.466%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.466%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.367%',
              left: '26.111%',
              width: '47.777%',
              height: '15.117%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.515%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '18.515%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.466%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '34.466%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.367%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.367%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          }
        ]
      },
      'without-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.179%',
              left: '0%',
              width: '100%',
              height: '31.64%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '18.359%',
              left: '0%',
              width: '100%',
              height: '31.64%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '100%',
              height: '31.64%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '2.539%',
              left: '0%',
              width: '100%',
              height: '31.64%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.179%',
              left: '0%',
              width: '100%',
              height: '31.64%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '65.82%',
              left: '0%',
              width: '100%',
              height: '31.64%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.179%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.179%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '26.269%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '26.269%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '42.089%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '42.089%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '57.91%',
              left: '25%',
              width: '50%',
              height: '15.82%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '26.269%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '26.269%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '42.089%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '42.089%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '57.91%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '57.91%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '18.359%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '18.359%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.179%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.179%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '65.82%',
              left: '25%',
              width: '50%',
              height: '15.82%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '18.359%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '18.359%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.179%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '34.179%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '65.82%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '65.82%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          }
        ]
      }
    },
    FILL: {
      'with-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '98.333%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '1.481%',
              width: '97.037%',
              height: '48.75%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '1.481%',
              width: '97.037%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.944%',
              left: '1.481%',
              width: '97.037%',
              height: '32.222%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '1.481%',
              width: '47.777%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '50.74%',
              width: '47.777%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '1.481%',
              width: '47.777%',
              height: '48.75%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '50.74%',
              width: '47.777%',
              height: '48.75%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '1.481%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '50.74%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '1.481%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '50.74%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.944%',
              left: '26.111%',
              width: '47.777%',
              height: '32.222%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '1.481%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '50.74%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '1.481%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '33.888%',
              left: '50.74%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.944%',
              left: '1.481%',
              width: '47.777%',
              height: '32.222%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '66.944%',
              left: '50.74%',
              width: '47.777%',
              height: '32.222%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '1.481%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '50.74%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '25.624%',
              left: '1.481%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '25.624%',
              left: '50.74%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '1.481%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '50.74%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.208%',
              left: '26.111%',
              width: '47.777%',
              height: '23.958%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '1.481%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '0.833%',
              left: '50.74%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '25.624%',
              left: '1.481%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '25.624%',
              left: '50.74%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '1.481%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '50.416%',
              left: '50.74%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.208%',
              left: '1.481%',
              width: '47.777%',
              height: '23.958%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.208%',
              left: '50.74%',
              width: '47.777%',
              height: '23.958%'
            }
          }
        ]
      },
      'without-border': {
        '1': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '100%'
            }
          }
        ],
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '100%',
              height: '50%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '0%',
              width: '100%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '0%',
              width: '100%',
              height: '33.333%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '50%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '50%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '0%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '50%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '25%',
              width: '50%',
              height: '33.333%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '0%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '33.333%',
              left: '50%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '0%',
              width: '50%',
              height: '33.333%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '66.666%',
              left: '50%',
              width: '50%',
              height: '33.333%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '0%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '50%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '25%',
              width: '50%',
              height: '25%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '0%',
              left: '50%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '0%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '25%',
              left: '50%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '0%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '0%',
              width: '50%',
              height: '25%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75%',
              left: '50%',
              width: '50%',
              height: '25%'
            }
          }
        ]
      }
    }
  },
  FOCUS: {
    FIT: {
      'with-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.29%',
              left: '1.481%',
              width: '97.037%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '68.006%',
              left: '1.481%',
              width: '97.037%',
              height: '30.703%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '9.083%',
              left: '1.481%',
              width: '97.037%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.799%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.799%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.107%',
              left: '1.481%',
              width: '97.037%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.824%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.824%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '1.107%',
              left: '1.481%',
              width: '97.037%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.824%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '67.824%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '1.481%',
              width: '47.777%',
              height: '15.117%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '50.74%',
              width: '47.777%',
              height: '15.117%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '6.303%',
              left: '1.481%',
              width: '97.037%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '73.02%',
              left: '1.481%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '73.02%',
              left: '34.32%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '73.02%',
              left: '67.16%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '1.481%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '34.32%',
              width: '31.357%',
              height: '9.921%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '6.303%',
              left: '1.481%',
              width: '97.037%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '73.02%',
              left: '1.481%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '73.02%',
              left: '34.32%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '73.02%',
              left: '67.16%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '1.481%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '34.32%',
              width: '31.357%',
              height: '9.921%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '67.16%',
              width: '31.357%',
              height: '9.921%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '8.9%',
              left: '1.481%',
              width: '97.037%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.617%',
              left: '1.481%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.617%',
              left: '26.111%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.617%',
              left: '50.74%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.617%',
              left: '75.37%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '1.481%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '26.111%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '50.74%',
              width: '23.148%',
              height: '7.324%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '8.9%',
              left: '1.481%',
              width: '97.037%',
              height: '65.882%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.617%',
              left: '1.481%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.617%',
              left: '26.111%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.617%',
              left: '50.74%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '75.617%',
              left: '75.37%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '1.481%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '26.111%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '50.74%',
              width: '23.148%',
              height: '7.324%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '83.774%',
              left: '75.37%',
              width: '23.148%',
              height: '7.324%'
            }
          }
        ]
      },
      'without-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0.679%',
              left: '0%',
              width: '100%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '67.679%',
              left: '0%',
              width: '100%',
              height: '31.64%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '8.589%',
              left: '0%',
              width: '100%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0.679%',
              left: '0%',
              width: '100%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '67.679%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '67.679%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0.679%',
              left: '0%',
              width: '100%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '67.679%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '67.679%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '0%',
              width: '50%',
              height: '15.82%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '50%',
              width: '50%',
              height: '15.82%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '5.952%',
              left: '0%',
              width: '100%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '72.952%',
              left: '0%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '72.952%',
              left: '33.333%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '72.952%',
              left: '66.666%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '0%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '33.333%',
              width: '33.333%',
              height: '10.546%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '5.952%',
              left: '0%',
              width: '100%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '72.952%',
              left: '0%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '72.952%',
              left: '33.333%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '72.952%',
              left: '66.666%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '0%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '33.333%',
              width: '33.333%',
              height: '10.546%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '66.666%',
              width: '33.333%',
              height: '10.546%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '8.589%',
              left: '0%',
              width: '100%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '0%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '25%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '50%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '75%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '0%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '25%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '50%',
              width: '25%',
              height: '7.91%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '8.589%',
              left: '0%',
              width: '100%',
              height: '66.999%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '0%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '25%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '50%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '75.589%',
              left: '75%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '0%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '25%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '50%',
              width: '25%',
              height: '7.91%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '83.499%',
              left: '75%',
              width: '25%',
              height: '7.91%'
            }
          }
        ]
      }
    },
    FILL: {
      'with-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '39.116%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '1.481%',
              width: '97.037%',
              height: '58.383%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '39.116%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '1.481%',
              width: '47.777%',
              height: '58.383%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '50.74%',
              width: '47.777%',
              height: '58.383%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '39.116%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '1.481%',
              width: '47.777%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '50.74%',
              width: '47.777%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '1.481%',
              width: '47.777%',
              height: '28.775%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '39.116%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '1.481%',
              width: '47.777%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '50.74%',
              width: '47.777%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '1.481%',
              width: '47.777%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '50.74%',
              width: '47.777%',
              height: '28.775%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '39.116%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '1.481%',
              width: '31.357%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '34.32%',
              width: '31.357%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '67.16%',
              width: '31.357%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '1.481%',
              width: '31.357%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '34.32%',
              width: '31.357%',
              height: '28.775%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '39.116%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '1.481%',
              width: '31.357%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '34.32%',
              width: '31.357%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '67.16%',
              width: '31.357%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '1.481%',
              width: '31.357%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '34.32%',
              width: '31.357%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '67.16%',
              width: '31.357%',
              height: '28.775%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '39.116%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '1.481%',
              width: '23.148%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '26.111%',
              width: '23.148%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '50.74%',
              width: '23.148%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '75.37%',
              width: '23.148%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '1.481%',
              width: '23.148%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '26.111%',
              width: '23.148%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '50.74%',
              width: '23.148%',
              height: '28.775%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '8px',
              top: '0.833%',
              left: '1.481%',
              width: '97.037%',
              height: '39.116%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '1.481%',
              width: '23.148%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '26.111%',
              width: '23.148%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '50.74%',
              width: '23.148%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '40.783%',
              left: '75.37%',
              width: '23.148%',
              height: '28.774%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '1.481%',
              width: '23.148%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '26.111%',
              width: '23.148%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '50.74%',
              width: '23.148%',
              height: '28.775%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '8px',
              position: 'absolute',
              top: '70.391%',
              left: '75.37%',
              width: '23.148%',
              height: '28.775%'
            }
          }
        ]
      },
      'without-border': {
        '2': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '40.119%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '0%',
              width: '100%',
              height: '59.88%'
            }
          }
        ],
        '3': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '40.119%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '0%',
              width: '50%',
              height: '59.88%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '50%',
              width: '50%',
              height: '59.88%'
            }
          }
        ],
        '4': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '40.119%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '0%',
              width: '50%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '50%',
              width: '50%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '0%',
              width: '50%',
              height: '29.94%'
            }
          }
        ],
        '5': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '40.119%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '0%',
              width: '50%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '50%',
              width: '50%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '0%',
              width: '50%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '50%',
              width: '50%',
              height: '29.94%'
            }
          }
        ],
        '6': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '40.119%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '0%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '33.333%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '66.666%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '0%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '33.333%',
              width: '33.333%',
              height: '29.94%'
            }
          }
        ],
        '7': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '40.119%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '0%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '33.333%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '66.666%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '0%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '33.333%',
              width: '33.333%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '66.666%',
              width: '33.333%',
              height: '29.94%'
            }
          }
        ],
        '8': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '40.119%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '0%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '25%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '50%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '75%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '0%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '25%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '50%',
              width: '25%',
              height: '29.94%'
            }
          }
        ],
        '9': [
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: '0px',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '40.119%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '0%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '25%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '50%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '40.119%',
              left: '75%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '0%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '25%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '50%',
              width: '25%',
              height: '29.94%'
            }
          },
          {
            style: {
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            },
            containerStyle: {
              overflow: 'hidden',
              borderRadius: '0px',
              position: 'absolute',
              top: '70.059%',
              left: '75%',
              width: '25%',
              height: '29.94%'
            }
          }
        ]
      }
    }
  }
};
