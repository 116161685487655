import { CompositeSegment } from '../../../types/Segment/CompositeSegment';
import { SegmentType } from '../../../types/Segment/Type';
import { VideoElement } from '../../../types/Element/VideoElement';
import { ElementType } from '../../../types/Element/Type';
import { getLayoutChangesAndPlayRanges } from './getLayoutChangesAndPlayRanges';
import { alignedRecordings } from '../../../stores/alignedRecordings';
import { getSpeakerLabelConfig } from './getSpeakerLabel';
import { Clip } from '@/domains/asset';
import { DEFAULT_COLOR } from '@/libs/core';

export function createMainCompositeSegment(
  clip: Clip,
  fps: number,
  duration: number,
  offset: number,
  fromFrame: number,
  toFrame: number
): CompositeSegment {
  const alignedRecordingsStore = alignedRecordings.getSnapshot();

  const videos: VideoElement[] = [];

  alignedRecordingsStore.forEach((recording, index) => {
    const showSpeakerLabels =
      clip.asset_metadata.magicLayout?.showSpeakerLabels && 'aligned_screenshare_feed' !== recording.entity;

    videos.push({
      id: recording.id,
      name: 'Video ' + recording.entity + index,
      type: ElementType.VIDEO,
      fromFrame,
      toFrame,
      details: {
        src: recording.mp4_url.replace(/([^:])\/\//g, '$1/'),
        preview: recording.preview_url,
        altText: 'Video ' + recording.entity + index,
        volume: [
          {
            value: 100,
            fromFrame,
            toFrame
          }
        ]
      },
      trim: [],
      style: {},
      containerStyle: {},
      speakerLabel: showSpeakerLabels ? getSpeakerLabelConfig(recording.id, clip) : undefined
    });
  });

  const asset_metadata = clip.asset_metadata;
  const layout = asset_metadata.layout;
  const size = asset_metadata.size;
  const fitMode = asset_metadata.fit;
  const hideBorders = !!asset_metadata.hide_borders;

  const { layoutChanges, playRanges } = getLayoutChangesAndPlayRanges({
    clip,
    fps,
    layout,
    size,
    fitMode,
    hideBorders
  });

  return {
    id: 'segment',
    name: 'Composite',
    type: SegmentType.COMPOSITE,
    scenes: [],
    videos,
    layoutChanges,
    playRanges,
    fromFrame: offset,
    toFrame: offset + duration,
    details: {
      background: {
        type: asset_metadata.magicLayout?.backgroundImage ? 'IMAGE_URL' : 'COLOR',
        value:
          asset_metadata.magicLayout?.backgroundImage || asset_metadata.magicLayout?.backgroundColor || DEFAULT_COLOR
      }
    },
    projectId: clip.content.project.id,
    contentId: clip.content.id,
    maxBounds: {
      fromFrame,
      toFrame
    },
    currentBounds: {
      fromFrame,
      toFrame
    }
  };
}
