import { useState, useEffect, useSyncExternalStore } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRemainingProcessingTimeInSec } from '../VideoImportPage/utils';
import { ContentErrorTypeMap, ContentStatesEnum } from '../VideoImportPage/constants';
import VideoImportProgressToast from '../VideoImportPage/VideoImportProgressToast';
import { classnames } from '@/libs/utils';
import useContentUpload from '@/hooks/useContentUpload';
import { CustomEvents } from '@/libs/eventBus/constants';
import { usePusherContext } from '@/context/PusherContext/PusherContext';
import EventBus from '@/libs/eventBus/eventBus';
import AlertDialog from '@/components/organisms/AlertDialog';
import { disableFirstTimeUser, firstTimeUserStore } from '@/stores/firstTimeUserStore';

const SESSION_STORAGE_PROCESSING_COMPLETE_TIMES = 'processingCompleteTimes';

export default function VideoImportProcessingPage() {
  const { broadcastId } = useParams<{ broadcastId: string }>();
  const [currentProcessingTime, setCurrentProcessingTime] = useState<number | null>(null);
  const isFirstTimeUser = useSyncExternalStore(firstTimeUserStore.subscribe, firstTimeUserStore.getSnapshot);

  const {
    getContentUploadById,
    updateContentState,
    removeContentUploadEntry,
    changeContentUploadStatus,
    handleConfettiState,
    cancelUpload,
    client,
    trackFilestackError
  } = useContentUpload();

  const navigate = useNavigate();

  const contentUpload = getContentUploadById(broadcastId);
  const { contentState, projectId, contentId, totalPercentageUploaded, error } = contentUpload;
  const { isPusherConnected } = usePusherContext();

  useEffect(() => {
    client.on('upload.error', trackFilestackError);
    return () => {
      client.off('upload.error', trackFilestackError);
    };
  }, [client, trackFilestackError]);

  useEffect(() => {
    if (
      contentState === ContentStatesEnum.Processing &&
      contentUpload.videoProcessingTime &&
      contentUpload.contentUploadedTime
    ) {
      const uploadedTime = new Date(contentUpload.contentUploadedTime);
      const remainingSeconds = Number(contentUpload.videoProcessingTime);
      let processingTimeInterval = setInterval(() => {
        const remainingProcessingTime = getRemainingProcessingTimeInSec(uploadedTime, remainingSeconds);
        setCurrentProcessingTime(remainingProcessingTime);
      }, 1000);

      return () => {
        clearInterval(processingTimeInterval);
      };
    }
  }, [contentUpload.videoProcessingTime, contentUpload.contentUploadedTime, contentState]);

  useEffect(() => {
    if (!broadcastId) {
      return;
    }

    if (contentState === ContentStatesEnum.Generating && contentUpload.clipGenerationEstimatedTime) {
      setCurrentProcessingTime(null);

      const processingCompleteTimes = JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_PROCESSING_COMPLETE_TIMES) || '{}'
      );
      const storedProcessingCompleteTime = processingCompleteTimes[broadcastId];
      let processingCompleteTime = new Date();

      if (storedProcessingCompleteTime) {
        processingCompleteTime = new Date(storedProcessingCompleteTime);
      } else {
        processingCompleteTimes[broadcastId] = processingCompleteTime;
        sessionStorage.setItem(SESSION_STORAGE_PROCESSING_COMPLETE_TIMES, JSON.stringify(processingCompleteTimes));
      }

      const remainingSeconds = Number(contentUpload.clipGenerationEstimatedTime);
      let processingTimeInterval = setInterval(() => {
        const remainingProcessingTime = getRemainingProcessingTimeInSec(processingCompleteTime, remainingSeconds);
        setCurrentProcessingTime(remainingProcessingTime);
      }, 1000);

      return () => {
        clearInterval(processingTimeInterval);
      };
    }
  }, [contentUpload.clipGenerationEstimatedTime, contentState, broadcastId]);

  useEffect(() => {
    if (contentState === ContentStatesEnum.Cancelled) {
      navigate('/');
      return;
    }
  }, [contentState]);

  useEffect(() => {
    if (!isPusherConnected) {
      return;
    }
    const contentProcessingListener = EventBus.on(CustomEvents.TranscriptionStatusUpdated, data =>
      changeContentUploadStatus(data, ContentStatesEnum.Processing, contentId)
    );
    const clipGenerationListener = EventBus.on(CustomEvents.ClipGenerationStatusUpdated, data =>
      changeContentUploadStatus(data, ContentStatesEnum.Generating, contentId)
    );
    const routeChangeListener = EventBus.on(CustomEvents.NavigateToContentLabHome, () => navigate('/'));
    return () => {
      if (contentProcessingListener) {
        EventBus.off(CustomEvents.TranscriptionStatusUpdated, contentProcessingListener);
      }
      if (clipGenerationListener) {
        EventBus.off(CustomEvents.ClipGenerationStatusUpdated, clipGenerationListener);
      }
      if (routeChangeListener) {
        EventBus.off(CustomEvents.NavigateToContentLabHome, routeChangeListener);
      }
    };
  }, [isPusherConnected, changeContentUploadStatus, contentId, navigate]);

  useEffect(() => {
    if (contentState !== ContentStatesEnum.Uploading && isFirstTimeUser) {
      disableFirstTimeUser();
    }
  }, [contentState, isFirstTimeUser]);

  useEffect(() => {
    if (contentState !== ContentStatesEnum.GeneratingCompleted) return;
    handleConfettiState({ showConfetti: true, contentId });
    updateContentState(ContentStatesEnum.ContentReadyForPreview, contentId);
  }, [contentState, projectId, contentId, navigate, handleConfettiState, updateContentState]);

  const handleErrorConfirmation = () => {
    removeContentUploadEntry(contentId);
    navigate('/');
  };

  const handleCancelUpload = () => {
    cancelUpload(contentId);
  };

  return (
    <>
      <div className="pointer-events-none flex h-full w-full overflow-hidden bg-slate-100">
        <div
          className={classnames(
            'relative mt-1 flex h-full grow flex-col overflow-hidden transition-all duration-[400ms] ease-in-out',
            { 'pl-[2rem]': !isFirstTimeUser }
          )}
        >
          <div
            className={classnames({
              'w-full py-3': true,
              'pt-6': contentState !== ContentStatesEnum.Uploading
            })}
          >
            <VideoImportProgressToast
              type={ContentStatesEnum.Uploading}
              title="Uploading"
              totalPercentageUploaded={totalPercentageUploaded}
              message="Your upload will continue smoothly even if you navigate away from this page."
              onCancel={handleCancelUpload}
              isVisible={contentState === ContentStatesEnum.Uploading}
              activeState={contentState}
            />
            <VideoImportProgressToast
              type={ContentStatesEnum.Processing}
              title="Processing & Analyzing"
              message="We're transcribing your video and extracting key insights."
              isVisible={[ContentStatesEnum.Uploading, ContentStatesEnum.Processing].includes(contentState)}
              activeState={contentState}
              currentProcessingTime={currentProcessingTime}
            />
            <div
              className={classnames(
                ' flex -translate-y-12 justify-center',
                contentState === ContentStatesEnum.Uploading
                  ? 'scale-[0.94]'
                  : contentState === ContentStatesEnum.Processing
                  ? 'scale-[0.97]'
                  : 'pt-6'
              )}
            >
              <VideoImportProgressToast
                type={ContentStatesEnum.Generating}
                title="Generating Assets"
                message="Almost there! We're flexing our AI muscles to create engaging assets for you."
                isVisible={[
                  ContentStatesEnum.Uploading,
                  ContentStatesEnum.Processing,
                  ContentStatesEnum.Generating
                ].includes(contentState)}
                activeState={contentState}
                currentProcessingTime={currentProcessingTime}
              />
              <VideoImportProgressToast
                type={ContentStatesEnum.ContentReadyForPreview}
                title="Clips Ready"
                message="Your Clips are ready for preview. We'll wait for you to finish up."
                isVisible={ContentStatesEnum.ContentReadyForPreview === contentState}
                activeState={contentState}
              />
            </div>
          </div>
        </div>
      </div>
      <AlertDialog
        isOpen={error?.type ? true : false}
        heading={(error && ContentErrorTypeMap[error.type]) ?? 'Error'}
        message={error?.message}
        type="error"
        primaryActionTitle="Back to Home"
        handlePrimaryActionClick={handleErrorConfirmation}
      />
    </>
  );
}
