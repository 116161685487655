import React, { useCallback, useMemo, useState, useSyncExternalStore } from 'react';
import { PlayerRef, Player as RemotionPlayer } from '@remotion/player';
import { pick } from 'radash';
import { useCaptionContainerRef } from '../ClipPlayer/useCaptionContainerRef';
import StaticCanvas from './StaticCanvas';
import VideoCanvas from './VideoCanvas';
import CanvasPlayerCaptions from './CanvasPlayerCaptions';
import CanvasPlayerDraggableCaptions from './CanvasPlayerDraggableCaptions/CanvasPlayerDraggableCaptions';
import { CAPTION_HORIZONTAL_SPACE_FACTOR } from './constants';
import CanvasAudiogramCaptions from './CanvasAudiogramCaptions';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getFontSize } from '@/libs/sharedAPI/common';
import { COMPOSITION_DIMENSIONS, FPS_24, VIDEO_QUALITY } from '@/App/remotion/constants';
import { isEmptyObject } from '@/libs/utils';
import Main from '@/App/remotion/components/Main';
import { RemotionConfig } from '@/App/remotion/types';
import { changeCaptionsPosition } from '@/stores/clip';
import CaptionsMenu from '@/App/remotion/components/Captions/CaptionsMenu';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function CanvasPlayer({
  mainPlayer,
  secondaryPlayers,
  currentSpeaker,
  loading,
  inlineEditEnabled,
  remotionPlayerRef,
  isClipCustomizerMenuVisible,
  isPaused,
  pauseCurrentSection
}: {
  mainPlayer: React.MutableRefObject<HTMLVideoElement | null>;
  secondaryPlayers: React.RefObject<HTMLVideoElement>[];
  currentSpeaker: SpeakerWithDetails;
  loading: boolean;
  inlineEditEnabled: boolean;
  remotionPlayerRef: React.RefObject<PlayerRef>;
  isClipCustomizerMenuVisible: boolean;
  isPaused: boolean;
  pauseCurrentSection: () => void;
}) {
  const { clipId, clipData } = useClipsContext();
  const { adminAppStore } = useAppContext();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isCaptionsOverlayDisabled = featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false;
  const useClipPlayerV2 = featureFlags[FeatureFlagKeys.Use_CL_Clip_Player_V2] === true;
  const useSpeakerLabelsForRecordings = featureFlags[FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings] === true;
  const useRemotionCaptions = featureFlags[FeatureFlagKeys.Use_CL_Remotion_Captions] === true;
  const canResizeCaptions = featureFlags[FeatureFlagKeys.Use_CL_Resize_Captions] === true;

  const { clipSize, showCaption } = useMemo(() => {
    const clipSize = clipData.asset_metadata.size;
    const showCaption = !!clipData.asset_metadata.subtitle;
    return { clipSize, showCaption };
  }, [clipData.asset_metadata.size, clipData.asset_metadata.subtitle]);

  const remotionConfig = useMemo(
    () => clipData.asset_metadata.remotionConfig,
    [clipData.asset_metadata.remotionConfig]
  );

  const clipLayout = useMemo(() => clipData.asset_metadata.layout, [clipData.asset_metadata.layout]);
  const isAudiogram = useMemo(() => clipLayout === 'AUDIOGRAM', [clipLayout]);

  const showSpeakerLabels = useMemo(
    () => !!clipData.asset_metadata.magicLayout?.showSpeakerLabels && clipLayout !== 'DEFAULT',
    [clipData.asset_metadata.magicLayout?.showSpeakerLabels, clipLayout]
  );

  const visibleSecondaryPlayers = useMemo(
    () => secondaryPlayers.filter(player => player.current).sort((a, b) => a.current!.id.localeCompare(b.current!.id)),
    [secondaryPlayers]
  );

  // config used for video canvas
  const videoSizeConfig = useMemo(
    () => getSizeConfig(clipSize, clipId, clipLayout, 0.5),
    [clipSize, clipId, clipLayout]
  );

  const [captionContainerSizes, setCaptionContainerSizes] = useState({
    width: 0,
    height: 0
  });
  const { captionContainerRef } = useCaptionContainerRef(setCaptionContainerSizes, clipData.asset_metadata);

  const [captionFontSize, captionHorizontalSpace, captionsHeight] = useMemo(() => {
    const scale = clipData.asset_metadata.caption_styles?.scale || 1;
    const fontSize = getFontSize({ playerHeight: captionContainerSizes.height, scale, clipSize });

    return [
      fontSize,
      fontSize * CAPTION_HORIZONTAL_SPACE_FACTOR,
      ((videoSizeConfig.getVideoBottomPadding() - videoSizeConfig.getVideoSurroundPadding()) /
        videoSizeConfig.getHeight()) *
        captionContainerSizes.height
    ];
  }, [videoSizeConfig, captionContainerSizes, clipData.asset_metadata.caption_styles?.scale]);

  const backgroundStyle = useMemo(() => {
    if (!clipData.asset_metadata.magicLayout?.backgroundImage)
      return {
        backgroundColor: clipData.asset_metadata.magicLayout?.backgroundColor
      };
    return { backgroundImage: `url(${clipData.asset_metadata.magicLayout?.backgroundImage})` };
  }, [clipData.asset_metadata.magicLayout?.backgroundImage, clipData.asset_metadata.magicLayout?.backgroundColor]);

  const { compositionHeight, compositionWidth } = useMemo(() => {
    const videoQuality = VIDEO_QUALITY.FULL_HD;
    const compositionHeight = COMPOSITION_DIMENSIONS[clipData.asset_metadata.size][videoQuality].HEIGHT;
    const compositionWidth = COMPOSITION_DIMENSIONS[clipData.asset_metadata.size][videoQuality].WIDTH;

    return {
      compositionHeight,
      compositionWidth
    };
  }, [clipData.asset_metadata.size]);

  const durationInFrames = useMemo(() => {
    if (clipData.asset_metadata.duration) {
      return Math.ceil(clipData.asset_metadata.duration * FPS_24);
    }

    return 0;
  }, [clipData.asset_metadata.duration]);

  const isRemotionConfigSet = useMemo(
    () => remotionConfig && !isEmptyObject(remotionConfig) && durationInFrames > 0,
    [remotionConfig, durationInFrames]
  );

  const isRemotionEnabledOnSharedPage = useMemo(() => {
    const isSharedPage = window.location.pathname.includes('/shared/');
    const isRemotionEnabledOnSharedPage =
      adminAppStore.getters?.['unauthedFeatureFlags/getUnauthedFeatureFlags']?.['Use_CL_Remotion'];

    return isSharedPage && isRemotionEnabledOnSharedPage;
  }, [adminAppStore.getters]);

  const isUsingRemotionForSpeakerLabels = useMemo(() => {
    if (isAudiogram) return false;

    if (!showSpeakerLabels) return false;

    const isGoldcastRecording = clipData?.content?.media_source_type === 'RECORDING';
    const isUpload = clipData?.content?.media_source_type === 'UPLOAD';

    const shouldEnableRemotionForSpeakerLabels =
      (isUpload && useClipPlayerV2) || (isGoldcastRecording && useSpeakerLabelsForRecordings);

    if (!shouldEnableRemotionForSpeakerLabels && !isRemotionEnabledOnSharedPage) return false;

    return true;
  }, [
    isAudiogram,
    showSpeakerLabels,
    clipData?.content?.media_source_type,
    useClipPlayerV2,
    useSpeakerLabelsForRecordings,
    isRemotionEnabledOnSharedPage
  ]);

  const isUsingRemotionForCaptions = useMemo(() => {
    if (isAudiogram) return false;

    if (!showCaption) return false;

    if (!useRemotionCaptions && !isRemotionEnabledOnSharedPage) return false;

    return true;
  }, [isAudiogram, showCaption, useRemotionCaptions, isRemotionEnabledOnSharedPage]);

  const onOutlineRelease = useCallback(positions => {
    changeCaptionsPosition({
      clipId,
      caption_positions: {
        x: positions.left as any,
        y: positions.top as any,
        width: positions.width as any,
        height: positions.height as any
      },
      skipUpdate: false
    });
  }, []);

  // TODO: @AshwinBhatkal Change naming to generic naming later
  const onRemotionPlayerPointerUp = useCallback(
    e => {
      if ((e.target as HTMLDivElement).id.includes('selection-outline') && !isPaused) {
        pauseCurrentSection();
      }
    },
    [pauseCurrentSection, isPaused]
  );

  const remotionInitialFrame =
    mainPlayer.current?.currentTime &&
    Math.floor((mainPlayer.current?.currentTime - clipData.asset_metadata.start) * FPS_24);

  const isRemotionInitialFrameValid =
    remotionInitialFrame && remotionInitialFrame >= 0 && remotionInitialFrame < durationInFrames;

  return (
    <div
      className="absolute top-0 h-full w-full overflow-hidden rounded-md bg-cover bg-center"
      style={{
        ...backgroundStyle
      }}
      id="player-container"
    >
      <div className="relative grid h-full items-start " data-testid="composite-player" ref={captionContainerRef}>
        {/* Canvas for rendering the video elements on screen */}
        <VideoCanvas
          mainPlayer={mainPlayer}
          secondaryPlayers={visibleSecondaryPlayers}
          currentSpeaker={currentSpeaker}
          loading={loading}
          clipLayout={clipLayout}
        />

        {/* canvas for rendering elements that are more or less static throughout, eg: speaker labels */}
        <StaticCanvas
          mainPlayer={mainPlayer}
          secondaryPlayers={visibleSecondaryPlayers}
          currentSpeaker={currentSpeaker}
          // TODO: @AshwinBhatkal to update this key when fully integrated with remotion
          key={
            isRemotionConfigSet && (isUsingRemotionForSpeakerLabels || isUsingRemotionForCaptions)
              ? null
              : currentSpeaker?.id +
                currentSpeaker?.first_name +
                currentSpeaker?.last_name +
                currentSpeaker?.company +
                currentSpeaker?.title
          }
          captionContainerSizes={captionContainerSizes}
          clipLayout={clipLayout}
          isUsingRemotionForSpeakerLabels={isUsingRemotionForSpeakerLabels}
        />
        {(isUsingRemotionForSpeakerLabels || isUsingRemotionForCaptions) && isRemotionConfigSet && (
          <div onPointerUp={onRemotionPlayerPointerUp}>
            <RemotionPlayer
              component={Main}
              durationInFrames={durationInFrames}
              compositionWidth={compositionWidth}
              compositionHeight={compositionHeight}
              fps={FPS_24}
              ref={remotionPlayerRef}
              {...(isRemotionInitialFrameValid ? { initialFrame: remotionInitialFrame } : {})}
              clickToPlay={false}
              controls={false}
              spaceKeyToPlayOrPause={false}
              inputProps={{
                ...(pick(remotionConfig as RemotionConfig, ['version', 'tracks', 'elements', 'font']) as Pick<
                  RemotionConfig,
                  'version' | 'tracks' | 'elements' | 'font'
                >),
                drift: 0,
                onOutlineRelease,
                canResizeCaptions,
                canEditCaptions: isClipCustomizerMenuVisible,
                CaptionsMenu
              }}
              style={{
                height: captionContainerSizes.height,
                width: captionContainerSizes.width
              }}
            />
          </div>
        )}
        {showCaption && !isUsingRemotionForCaptions && !isCaptionsOverlayDisabled && !!captionsHeight && (
          <CanvasPlayerDraggableCaptions
            clipMetadata={clipData.asset_metadata}
            clipId={clipId}
            captionFontSize={captionFontSize}
            isDisabled={!inlineEditEnabled || !!clipData.locked}
          />
        )}

        {showCaption &&
          !isUsingRemotionForCaptions &&
          captionHorizontalSpace >= 0 &&
          !isAudiogram &&
          isCaptionsOverlayDisabled && (
            <div className="absolute bottom-0 w-full">
              <div
                className="mx-auto flex h-full w-full max-w-[90%] items-center justify-center"
                style={{
                  fontSize: `${captionFontSize}px`,
                  height: `${captionsHeight}px`
                }}
              >
                <div
                  className="flex flex-wrap justify-center whitespace-normal text-center"
                  style={{ lineHeight: `${(5 * captionFontSize) / 4}px` }}
                >
                  <CanvasPlayerCaptions captionHorizontalSpace={captionHorizontalSpace} />
                </div>
              </div>
            </div>
          )}

        {isAudiogram && (
          <CanvasAudiogramCaptions
            videoSizeConfig={videoSizeConfig}
            captionContainerSizes={captionContainerSizes}
            captionFontSize={captionFontSize}
          />
        )}
      </div>
    </div>
  );
}
