import { IconCalendarEvent, IconFileTextAi, IconVideo } from '@tabler/icons-react';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssetList, assetsClipCloneCreate, assetsClipDestroy, assetsTextDestroy } from '@goldcast/api/content';
import { useMutation } from 'react-query';
import { getTitleFromMarkdownString, parseTextTitle } from '../utils';
import AssetTableClipThumbnail from './AssetTableClipThumbnail/AssetTableClipThumbnail';
import TextPreview from './TextPreview';
import RowMenuItem from '../uiComponents/RowMenuItem';
import { MenuItemsLabelType, menuItems, updateAssetTitle } from './AssetsTableUtils';
import ContentDialog from '../uiComponents/ContentDialog/ContentDialog';
import RenameTitleModal from './RenameTitleModal';
import Loader from '@/components/atoms/Loader';
import useDialog from '@/components/organisms/useDialog';
import { showErrorToast } from '@/libs/toast/toast';
import useDownloadAsset from '@/hooks/useDownloadAsset';
import { POST_TO_NAV_MAP } from '@/Pages/PostsListPage/constants';
import useAnalytics from '@/hooks/useAnalytics';
import { handleClipLockedError } from '@/Pages/Clip/utils';
import useClipNavigate from '@/hooks/useClipNavigate';
import { useAppContext } from '@/context/AppContext/AppContext';
import ClipUsageDialog from '@/components/molecules/ClipPublish/ClipUsageDialog';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useAssetUsage from '@/hooks/useAssetUsage';

export default function AssetsTableRow({
  asset,
  refetch,
  isLastPageItem
}: {
  asset: AssetList;
  refetch: () => void;
  isLastPageItem: boolean;
}) {
  const { content, type, title, asset_metadata } = asset;
  const { isOpen, openDialog, closeDialog } = useDialog();
  const { logger } = useAppContext();
  const { adminAppStore } = useAppContext();

  const { usageData: clipAssetUsage, checkAssetUsage } = useAssetUsage();

  const navigate = useNavigate();

  const { trackContentLabUsage, trackContentDuplicated } = useAnalytics();

  const isVideoLibraryClipPublishEnabled = useMemo(
    () => adminAppStore.getters?.['org/getCurrentOrg']?.showVideoLibraryClipPublish || false,
    [adminAppStore]
  );

  const {
    isOpen: isClipUsageDialogOpen,
    openDialog: openClipUsageDialog,
    closeDialog: closeClipUsageDialog
  } = useDialog();

  const {
    isOpen: isConfirmDeleteDialogOpen,
    openDialog: openConfirmDeleteDialog,
    closeDialog: closeConfirmDeleteDialog
  } = useDialog();

  const navigateToAsset = useCallback(() => {
    if (type === 'CLIP') {
      !!asset.saved_search
        ? navigate(`/search/${asset.saved_search.id}/clips?activeClipId=${asset.id}`)
        : navigate(`${content!.project.id}/${content!.id}/clips?activeClipId=${asset.id}`);
    } else {
      !!asset.saved_search
        ? navigate(`/search/${asset.saved_search.id}/${POST_TO_NAV_MAP[asset_metadata.type]}?activePostId=${asset.id}`)
        : navigate(
            `${content!.project.id}/${content!.id}/${POST_TO_NAV_MAP[asset_metadata.type]}?activePostId=${asset.id}`
          );
    }

    trackContentLabUsage({
      asset: asset
    });
  }, [asset.id, asset.saved_search, asset_metadata.type, content?.id, content?.project.id, type, trackContentLabUsage]);

  const assetTitle =
    type === 'CLIP' ? title : parseTextTitle(title) || getTitleFromMarkdownString(asset_metadata?.output);

  const {
    mutate: updateClipTitle,
    isLoading: updatingClipTitle,
    isSuccess: isUpdateClipTitleSuccess
  } = useMutation(updateAssetTitle, {
    onError: () => showErrorToast("Oops! Couldn't rename the asset. Please try again.")
  });

  const onSaveNewTitle = (newTitle: string) => {
    if (assetTitle !== newTitle) {
      updateClipTitle({ ...asset, title: newTitle });
    }
    closeDialog();
  };

  const { mutate: duplicateClipAsset, isLoading: duplicatingClipAsset } = useMutation(assetsClipCloneCreate, {
    onSuccess: duplicatedAsset => {
      trackContentDuplicated({
        sourceAssetId: asset.id,
        assetId: duplicatedAsset.id,
        asset: duplicatedAsset,
        assetType: 'CLIP',
        state: 'Duplicated'
      });

      !!asset.saved_search
        ? navigate(`/search/${asset.saved_search.id}/clips?activeClipId=${duplicatedAsset.id}`)
        : navigate(`${asset.content?.project.id}/${asset?.content?.id}/clips?activeClipId=${duplicatedAsset.id}`);
    },
    onError: (err: any) => {
      trackContentDuplicated({
        sourceAssetId: asset.id,
        assetType: 'CLIP',
        state: 'Failed',
        failureReason: err.message
      });

      showErrorToast("Oops! Couldn't duplicate clip. Please try again.");
      logger.error('Failed to duplicate clip', err);
    }
  });

  const {
    mutate: deleteTextAsset,
    isLoading: deletingTextAsset,
    isSuccess: isDeleteTextSuccess
  } = useMutation(assetsTextDestroy);
  const {
    mutate: deleteClipAsset,
    isLoading: deletingClipAsset,
    isSuccess: isDeleteClipSuccess
  } = useMutation(assetsClipDestroy, {
    onError: (err: any) => {
      const parseError = JSON.parse(err);
      const message = parseError?.error ?? 'Failed to delete clip';
      showErrorToast(message);
    }
  });

  const deleteAsset = async () => {
    if (type === 'TEXT') {
      deleteTextAsset({
        id: asset.id
      });
    } else if (type === 'CLIP') {
      // check clip usage before deletion
      if (isVideoLibraryClipPublishEnabled && asset?.asset_metadata?.video_library_id) {
        const usageCount = await checkAssetUsage(asset?.asset_metadata?.video_library_id);
        if (usageCount > 0) {
          openClipUsageDialog();
          return;
        }
      }
      // show confirm dialog for deletion
      if (isVideoLibraryClipPublishEnabled) {
        openConfirmDeleteDialog();
        return;
      }

      deleteClipAsset({
        id: asset.id
      });
    }
  };

  const onDeleteClip = useCallback(() => {
    deleteClipAsset({
      id: asset.id
    });
    closeConfirmDeleteDialog();
  }, [asset.id, deleteClipAsset, closeConfirmDeleteDialog]);

  useEffect(() => {
    if (isDeleteTextSuccess || isDeleteClipSuccess || isUpdateClipTitleSuccess) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteTextSuccess, isDeleteClipSuccess, isUpdateClipTitleSuccess]);

  const { navigateToClip } = useClipNavigate();

  const { onDownloadClip, onDownloadTextAsset } = useDownloadAsset(
    {
      type: type as 'CLIP' | 'TEXT',
      clip: asset,
      textAsset: asset as any
    },
    navigateToClip
  );

  const handleEdit = useCallback(() => {
    if (type === 'CLIP') {
      if (asset.locked) {
        handleClipLockedError(asset as any);
        return;
      }

      navigateToClip(asset, { downloadIntent: false });
    }

    trackContentLabUsage({
      asset: asset
    });
  }, []);

  const onGenerateSocialPost = () => {
    !!asset.saved_search
      ? navigate(`/search/${asset.saved_search.id}/social`, { state: { clipId: asset_metadata.id } })
      : navigate(`/${content!.project.id}/${content!.id}/social`, { state: { clipId: asset_metadata.id } });
  };

  const onDuplicateClip = useCallback(() => {
    duplicateClipAsset({
      id: asset.id
    });
  }, [asset.id]);

  const onAction = (actionType: MenuItemsLabelType) => {
    switch (actionType) {
      case 'Edit':
        handleEdit();
        break;
      case 'Rename':
        openDialog();
        break;
      case 'Download':
        type === 'CLIP' ? onDownloadClip() : onDownloadTextAsset();
        break;
      case 'Delete':
        deleteAsset();
        break;
      case 'Generate Social Post':
        onGenerateSocialPost();
        break;
      case 'Duplicate':
        onDuplicateClip();
        break;
    }
  };

  const homepageMenuItems = useMemo(() => {
    return menuItems[type || 'CLIP'].filter(item => !item.clipListOnly);
  }, [type]);

  const pendingAction = deletingTextAsset || deletingClipAsset || updatingClipTitle || duplicatingClipAsset;

  const recordingTitle = useMemo(() => {
    return asset.saved_search ? asset.saved_search.title : asset.content!.title;
  }, [asset.saved_search, asset.content]);

  return (
    <>
      <div
        onClick={navigateToAsset}
        className="group flex w-full cursor-pointer items-center justify-between space-x-4 p-2 hover:bg-slate-100"
      >
        <div className="w-[7.25rem] shrink-0">
          {type === 'CLIP' ? (
            <AssetTableClipThumbnail asset={asset} />
          ) : (
            <div className="aspect-[4/3] overflow-hidden rounded-lg border !border-transparent">
              <TextPreview type={asset_metadata.type} output={asset_metadata.output} />
            </div>
          )}
        </div>
        <div className="w-2/12 grow truncate">
          <span className="truncate leading-tight">
            <span className="text-sm" title={asset_metadata.description || assetTitle}>
              {assetTitle}
            </span>
            {recordingTitle && (
              <div className="-mt-0.5 flex items-center space-x-1 text-xs text-slate-500">
                <IconCalendarEvent size={13} stroke={1.5} className="" />
                <span className="truncate">{recordingTitle}</span>
              </div>
            )}
          </span>
        </div>
        <div className="w-24">
          <div className="flex items-center space-x-2.5">
            <div className="inline-flex text-xs">
              <div className="flex items-center justify-center space-x-1 rounded-full bg-slate-200 px-2 py-1 pr-2.5 text-slate-600">
                {type === 'CLIP' ? <IconVideo size={15} stroke={1.7} /> : <IconFileTextAi size={15} stroke={1.7} />}
                <div>{type === 'CLIP' ? 'Clip' : 'Text'}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/12 text-sm text-slate-600">{moment(asset.created_at).format('MMM D YYYY')}</div>
        {pendingAction ? (
          <Loader size="small" />
        ) : (
          <RowMenuItem
            content={asset.content}
            menuItems={homepageMenuItems}
            onAction={onAction}
            positionVariant={isLastPageItem ? 'bottom-right' : 'top-right'}
            forceShow={false}
          />
        )}
      </div>
      {type === 'CLIP' && (
        <ContentDialog isOpen={isOpen} setIsOpen={closeDialog} title="Rename" size="xsmall">
          <RenameTitleModal existingTitle={assetTitle} onSubmit={onSaveNewTitle} onClose={closeDialog} />
        </ContentDialog>
      )}
      <ContentDialog
        isOpen={isClipUsageDialogOpen}
        hideCloseIcon={true}
        size="medium"
        setIsOpen={closeClipUsageDialog}
        disableBackdropClose={false}
      >
        <ClipUsageDialog onClose={closeClipUsageDialog} clipAssetUsage={clipAssetUsage} type={'DELETE'} />
      </ContentDialog>
      <ConfirmationDialog
        isOpen={isConfirmDeleteDialogOpen}
        onClose={closeConfirmDeleteDialog}
        onConfirm={onDeleteClip}
        title="Delete Clip"
        content="Are you sure you want to delete this clip? This will permanently delete the clip and you will not be able to revert this change."
        confirmLabel="Yes"
        cancelLabel="Cancel"
      />
    </>
  );
}
