import { VideoSegment } from '../../../types/Segment/VideoSegment';
import { SegmentType } from '../../../types/Segment/Type';
import { ElementType } from '../../../types/Element/Type';
import { DEFAULT_COLOR } from '@/libs/core';
import { Clip } from '@/domains/asset';

export function createOutroVideoSegment(clip: Clip, outroDuration: number, offset: number): VideoSegment {
  return {
    id: 'outro',
    name: 'Outro',
    type: SegmentType.VIDEO,
    fromFrame: offset,
    toFrame: offset + outroDuration,
    details: {
      background: {
        type: 'COLOR',
        value: DEFAULT_COLOR
      }
    },
    scenes: [],
    video: {
      id: 'outroVideo',
      name: 'Outro Video',
      type: ElementType.VIDEO,
      fromFrame: 0,
      toFrame: outroDuration,
      details: {
        src: clip.asset_metadata.outro || '',
        altText: 'Outro Video',
        volume: [
          {
            value: 100,
            fromFrame: 0,
            toFrame: outroDuration
          }
        ]
      },
      trim: [],
      style: {},
      containerStyle: {}
    }
  };
}
