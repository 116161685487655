import { Activity, listActivity } from '@goldcast/api/backend';
import createStore from '@/stores/store';

type AlignedRecordings = Pick<Activity, 'id' | 'entity' | 'transcript_mapping' | 'stage_time_spans' | 'user'> & {
  mp4_url: string;
  preview_url?: string;
};

export const alignedRecordings = createStore<AlignedRecordings[]>([]);
export const alignedRecordingIdToSpeakerKeyMap = createStore<Record<string, string>>({});

export const getAlignedRecordings = async ({ broadcast_id }: { broadcast_id: string }) => {
  const { results } = await listActivity({
    queryParams: {
      broadcast_id,
      entity: ['aligned_screenshare_feed', 'aligned_user_feed'] as any
    },
    queryParamsStringifyOptions: {
      arrayFormat: 'comma'
    }
  });

  if (!results) {
    alignedRecordings.set(() => []);
    return;
  }

  let screenshareId = 1;
  const recordingIdToSpeakerKeyMap: Record<string, string> = {};

  const processedRecordings = results
    // Sort screenshare recordings to the top
    .sort((a, b) => {
      if (a.entity === 'aligned_screenshare_feed') return -1;
      if (b.entity === 'aligned_screenshare_feed') return 1;
      return 0;
    })
    .map(recording => {
      // Build speaker mapping during the same iteration
      recordingIdToSpeakerKeyMap[recording.id] =
        recording.entity === 'aligned_user_feed'
          ? recording.transcript_mapping?.speaker_labels[0]
          : 'Screenshare ' + screenshareId++;

      // Return transformed recording
      return {
        id: recording.id,
        entity: recording.entity,
        transcript_mapping: recording.transcript_mapping,
        stage_time_spans: recording.stage_time_spans,
        user: recording.user,
        mp4_url: (recording as any).mp4_url,
        preview_url: (recording as any).preview_url
      };
    });

  alignedRecordingIdToSpeakerKeyMap.set(() => recordingIdToSpeakerKeyMap);
  alignedRecordings.set(() => processedRecordings);
};
