import { memo } from 'react';
import moment from 'moment';
import { IconArrowUpRight, IconLoader2 } from '@tabler/icons-react';
import { VideoAssetUsage } from '@goldcast/api/backend';
import Button from '@/components/atoms/Button/Button';
import usePublishAsset from '@/hooks/usePublishAsset';

const NAVIGATION_ROUTES = {
  EVENT: {
    Recording: '/recording-event',
    Conference: '/events',
    Webinar: '/webinar'
  },
  HUB: '/pages',
  STUDIO_EVENT_TEMPLATE: '/templates'
} as const;

const VIDEO_USAGE_MESSAGE = {
  PUBLISH: {
    title: 'Video in use',
    message:
      'You’re about to publish and update this video. This will replace the current version wherever it is being used.'
  },
  DELETE: {
    title: 'Video in use',
    message:
      'This video is currently in use in the following destinations and cannot be removed. Please remove the video from these destinations first to proceed.'
  }
};

function ClipUsageDialog({
  onClose,
  type = 'PUBLISH',
  clipAssetUsage
}: {
  onClose: () => void;
  type: 'PUBLISH' | 'DELETE';
  clipAssetUsage?: VideoAssetUsage[];
}) {
  const { publishAsset, isPublishing } = usePublishAsset();

  const handlePublish = async () => {
    await publishAsset();
    onClose();
  };

  const onUsageClick = (clipUsage: VideoAssetUsage) => {
    const basePath =
      clipUsage.entity_type === 'EVENT'
        ? NAVIGATION_ROUTES.EVENT[clipUsage.entity_subtype as keyof typeof NAVIGATION_ROUTES.EVENT]
        : NAVIGATION_ROUTES[clipUsage.entity_type as keyof typeof NAVIGATION_ROUTES];

    if (!basePath) return;

    const url = `${basePath}/${clipUsage.entity_id}`;
    window.open(url, '_blank');
  };

  return (
    <div className="relative flex flex-col overflow-y-auto overflow-x-hidden">
      <div className="grow px-5 pb-5">
        <h1 className="font-medium">{VIDEO_USAGE_MESSAGE[type].title}</h1>
        <div className="mt-1.5 w-full text-sm leading-tight text-slate-600">
          {VIDEO_USAGE_MESSAGE[type].message}
          {clipAssetUsage && (
            <div className="mt-5">
              {clipAssetUsage.map(clipUsage => (
                <div key={clipUsage?.entity_id} className="flex w-full items-center justify-between py-3">
                  <div className="">
                    <div className="text-sm font-medium text-black">{clipUsage.entity_title}</div>
                    <div className="mt-0.5 text-xs text-slate-600">{clipUsage.entity_subtype}</div>
                  </div>
                  <div className="flex flex-col items-end">
                    <div
                      className="flex cursor-pointer items-center space-x-1 text-sm hover:underline"
                      onClick={() => onUsageClick(clipUsage)}
                    >
                      <span className="text-xs font-medium text-black">Visit</span>
                      <IconArrowUpRight className="h-4 w-4 text-black" />
                    </div>
                    {clipUsage?.entity_created_date && (
                      <div className="tw-mt-1 tw-text-xs tw-text-slate-600">
                        {clipUsage?.entity_type === 'EVENT' ? 'Event Date: ' : 'Created: '}
                        {moment(clipUsage?.entity_created_date).format('MMM D, YYYY')}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {type === 'PUBLISH' ? (
        <div className="flex items-center justify-end gap-3 border-t border-t-slate-200 px-5 pb-3 pt-2.5">
          <Button variation="outline" trackingId="cancel-publishing" onClick={onClose}>
            Cancel
          </Button>
          <Button variation="filled" trackingId="publish-content" disabled={isPublishing} onClick={handlePublish}>
            Publish and update
            {isPublishing && <IconLoader2 className="ml-2 animate-spin" size={16} />}
          </Button>
        </div>
      ) : (
        <div className="flex items-center justify-end gap-3 border-t border-t-slate-200 px-5 pb-3 pt-2.5">
          <Button variation="filled" trackingId="close-dialog" onClick={onClose}>
            Close
          </Button>
        </div>
      )}
    </div>
  );
}

export default memo(ClipUsageDialog);
