import { useCallback, useState } from 'react';
import { listVideoAssetUsage, VideoAssetUsage } from '@goldcast/api/backend';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function useAssetUsage() {
  const { logger } = useAppContext();

  const [usageData, setUsageData] = useState<VideoAssetUsage[]>([]);

  const checkAssetUsage = useCallback(async (videoLibraryId: string) => {
    try {
      const response = await listVideoAssetUsage({ id: videoLibraryId });

      if (response && response.length > 0) {
        setUsageData(response);
      }
      return response.length;
    } catch (error) {
      logger.error('Error fetching clip usage data', error);
    }
    return 0;
  }, []);

  return { usageData, checkAssetUsage };
}
