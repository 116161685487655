import { Sequence } from 'remotion';
import { SegmentsTrack } from '../../MainPlayer/types/Track/SegmentsTrack';
import { SegmentType } from '../../MainPlayer/types/Segment/Type';
import CompositeSegmentComponent from '../SegmentComponent/CompositeSegmentComponent';
import VideoElementComponent from '../VideoElementComponent/VideoElementComponent';

const SegmentsTrackComponent: React.FC<{
  segments: SegmentsTrack['segments'];
}> = ({ segments }) => {
  return segments.map(segment => {
    if (!segment) return null;

    const durationInFrames = segment.toFrame - segment.fromFrame;
    if (durationInFrames <= 0) return null;

    switch (segment.type) {
      case SegmentType.COMPOSITE:
        return (
          <Sequence from={segment.fromFrame} durationInFrames={durationInFrames} key={segment.id}>
            <CompositeSegmentComponent segment={segment} />
          </Sequence>
        );

      case SegmentType.VIDEO:
        return (
          <Sequence from={segment.fromFrame} durationInFrames={durationInFrames} key={segment.id}>
            <VideoElementComponent element={segment.video} />
          </Sequence>
        );
      default:
        return null;
    }
  });
};

export default SegmentsTrackComponent;
