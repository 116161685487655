import { AbsoluteFill, Sequence } from 'remotion';
import ElementsTrackComponent from './ElementsTrackComponent';
import SegmentsTrackComponent from './SegmentsTrackComponent';
import { CompositionConfig } from '../../MainPlayer/types/CompositionConfig';
import CaptionsPages from '@/App/remotion/components/Captions/CaptionsPages';
import { Track } from '@/App/remotion/MainPlayer/types/Track/Track';
import { TrackType } from '@/App/remotion/MainPlayer/types/Track/Type';

const TrackComponent: React.FC<{
  track: Track;
  onOutlineRelease: CompositionConfig['onOutlineRelease'];
  CaptionsMenu: CompositionConfig['CaptionsMenu'];
}> = ({ track, onOutlineRelease, CaptionsMenu }) => {
  const { type } = track;

  if (type === TrackType.ELEMENTS) {
    return <ElementsTrackComponent elements={track.elements} source="TRACK" />;
  }

  if (type === TrackType.SEGMENTS) {
    return <SegmentsTrackComponent segments={track.segments} />;
  }

  if (type === TrackType.CAPTIONS) {
    return (
      <Sequence from={track.fromFrame} durationInFrames={track.toFrame - track.fromFrame}>
        <CaptionsPages
          pages={track.pages}
          onOutlineRelease={onOutlineRelease}
          canResizeCaptions={true}
          canEditCaptions={true}
          CaptionsMenu={CaptionsMenu}
          drift={0}
          font={track.font}
        />
      </Sequence>
    );
  }

  return <AbsoluteFill>Please contact support!</AbsoluteFill>;
};

export default TrackComponent;
