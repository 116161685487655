import AssetsTable from './AssetsTable/AssetsTable';
import RecentEvents from './RecentEvents/RecentEvents';

export default function Sessions(): JSX.Element {
  return (
    <div className="flex grow flex-col overflow-hidden bg-slate-50">
      <RecentEvents />
      <AssetsTable />
    </div>
  );
}
