import React from 'react';
import { IconLoader2, IconSparkles } from '@tabler/icons-react';
import ContentUpload from './VideoImportPageSteps/ContentUpload';
import BrandingSelect from './VideoImportPageSteps/BrandingSelect/BrandingSelect';
import TemplateSelect from './VideoImportPageSteps/TemplateSelect/TemplateSelect';
import Done from './VideoImportPageSteps/Done';

export enum ContentStatesEnum {
  Uploading,
  Processing,
  Generating,
  Cancelled,
  GeneratingCompleted,
  ContentReadyForPreview
}

export const IconMap = {
  [ContentStatesEnum.Processing]: IconLoader2,
  [ContentStatesEnum.Generating]: IconSparkles
};

export const ASSETS_FILE_PATH = 'content-lab/filestack/custom_assets';

export enum ContentError {
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  GENERATING_FAILED = 'GENERATING_FAILED'
}

export const ContentErrorTypeMap = {
  [ContentError.UPLOAD_FAILED]: 'Video Uploading Failed',
  [ContentError.PROCESSING_FAILED]: 'Video Processing Failed',
  [ContentError.GENERATING_FAILED]: 'Video Generating failed'
};

export const NavigationLabelMap = {
  clips: 'clips',
  transcript: 'transcript',
  social: 'social posts',
  email: 'emails',
  blog: 'blogs',
  takeaways: 'takeaways'
};

export const HeadingMap = {
  clips: 'Clips',
  social: 'Social',
  blog: 'Blog',
  email: 'Email',
  takeaways: 'Takeaways',
  custom: 'Custom',
  transcript: 'Transcript'
};

type VideoImportStep = {
  id: 'content-upload' | 'branding-select' | 'template-select' | 'done';
  index: number;
  component: React.FC;
  title: string;
  description: string;
};

export const VIDEO_IMPORT_STEPS: VideoImportStep[] = [
  {
    id: 'content-upload',
    index: 0,
    component: ContentUpload,
    title: 'Import Your Video',
    description:
      'Get weeks worth of content out of your webinars, product demos, video podcasts, or any other long-form video content.'
  },
  {
    id: 'branding-select',
    index: 1,
    component: BrandingSelect,
    title: 'Branding',
    description: 'Add a splash of branding to your clips. You can always edit your brand settings in Brand Kit later.'
  },
  {
    id: 'template-select',
    index: 2,
    component: TemplateSelect,
    title: 'Templates',
    description: 'Select templates you want to use for your clips. You can always edit these later.'
  },
  {
    id: 'done',
    index: 3,
    component: Done,
    title: 'That was easy!',
    description: ''
  }
];
