import { isEqual } from 'radash';
import { currentClip, shouldUseNewPlayerConfig, updateClipInList, updateClipWithId } from '../clip';
import createStore from '../store';
import { defaultSharedAPIStore } from './constants';
import { speakersAnalysisStore } from '../speakersAnalysis/speakersAnalysis';
import featureFlagStore from '../featureFlagStore';
import { Clip, ClipMetadata } from '@/domains/asset';
import { getDefaultLayoutCanvasData } from '@/libs/sharedAPI/default';
import { getActiveSpeakerLayoutCanvasData } from '@/libs/sharedAPI/activeSpeaker';
import { updateClip } from '@/libs/clipContentUtil';
import { TranscriptContextData } from '@/context/TranscriptContext/TranscriptContextTypes';
import { SpeakerSegmentContextType } from '@/context/SpeakerSegmentContext/SpeakerSegmentContextTypes';
import { getSpeakerGridLayoutCanvasData } from '@/libs/sharedAPI/speakerGrid';
import { getAudiogramLayoutCanvasData } from '@/libs/sharedAPI/audiogram';
import { isEmptyObject } from '@/libs/utils';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { getCaptions } from '@/libs/sharedAPI/common';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';

export interface SharedAPIStore {
  transcriptStore: TranscriptContextData;
  speakerSegmentStore: SpeakerSegmentContextType;
  mainPlayerRef: HTMLVideoElement | null;
  introPlayer: HTMLVideoElement | null;
  outroPlayer: HTMLVideoElement | null;
  speakerMap: Record<
    string,
    {
      videoRef: HTMLVideoElement;
    }
  >;
  speakerImageMap: Record<
    string,
    {
      imageRef: HTMLImageElement;
    }
  >;
}

export const sharedAPI = createStore<Record<string, SharedAPIStore>>({});

export function initSharedAPI(clipId: string) {
  sharedAPI.update(data => ({ ...data, [clipId]: defaultSharedAPIStore }));
}

export function updateSharedAPIWithId(clipId: string, data: Partial<SharedAPIStore>) {
  sharedAPI.update(store => ({ ...store, [clipId]: { ...store[clipId], ...data } }));
}

export function updateSpeakerImageMap(clipId: string, speakerKey: string, imageRef: HTMLImageElement) {
  sharedAPI.update(data => ({
    ...data,
    [clipId]: {
      ...data[clipId],
      speakerImageMap: {
        ...data[clipId].speakerImageMap,
        [speakerKey]: {
          ...data[clipId].speakerImageMap[speakerKey],
          imageRef
        }
      }
    }
  }));
}

export function updateSpeakerVideoMap(clipId: string, speakerKey: string, videoRef: HTMLVideoElement) {
  sharedAPI.update(data => ({
    ...data,
    [clipId]: {
      ...data[clipId],
      speakerMap: {
        ...data[clipId].speakerMap,
        [speakerKey]: {
          ...data[clipId].speakerMap[speakerKey],
          videoRef
        }
      }
    }
  }));
}

export function updateClipConfig(clipId, clip?: Clip) {
  if (!clip) {
    clip = currentClip.getSnapshot()[clipId];
  }
  let finalClip = { ...clip };

  const clipLayout = finalClip.asset_metadata.layout;

  const isSpeakerLayout = clipLayout === 'SPEAKER' || clipLayout === 'GRID';
  const speakersAnalysis = speakersAnalysisStore.getSnapshot()[clipId];

  const isAnalysisAccurate = isSpeakerLayout ? !!speakersAnalysis.accurate : undefined;
  const isAutoActiveMappingOverridden = isSpeakerLayout
    ? !isEqual(speakersAnalysis.speaker_mapping, speakersAnalysis.active_speaker_mapping)
    : undefined;
  const isAutoActiveSpeakerIdentified = isSpeakerLayout
    ? !!speakersAnalysis.active_speaker_mapping && !isEmptyObject(speakersAnalysis.active_speaker_mapping)
    : undefined;

  const useRemotionCaptions =
    featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Remotion_Captions] && clipLayout !== 'AUDIOGRAM';

  // START - CR-4289 - captions-undo-fix
  // This code is same as in clip.ts. That is required for history handling. This is required for clip handling
  // Temp code. Will be cleaned after removal of shared api
  const showCaptions = !!finalClip.asset_metadata.subtitle;
  if (
    finalClip.asset_metadata.caption_positions === undefined &&
    finalClip.asset_metadata.caption_styles?.placement === undefined &&
    showCaptions &&
    useRemotionCaptions
  ) {
    finalClip = {
      ...finalClip,
      asset_metadata: {
        ...finalClip.asset_metadata,
        caption_positions: {
          x: '2.5%',
          y: '82%',
          width: '95%',
          height: '18%'
        } as any
      }
    };
  }
  // END - CR-4289 - captions-undo-fix

  if (!shouldUseNewPlayerConfig(finalClip)) {
    try {
      let canvasJSON: ClipMetadata['config'] = null;
      const sharedAPIStoreForClip = sharedAPI.getSnapshot()[clipId];
      const commonParams = { clip: finalClip, sharedAPIStoreForClip };

      // TODO: @AshwinBhatkal Remove this with removal of Use_CL_Facial_Rec_Increment_AB

      if (clipLayout === 'DEFAULT') {
        canvasJSON = getDefaultLayoutCanvasData(commonParams);
      } else if (clipLayout === 'SPEAKER') {
        canvasJSON = { ...getActiveSpeakerLayoutCanvasData(commonParams), isAnalysisAccurate };
      } else if (clipLayout === 'GRID') {
        canvasJSON = { ...getSpeakerGridLayoutCanvasData(commonParams), isAnalysisAccurate };
      } else if (clipLayout === 'AUDIOGRAM') {
        canvasJSON = getAudiogramLayoutCanvasData(commonParams);
      }

      if (canvasJSON !== null) {
        // This update should contain clip param and not the current state of currentClip
        finalClip = {
          ...finalClip,
          asset_metadata: {
            ...finalClip.asset_metadata,
            isAutoActiveMappingOverridden,
            isAutoActiveSpeakerIdentified,
            isAnalysisAccurate,
            // ! Contact @AshwinBhatkal on making a change here. This is a temp change as shared API will be phased soon
            config: { ...canvasJSON, remotion_captions: useRemotionCaptions }
          }
        };
      }
    } catch (e) {
      window.logger?.error('Error while updating clip config', e);
    }
  } else {
    const sizeConfig = getSizeConfig(finalClip.asset_metadata.size, finalClip.id, finalClip.asset_metadata.layout, 1);
    finalClip = {
      ...finalClip,
      asset_metadata: {
        ...finalClip.asset_metadata,
        config: {
          ...(finalClip.asset_metadata.config || ({} as any)),
          captions: getCaptions(finalClip.asset_metadata.introDuration || 0, sizeConfig, { ...finalClip }),
          remotion_captions: useRemotionCaptions
        }
      }
    };
  }

  updateClipWithId(clipId, finalClip, true);
  updateClipInList(finalClip);

  return updateClip(currentClip.getSnapshot()[clipId]);
}
