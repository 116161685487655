import { LayoutType } from '@/domains/asset';

export const DEFAULT_TILE_CURSOR = 'cursor-pointer';
export const UNAVAILABLE_CURSOR = 'cursor-not-allowed';
export const PROCESSING_CURSOR = 'cursor-wait';

export const DEFAULT_REASON = 'Something went wrong on our end.';

export enum ClipLayoutStatus {
  Processing = 'PROCESSING',
  Failed = 'FAILED',
  Unavailable = 'UNAVAILABLE',
  Done = 'DONE'
}

export enum ClipUnavailableReason {
  UNAVAILABLE_FOR_NON_LIVE = 'UNAVAILABLE_FOR_NON_LIVE',
  TRANSCRIPT_NOT_AVAILABLE = 'TRANSCRIPT_NOT_AVAILABLE',
  OLDER_SESSIONS = 'OLDER_SESSIONS',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  MORE_THAN_4_SPEAKERS = 'MORE_THAN_4_SPEAKERS',
  LESS_THAN_2_SPEAKERS = 'LESS_THAN_2_SPEAKERS',
  INDIVIDUAL_RECORDING_MISSING = 'INDIVIDUAL_RECORDING_MISSING',
  UNAVAILABLE_FOR_CUSTOM_UPLOAD = 'UNAVAILABLE_FOR_CUSTOM_UPLOAD',
  ONE_OR_MORE_SPEAKERS_WITHOUT_FACE = 'ONE_OR_MORE_SPEAKERS_WITHOUT_FACE',
  ANALYSIS_PENDING = 'ANALYSIS_PENDING',
  LESS_THAN_TWO_SIMULTANEOUS_SPEAKERS = 'LESS_THAN_TWO_SIMULTANEOUS_SPEAKERS',
  MORE_THAN_ONE_SPEAKER_DETECTED = 'MORE_THAN_ONE_SPEAKER_DETECTED',
  NO_FACES_IN_SOME_SEGMENTS = 'NO_FACES_IN_SOME_SEGMENTS'
}

const commonMessages = {
  [ClipUnavailableReason.UNAVAILABLE_FOR_NON_LIVE]: 'Only available for live sessions.',
  [ClipUnavailableReason.TRANSCRIPT_NOT_AVAILABLE]: 'Not available for this session.',
  [ClipUnavailableReason.OLDER_SESSIONS]: 'Session ran prior to Aug 1 2023.',
  [ClipUnavailableReason.UNAVAILABLE_FOR_CUSTOM_UPLOAD]: 'Only available for Goldcast recordings.',
  [ClipUnavailableReason.SOMETHING_WENT_WRONG]: DEFAULT_REASON,
  [ClipUnavailableReason.ONE_OR_MORE_SPEAKERS_WITHOUT_FACE]: 'Not all speakers have been identified.',
  [ClipUnavailableReason.NO_FACES_IN_SOME_SEGMENTS]: 'Ensure faces are visible throughout the clip.',
  [ClipUnavailableReason.ANALYSIS_PENDING]: 'Clip analysis is in progress.',
  [ClipUnavailableReason.LESS_THAN_TWO_SIMULTANEOUS_SPEAKERS]: 'Only one active speaker detected across the clip.',
  [ClipUnavailableReason.MORE_THAN_ONE_SPEAKER_DETECTED]: 'Multiple speakers detected but with some limitations.'
};

export const CLIP_UNAVAILABLE_MESSAGES: Record<LayoutType, null | Record<ClipUnavailableReason, string>> = {
  DEFAULT: null,
  AUDIOGRAM: null,
  SPEAKER: {
    ...commonMessages,
    [ClipUnavailableReason.MORE_THAN_4_SPEAKERS]: '',
    [ClipUnavailableReason.LESS_THAN_2_SPEAKERS]: '',
    [ClipUnavailableReason.INDIVIDUAL_RECORDING_MISSING]: 'Speaker recording is unavailable.'
  },
  GRID: {
    ...commonMessages,
    [ClipUnavailableReason.MORE_THAN_4_SPEAKERS]: 'Not available for more than 4 speakers.',
    [ClipUnavailableReason.LESS_THAN_2_SPEAKERS]: 'Only available for sessions with more than one speaker.',
    [ClipUnavailableReason.INDIVIDUAL_RECORDING_MISSING]: 'Some speaker recordings are unavailable.'
  },
  FOCUS: null
};
