import { memo, useState } from 'react';
import IconButton from '@/components/atoms/Button/IconButton';

function GenerateMoreAITextButton({
  generatePost,
  buttonTextContent
}: {
  generatePost: () => Promise<void>;
  buttonTextContent: string;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const generateMorePost = () => {
    setIsLoading(true);
    generatePost().finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div className="flex items-center justify-center border-t bg-white px-6 py-3">
      {isLoading ? (
        <IconButton
          icon="IconLoader2"
          content=""
          variation="filled"
          size="full"
          disabled={true}
          iconClassName="animate-spin"
          trackingId="loading-more-ai-search-clips-button"
        />
      ) : (
        <IconButton
          icon="IconSparkles"
          content={buttonTextContent}
          variation="filled"
          disabled={isLoading}
          size="full"
          trackingId="genereate-more-ai-search-clips-button"
          onClick={generateMorePost}
        />
      )}
    </div>
  );
}

export default memo(GenerateMoreAITextButton);
