import { IconName } from '@/components/atoms/Icon';
import { FitType, LayoutType, SizeType } from '@/domains/asset';

export interface LayoutPopupOption {
  label: string;
  icon: IconName;
}

export const ASPECT_RATIO_OPTIONS: Record<SizeType, LayoutPopupOption> = {
  LANDSCAPE: {
    label: '16:9',
    icon: 'IconCropLandscape'
  },
  SQUARE: {
    label: '1:1',
    icon: 'IconCrop11'
  },
  PORTRAIT: {
    label: '9:16',
    icon: 'IconCropPortrait'
  }
};

export const MAGIC_LAYOUT_OPTIONS: Record<Extract<LayoutType, 'SPEAKER' | 'GRID' | 'FOCUS'>, LayoutPopupOption> = {
  SPEAKER: {
    label: 'Active Speaker',
    icon: 'IconUserScan'
  },
  GRID: {
    label: 'Speaker Grid',
    icon: 'IconLayoutGrid'
  },
  FOCUS: {
    label: 'Focus',
    icon: 'IconLayoutSidebarRight'
  }
};

export const FIT_OPTIONS: Record<FitType, LayoutPopupOption> = {
  FILL: {
    label: 'Fill',
    icon: 'IconAspectRatio'
  },
  FIT: {
    label: 'Fit',
    icon: 'IconSection'
  }
};
