import { Fragment } from 'react';
import { AbsoluteFill, useCurrentFrame } from 'remotion';
import TrackComponent from '../components/TrackComponent/TrackComponent';
import { CompositionConfig } from './types/CompositionConfig';

const MainPlayer: React.FC<CompositionConfig> = ({
  playerConfig: { tracks },
  showPlayerFrames = false,
  onOutlineRelease,
  CaptionsMenu
}) => {
  const frame = useCurrentFrame();

  return (
    <Fragment>
      <AbsoluteFill className="bg-black">
        {tracks.map(track => {
          if (track.hideTrack.inPlayer) return null;

          return (
            <TrackComponent
              track={track}
              key={track.id}
              onOutlineRelease={onOutlineRelease}
              CaptionsMenu={CaptionsMenu}
            />
          );
        })}
      </AbsoluteFill>
      {showPlayerFrames && (
        <div style={{ position: 'fixed', top: 0, left: 0, color: 'red', fontSize: 60 }}>{frame}</div>
      )}
    </Fragment>
  );
};

export default MainPlayer;
