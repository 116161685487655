import { CSSProperties } from 'react';
import { PositionStyleProperties } from '../Style/Position';
import { Volume } from '../Volume';
import { BaseElement } from './BaseElement';
import { Trim } from './Trim';
import { ElementType } from './Type';
import { Font } from '../Font';
export enum SpeakerLabelElementType {
  TEXT = 'TEXT',
  CONTAINER = 'CONTAINER'
}

export interface BaseSpeakerLabelInterface {
  style?: CSSProperties;
  type: SpeakerLabelElementType;
}

export interface SpeakerLabelTextInterface extends BaseSpeakerLabelInterface {
  type: SpeakerLabelElementType.TEXT;
  content: string;
}

export interface ContainerSpeakerLabelElement extends BaseSpeakerLabelInterface {
  type: SpeakerLabelElementType.CONTAINER;
  elements?: SpeakerLabelInterface[];
  font?: Font;
}

export type SpeakerLabelInterface = SpeakerLabelTextInterface | ContainerSpeakerLabelElement;

export interface VideoElement extends BaseElement {
  type: ElementType.VIDEO;
  details: VideoElementDetails;
  trim: Trim;
  style: Partial<VideoStyleProperties>;
  containerStyle: Partial<VideoStyleProperties>;
  speakerLabel?: SpeakerLabelInterface;
}

interface VideoElementDetails {
  src: string;
  preview?: string;
  altText: string;
  volume: Volume;
}

type VideoStyleProperties = PositionStyleProperties &
  Pick<
    CSSProperties,
    | 'opacity'
    | 'transform'
    | 'borderWidth'
    | 'borderColor'
    | 'borderStyle'
    | 'borderRadius'
    | 'backgroundColor'
    | 'rotate'
    | 'objectFit'
    | 'zIndex'
  >;
