import { Popover, Transition } from '@headlessui/react';
import moment from 'moment';
import { memo } from 'react';

function TitlePopup({
  onUpdate,
  title,
  onTitleChange,
  recordingDate,
  onRecordingDateChange,
  disableTitlePopup
}: {
  onUpdate: () => void;
  title?: string;
  onTitleChange: (e: React.ChangeEvent) => void;
  recordingDate?: string;
  onRecordingDateChange?: (e: React.ChangeEvent) => void;
  disableTitlePopup?: boolean;
}) {
  const maxDate = moment().format('YYYY-MM-DD');

  return (
    <Popover className="relative z-50" data-testid="content-title-popup">
      <Popover.Button
        className="max-w-[10rem] truncate rounded-lg px-3 py-2 text-sm text-slate-600 hover:bg-slate-200"
        title={title}
        disabled={disableTitlePopup}
      >
        {title}
      </Popover.Button>

      <Transition
        enter="transition duration-150 ease-out"
        enterFrom="transform scale-[0.98] opacity-0"
        enterTo="transform scale-1 opacity-100"
        leave="transition duration-100 ease-out"
        leaveFrom="transform scale-1 opacity-100"
        leaveTo="transform scale-[0.98] opacity-0"
        afterLeave={onUpdate}
      >
        <Popover.Panel className="absolute w-[23rem] translate-y-[-2.875rem] rounded-lg border bg-white p-2 shadow-lg">
          <input
            type="text"
            data-testid="title-input"
            className="w-full rounded-lg border bg-slate-50 px-2.5 py-2 text-sm"
            value={title}
            required
            onChange={onTitleChange}
          />
          {!!onRecordingDateChange && (
            <>
              <div className="pt-3 text-xs text-slate-500">Recording Date</div>
              <input
                type="date"
                data-testid="recording-date-input"
                className="w-full rounded-lg border bg-slate-50 px-2.5 py-2 pt-1 text-sm"
                value={recordingDate}
                required
                max={maxDate}
                onChange={onRecordingDateChange}
              />
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default memo(TitlePopup);
