import { init, FeatureFlagMapping } from '@goldcast/feature-flags';
import { getEnvConfig } from '@/constants';
import featureFlagStore from '@/stores/featureFlagStore';

// Define the mapping of feature flag keys to their value types
export type FeatureFlagTypeMap = {
  Use_CL_AI_Debug_Tools: boolean;
  Use_CL_New_Timeline: boolean;
  Use_CL_Captions_Overlay: boolean;
  Use_CL_Facial_Rec_Increment_AB: boolean;
  Use_CL_Fast_Accurate: boolean;
  Use_CL_Edit_Full_Recording: boolean;
  Use_CL_Edit_Full_Recording_Trim: boolean;
  Use_CL_Edit_Full_Recording_Correct: boolean;
  Use_CL_Edit_Full_Recording_Customizer: boolean;
  Use_CL_Clip_Player_V2: boolean;
  Use_CL_Chapterization: boolean;
  Use_CL_Speaker_Labels_Recordings: boolean;
  Use_CL_Remotion_Captions: boolean;
  Use_CL_Resize_Captions: boolean;
  Use_CL_New_Recordings_Player: boolean;
  Use_CL_New_Recordings_Player_Portrait: boolean;
  Use_CL_New_Recordings_Player_Square: boolean;
  Use_CL_Show_Player_Frames: boolean;
  Use_CL_Init_New_Player_Config: boolean;
  Use_CL_Test_RS_Date: string;
  Use_CL_Uploads_In_VL: boolean;
  Use_Caption_Translation: boolean;
};

export enum FeatureFlagKeys {
  /* PERMANENT FLAGS */
  Use_CL_AI_Debug_Tools = 'Use_CL_AI_Debug_Tools',
  /* TEMPORARY FLAGS */
  Use_CL_New_Timeline = 'Use_CL_New_Timeline',
  Use_CL_Captions_Overlay = 'Use_CL_Captions_Overlay',
  Use_CL_Facial_Rec_Increment_AB = 'Use_CL_Facial_Rec_Increment_AB',
  Use_CL_Fast_Accurate = 'Use_CL_Fast_Accurate',
  Use_CL_Edit_Full_Recording = 'Use_CL_Edit_Full_Recording',
  Use_CL_Edit_Full_Recording_Trim = 'Use_CL_Edit_Full_Recording_Trim',
  Use_CL_Edit_Full_Recording_Correct = 'Use_CL_Edit_Full_Recording_Correct',
  Use_CL_Edit_Full_Recording_Customizer = 'Use_CL_Edit_Full_Recording_Customizer',
  Use_CL_Clip_Player_V2 = 'Use_CL_Clip_Player_V2',
  Use_CL_Chapterization = 'Use_CL_Chapterization',
  Use_CL_Speaker_Labels_Recordings = 'Use_CL_Speaker_Labels_Recordings',
  Use_CL_Remotion_Captions = 'Use_CL_Remotion_Captions',
  Use_CL_Resize_Captions = 'Use_CL_Resize_Captions',
  Use_CL_New_Recordings_Player = 'Use_CL_New_Recordings_Player',
  Use_CL_New_Recordings_Player_Portrait = 'Use_CL_New_Recordings_Player_Portrait',
  Use_CL_New_Recordings_Player_Square = 'Use_CL_New_Recordings_Player_Square',
  Use_CL_Show_Player_Frames = 'Use_CL_Show_Player_Frames',
  Use_CL_Init_New_Player_Config = 'Use_CL_Init_New_Player_Config',
  Use_CL_Test_RS_Date = 'Use_CL_Test_RS_Date',
  Use_CL_Uploads_In_VL = 'Use_CL_Uploads_In_VL',
  Use_Caption_Translation = 'Use_Caption_Translation'
}

export const handleFlagValueChange =
  <T extends keyof FeatureFlagTypeMap>(flag: T) =>
  (value: FeatureFlagTypeMap[T]) => {
    featureFlagStore.update(data => ({
      ...data,
      [flag]: value
    }));
  };

export const featureFlags = (): FeatureFlagMapping<FeatureFlagTypeMap> => ({
  [FeatureFlagKeys.Use_CL_AI_Debug_Tools]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Debug_Tools)
  },
  [FeatureFlagKeys.Use_CL_New_Timeline]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_New_Timeline)
  },
  [FeatureFlagKeys.Use_CL_Captions_Overlay]: {
    value: true,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Captions_Overlay)
  },
  [FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB)
  },
  [FeatureFlagKeys.Use_CL_Fast_Accurate]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Fast_Accurate)
  },
  [FeatureFlagKeys.Use_CL_Edit_Full_Recording]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Edit_Full_Recording)
  },
  [FeatureFlagKeys.Use_CL_Clip_Player_V2]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Clip_Player_V2)
  },
  [FeatureFlagKeys.Use_CL_Edit_Full_Recording_Trim]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Edit_Full_Recording_Trim)
  },
  [FeatureFlagKeys.Use_CL_Chapterization]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Chapterization)
  },
  [FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings)
  },
  [FeatureFlagKeys.Use_CL_Edit_Full_Recording_Correct]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Edit_Full_Recording_Correct)
  },
  [FeatureFlagKeys.Use_CL_Remotion_Captions]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Remotion_Captions)
  },
  [FeatureFlagKeys.Use_CL_Edit_Full_Recording_Customizer]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Edit_Full_Recording_Customizer)
  },
  [FeatureFlagKeys.Use_CL_Resize_Captions]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Resize_Captions)
  },
  [FeatureFlagKeys.Use_CL_New_Recordings_Player]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_New_Recordings_Player)
  },
  [FeatureFlagKeys.Use_CL_New_Recordings_Player_Portrait]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_New_Recordings_Player_Portrait)
  },
  [FeatureFlagKeys.Use_CL_New_Recordings_Player_Square]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_New_Recordings_Player_Square)
  },
  [FeatureFlagKeys.Use_CL_Show_Player_Frames]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Show_Player_Frames)
  },
  [FeatureFlagKeys.Use_CL_Init_New_Player_Config]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Init_New_Player_Config)
  },
  [FeatureFlagKeys.Use_CL_Test_RS_Date]: {
    value: '',
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Test_RS_Date)
  },
  [FeatureFlagKeys.Use_CL_Uploads_In_VL]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Uploads_In_VL)
  },
  [FeatureFlagKeys.Use_Caption_Translation]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_Caption_Translation)
  }
});

// store promise in var for cases of calling this function from multiple places
let initializationPromise: Promise<void> | null = null;

export const initializeFeatureFlags = user => {
  if (featureFlagStore.getSnapshot().areFlagsLoaded) return Promise.resolve();
  if (!initializationPromise) {
    initializationPromise = new Promise<void>((resolve, reject) => {
      init<FeatureFlagTypeMap>(
        getEnvConfig('APP_HARNESS_SECRET') || '',
        {
          identifier: user.id || 'anonymous',
          name: `${user.first_name} ${user.last_name}` || 'anonymous',
          attributes: {
            role: 2, // admin role
            event: '',
            eventOrganization: user.organization,
            is_content_lab_standalone: user.is_content_lab_standalone,
            appHostname: window.location?.hostname
          }
        },
        featureFlags(),
        {
          ready: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return resolve();
          },
          errored: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return reject();
          },
          errorAuth: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return reject();
          }
        }
      );
    });
  }
  return initializationPromise;
};
