import { memo, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import Loader from '../../atoms/Loader';
import { classnames } from '@/libs/utils';
import { ClipPublishedStatus } from '@/domains/asset';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { PUSHER_STATES } from '@/context/PusherContext/PusherContextConstants';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';

function ClipPublishStatusBadge() {
  const { clipData } = useClipsContext();
  const { logger } = useAppContext();

  const status = useMemo(() => {
    return clipData.asset_metadata.publish_status;
  }, [clipData.asset_metadata.publish_status]);

  const lastPublishedAt = useMemo(() => {
    if (!clipData.asset_metadata.published_at) return;
    return moment(new Date(clipData.asset_metadata.published_at)).fromNow();
  }, [clipData.asset_metadata.published_at]);

  const formattedPublishedAt = useMemo(() => {
    if (!clipData.asset_metadata.published_at) return;
    return moment(new Date(clipData.asset_metadata.published_at)).format('MMM D, YYYY [at] h:mm A');
  }, [clipData.asset_metadata.published_at]);

  const updateClipPublishedStatus = useCallback(
    ({ data }) => {
      if (
        data.broadcast_id === clipData.content.id ||
        data.upload_id === clipData.content.id ||
        data.saved_search_id === clipData?.saved_search?.id
      ) {
        EventBus.dispatch(CustomEvents.ReloadClip, { showLoader: false, assetId: data.asset_id });
        if (data.state === PUSHER_STATES.PUBLISH_STATUS_UPDATED_DONE) {
          showSuccessToast(
            <span>
              Your video <b>{data?.asset_title}</b> is successfully published!
            </span>
          );
        } else if (data.state === PUSHER_STATES.PUBLISH_STATUS_UPDATED_FAILED) {
          showErrorToast('Sorry, we encountered an issue publishing video.');
          logger.error('Error publishing video: ', data);
        }
      }
    },
    [clipData.content.id, logger]
  );

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.PublishRecordingStatusUpdated, updateClipPublishedStatus);
    return () => {
      EventBus.off(CustomEvents.PublishRecordingStatusUpdated, eventListener);
    };
  }, [updateClipPublishedStatus]);

  const statusLabel = useMemo(() => {
    switch (status) {
      case ClipPublishedStatus.PROCESSING:
        return 'Publishing';
      case ClipPublishedStatus.FAILED:
        return 'Publishing Failed';
      default:
        return null;
    }
  }, [status]);

  return (
    <div className="flex space-x-1">
      {!!statusLabel && (
        <div
          className={classnames(
            'mr-1 flex h-fit w-fit items-center gap-2 rounded-full px-2.5 py-1 text-center text-2xs font-medium',
            {
              'bg-slate-100 text-slate-600': status === ClipPublishedStatus.PROCESSING,
              'bg-rose-200 text-rose-500': status === ClipPublishedStatus.FAILED
            }
          )}
        >
          <span> {statusLabel}</span>
        </div>
      )}
      {status === ClipPublishedStatus.PUBLISHED && (
        <span className="flex items-center text-2xs text-slate-500">
          <span title={formattedPublishedAt}>Last Published {lastPublishedAt}</span>
        </span>
      )}
      {status === ClipPublishedStatus.PROCESSING && <Loader size="xs" />}
    </div>
  );
}

export default memo(ClipPublishStatusBadge);
