import { SpeakerLabelElementType, SpeakerLabelInterface } from '../../../types/Element/VideoElement';
import { alignedRecordingIdToSpeakerKeyMap } from '../../../stores/alignedRecordings';
import { Clip } from '@/domains/asset';
import { BACKGROUND_COLOR_DEFAULT, TEXT_COLOR_DEFAULT } from '@/stores/clip';
import { getFontFamilyStyle } from '@/App/remotion/libs/font';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { sharedAPI } from '@/stores/sharedAPI/sharedAPI';

export const getSpeakerLabelConfig = (recordingId: string, clip: Clip) => {
  // Should support for template based speaker label
  const clipId = clip.id;

  const transcriptStore = sharedAPI.getSnapshot()[clipId].transcriptStore;
  const speaker = transcriptStore.speakersWithDetails[alignedRecordingIdToSpeakerKeyMap.getSnapshot()[recordingId]];
  const { name, title } = getSpeakerNameAndTitle(speaker);

  const assetMetadata = clip.asset_metadata;
  const fontLocation = assetMetadata.font_location;
  const fontName = getFontFamilyStyle(assetMetadata.id, fontLocation);

  const output: SpeakerLabelInterface = {
    style: {
      position: 'absolute',
      bottom: '0.7cqw',
      left: '0.7cqw',
      minWidth: '40cqw',
      maxWidth: '50cqw',
      alignItems: 'flex-start',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      lineHeight: 'none',
      background: assetMetadata?.magicLayout?.textColor || TEXT_COLOR_DEFAULT,
      color: assetMetadata?.magicLayout?.backgroundColor || BACKGROUND_COLOR_DEFAULT,
      borderRadius: '4px',
      padding: '1cqi',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: fontName
    },
    type: SpeakerLabelElementType.CONTAINER,
    elements: [
      {
        type: SpeakerLabelElementType.TEXT,
        content: name,
        style: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: 'max(2.5cqi, 12px)',
          lineHeight: 'none',
          fontFamily: 'inherit'
        }
      },

      ...(title
        ? [
            {
              type: SpeakerLabelElementType.TEXT,
              content: title,
              style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 'max(2cqi, 10px)',
                lineHeight: 'none',
                fontFamily: 'inherit'
              }
            }
          ]
        : [])
    ],
    ...(fontLocation && fontName ? { font: { location: fontLocation, name: fontName } } : {})
  };
  return output;
};
