import { memo, useCallback, useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { assetsClipDownloadTranscriptRetrieve } from '@goldcast/api/content';
import IconButton from '@/components/atoms/Button/IconButton';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import { downloadURI } from '@/libs/core';
import { useAppContext } from '@/context/AppContext/AppContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

const TranscriptDownloadButton = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { logger } = useAppContext();
  const { clipId } = useClipsContext();

  const downloadTranscript = useCallback(
    (format = 'DOCX') => {
      if (!clipId) {
        showErrorToast('Clip ID not found');
        return;
      }

      setIsDownloading(true);

      assetsClipDownloadTranscriptRetrieve({
        id: clipId,
        queryParams: {
          doc_type: format
        }
      })
        .then(response => {
          if (!response.url) {
            throw new Error('Transcript download URL not found');
          }

          downloadURI(response.url);
          showSuccessToast('Transcript download started!');
        })
        .catch(error => {
          logger.error(error);
          showErrorToast('Failed to download transcript');
        })
        .finally(() => {
          setIsDownloading(false);
        });
    },
    [clipId, logger]
  );

  const downloadVTT = useCallback(() => {
    downloadTranscript('VTT');
  }, [downloadTranscript]);

  const downloadDOCX = useCallback(() => {
    downloadTranscript('DOCX');
  }, [downloadTranscript]);

  return (
    <div className="relative">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button as="div" className="inline-flex">
            <IconButton
              icon={isDownloading ? 'IconLoader2' : 'IconDownload'}
              buttonClassName="h-8 rounded"
              iconClassName={isDownloading ? 'animate-spin' : ''}
              disabled={isDownloading}
              trackingId="transcript-download-button"
              title="Download Transcript (VTT/DOCX)"
              size="base"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={downloadVTT}
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } flex w-full items-center px-4 py-2 text-sm`}
                    data-testid="transcript-download-vtt"
                  >
                    .vtt
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={downloadDOCX}
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } flex w-full items-center px-4 py-2 text-sm`}
                    data-testid="transcript-download-docx"
                  >
                    .docx
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default memo(TranscriptDownloadButton);
