import { useCurrentFrame } from 'remotion';
import { useMemo } from 'react';
import VideoElementComponent from './VideoElementComponent';
import { VideoElement } from '@/App/remotion/MainPlayer/types/Element/VideoElement';

/**
 * use this component specifically to handle deletes in a Sequence
 */
const VideoElementComponentWithPlayRange: React.FC<{
  element: VideoElement;
  playRanges: { fromFrame: number; toFrame: number }[];
  disableMediaFragmentHints?: boolean;
}> = ({ element, disableMediaFragmentHints = false, playRanges }) => {
  const { fromFrame } = element;
  const currentFrame = useCurrentFrame();

  const startFrom = useMemo(() => {
    if (!playRanges.length) return fromFrame;

    let summedUpDurations = 0;
    for (const section of playRanges) {
      summedUpDurations += section.toFrame - section.fromFrame;
      if (summedUpDurations > currentFrame) {
        return section.toFrame - summedUpDurations;
      }
    }

    return null;
  }, [currentFrame, playRanges, fromFrame]);

  if (startFrom === null) {
    return null;
  }

  return (
    <VideoElementComponent
      element={{ ...element, fromFrame: startFrom }}
      disableMediaFragmentHints={disableMediaFragmentHints}
      ignoreEndAt
    />
  );
};

export default VideoElementComponentWithPlayRange;
