import { useCurrentFrame } from 'remotion';
import { getSpeakerLayoutBorderKey } from '@goldcast/utils';
import { CSSProperties, useMemo } from 'react';
import { CompositeSegment } from '../../MainPlayer/types/Segment/CompositeSegment';
import { layoutConfigMap } from './layoutConfigMap';
import VideoElementComponentWithPlayRange from '../VideoElementComponent/VideoElementComponentWithPlayRange';

const CompositeSegmentComponent: React.FC<{
  segment: CompositeSegment;
}> = ({ segment }) => {
  const currentFrame = useCurrentFrame();

  const currentLayout = segment.layoutChanges.find(change => {
    return currentFrame >= change.fromFrame && currentFrame <= change.toFrame;
  });

  const backgroundStyle = useMemo(() => {
    const background = segment.details.background;
    if (background.type === 'IMAGE_URL') {
      return { backgroundImage: `url(${background.value})`, backgroundSize: 'cover', backgroundPosition: 'center' };
    }

    // For type === 'COLOR' or others
    return { backgroundColor: background.value };
  }, [segment.details?.background?.value]);

  const videosToShow: string[] = useMemo(() => {
    if (!currentLayout) {
      return [];
    }

    const videoIds = currentLayout.videoIds;

    return segment.videos
      .map(video => video.id)
      .filter(videoId => videoIds.includes(videoId))
      .sort((a, b) => videoIds.indexOf(a) - videoIds.indexOf(b));
  }, [currentLayout?.videoIds, currentLayout?.layout, segment.videos]);

  const finalConfig = useMemo(() => {
    if (!currentLayout) {
      return [];
    }

    return layoutConfigMap[currentLayout.size][currentLayout.layout][currentLayout.fitMode][
      getSpeakerLayoutBorderKey(!currentLayout.hideBorders)
    ][videosToShow.length];
  }, [currentLayout, videosToShow]);

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        ...backgroundStyle
      }}
    >
      {segment.videos.map(video => {
        const videoIndex = videosToShow.indexOf(video.id);

        const newStyle = videoIndex === -1 ? {} : finalConfig[videoIndex].style;
        const newContainerStyle = videoIndex === -1 ? { zIndex: -1 } : finalConfig[videoIndex].containerStyle;
        return (
          <VideoElementComponentWithPlayRange
            key={video.id}
            element={{
              ...video,
              style: {
                ...video.style,
                ...newStyle,
                ...(currentLayout?.hasScreenshare && videoIndex === 0 ? { objectFit: 'contain' } : {})
              } as CSSProperties,
              containerStyle: {
                ...video.containerStyle,
                ...newContainerStyle
              } as CSSProperties
            }}
            disableMediaFragmentHints={true}
            playRanges={segment.playRanges}
          />
        );
      })}
    </div>
  );
};

export default CompositeSegmentComponent;
