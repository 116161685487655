import HLS from 'hls.js';

export const getHLSVideoElement = (url: string, autoplay: boolean) => {
  const videoElement = document.createElement('video');

  videoElement.controls = true;
  videoElement.autoplay = autoplay;

  if (HLS.isSupported()) {
    const hls = new HLS({
      backBufferLength: 30
    });
    hls.attachMedia(videoElement);
    hls.on(HLS.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(url);
      hls.on(HLS.Events.MANIFEST_PARSED, () => {
        if (autoplay) {
          videoElement.play();
        }
      });
    });

    hls.on(HLS.Events.ERROR, (event: any, data: any) => {
      if (videoElement.onerror) {
        videoElement.onerror(data);
      }
    });
  } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
    videoElement.src = url;
    videoElement.setAttribute('playsinline', '');
  } else {
    throw new Error('HLS Playback is not supported on this device!');
  }
  return videoElement;
};
