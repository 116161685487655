import { getSizeConfig } from './sizeConfig/SizeConfigFactory';
import { getFileNameFromPath } from '../file';
import { getDefaultFontProperties } from './constants';
import { roundToNDecimalPlaces } from '../utils';
import { SizeConfig } from './sizeConfig/SizeConfig';
import { SharedAPIStore } from '@/stores/sharedAPI/sharedAPI';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { AssetConfigCaptionStyle, CaptionStyle, Clip, ClipMetadata, SizeType } from '@/domains/asset';
import { getCurrentClipFontName } from '@/stores/brandKit';
import { FacePositions } from '@/stores/speakersAnalysis/speakersAnalysisTypes';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { Word } from '@/domains/transcript';
import { getFontByLocation } from '@/Pages/Clip/SideBar/FontSelector/constants';

export function getCommonBits(
  sizeConfig: ReturnType<typeof getSizeConfig>,
  clip: Clip,
  sharedAPIStoreForClip: SharedAPIStore
) {
  const introVideoSource = clip.asset_metadata.intro;
  const introVideoDuration = !!introVideoSource ? sharedAPIStoreForClip.introPlayer?.duration || 0 : 0;

  const outroVideoSource = clip.asset_metadata.outro;
  const outroVideoDuration = !!outroVideoSource ? sharedAPIStoreForClip.outroPlayer?.duration || 0 : 0;

  const clipDuration = clip.asset_metadata.duration || 0;

  const modifiedExcludes = clip.asset_metadata?.excludes?.map(exclude => {
    return { start: exclude[0], end: exclude[1] };
  });

  const canvas_width = sizeConfig.getWidth();
  const canvas_height = sizeConfig.getHeight();

  return {
    introVideoSource,
    introVideoDuration,
    outroVideoSource,
    outroVideoDuration,
    clipDuration,
    modifiedExcludes,
    showCaption: !!clip.asset_metadata.subtitle,
    captions: getCaptions(introVideoDuration, sizeConfig, clip),
    canvas_height,
    canvas_width
  };
}

export function getIntroVideo(
  introVideoDuration: number,
  sizeConfig: ReturnType<typeof getSizeConfig>,
  sharedAPIStoreForClip: SharedAPIStore,
  introVideoSource?: string
) {
  if (!introVideoSource) {
    return { end: 0, introVideo: [] };
  }

  const {
    source_crop_x,
    source_crop_y,
    source_crop_width,
    source_crop_height,
    target_crop_x,
    target_crop_y,
    target_crop_width,
    target_crop_height
  } = sizeConfig.getCropPositions({
    showCaption: false,
    videoWidth: sharedAPIStoreForClip.introPlayer?.videoWidth!,
    videoHeight: sharedAPIStoreForClip.introPlayer?.videoHeight!,
    centreCropOnly: true
  });

  const start = 0;
  const end = roundToNDecimalPlaces(introVideoDuration, 3);

  return {
    end,
    introVideo: [
      {
        start,
        end,
        x: target_crop_x,
        y: target_crop_y,
        z: 0,
        bounding_width: target_crop_width,
        bounding_height: target_crop_height,
        radius: sizeConfig.getBorderVideoRadius(),
        meta: {
          excludes: [],
          crop_x: source_crop_x,
          crop_y: source_crop_y,
          crop_width: source_crop_width,
          crop_height: source_crop_height,
          source: introVideoSource,
          in_time: 0
        }
      }
    ]
  };
}

export function getOutroVideo(
  outroClipStart: number,
  outroVideoDuration: number,
  sizeConfig: ReturnType<typeof getSizeConfig>,
  sharedAPIStoreForClip: SharedAPIStore,
  outroVideoSource?: string
) {
  if (!outroVideoSource) {
    return { end: outroClipStart, outroVideo: [] };
  }

  const {
    source_crop_x,
    source_crop_y,
    source_crop_width,
    source_crop_height,
    target_crop_x,
    target_crop_y,
    target_crop_width,
    target_crop_height
  } = sizeConfig.getCropPositions({
    showCaption: false,
    videoWidth: sharedAPIStoreForClip.outroPlayer?.videoWidth!,
    videoHeight: sharedAPIStoreForClip.outroPlayer?.videoHeight!,
    centreCropOnly: true
  });

  const start = outroClipStart;
  const end = roundToNDecimalPlaces(outroClipStart + outroVideoDuration, 3);

  return {
    end,
    outroVideo: [
      {
        start,
        end,
        x: target_crop_x,
        y: target_crop_y,
        z: 0,
        bounding_width: target_crop_width,
        bounding_height: target_crop_height,
        radius: sizeConfig.getBorderVideoRadius(),
        meta: {
          excludes: [],
          crop_x: source_crop_x,
          crop_y: source_crop_y,
          crop_width: source_crop_width,
          crop_height: source_crop_height,
          source: outroVideoSource,
          in_time: 0
        }
      }
    ]
  };
}

export function getCaptions(introVideoDuration: number, sizeConfig: ReturnType<typeof getSizeConfig>, clip: Clip) {
  const {
    top: bottom_margin,
    left: left_margin,
    right: right_margin,
    rotation,
    wrap_style,
    alignment
  } = sizeConfig.getCaptionAttributes({
    containerHeight: sizeConfig.getHeight(),
    containerWidth: sizeConfig.getWidth(),
    captionFontSize: 0
  });

  return clip.asset_metadata.layout === 'AUDIOGRAM'
    ? [
        {
          words: getCaptionWords(clip.asset_metadata),
          drift: introVideoDuration,
          bottom_margin,
          left_margin,
          right_margin,
          rotation,
          wrap_style,
          style: getFontStyles(clip.asset_metadata, sizeConfig, alignment)
        }
      ]
    : getVideoLayoutCaptions(clip, introVideoDuration, sizeConfig);
}

function getVideoLayoutCaptions(clip: Clip, introVideoDuration: number, sizeConfig: SizeConfig) {
  const featureFlags = featureFlagStore.getSnapshot();
  const isCaptionsOverlayDisabled = featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false;
  if (isCaptionsOverlayDisabled || !clip.asset_metadata.caption_positions) {
    return [
      {
        words: getCaptionWords(clip.asset_metadata),
        drift: introVideoDuration,
        left_margin: sizeConfig.getCaptionsLeftMargin(),
        right_margin: sizeConfig.getCaptionsRightMargin(),
        bottom_margin: sizeConfig.getCaptionsBottomMargin(),
        rotation: 0,
        style: getFontStyles(clip.asset_metadata, sizeConfig)
      }
    ];
  }
  const captionsScale = clip.asset_metadata.caption_styles?.scale || 1;
  const fontItem = getFontByLocation(clip.asset_metadata.font_location);
  const fontScaleInPx = fontItem?.scaleInPx || 0;
  const yCorrection = !!fontScaleInPx ? fontScaleInPx * 2 * captionsScale : 0;
  const captionsX = parseFloat(clip.asset_metadata.caption_positions!.x as unknown as string);
  const captionsWidth = parseFloat(clip.asset_metadata.caption_positions!.width as unknown as string);
  const captionsY = parseFloat(clip.asset_metadata.caption_positions!.y as unknown as string);

  return [
    {
      words: getCaptionWords(clip.asset_metadata),
      drift: introVideoDuration,
      left_margin: (sizeConfig.getWidth() / 100) * captionsX || 0,
      right_margin:
        sizeConfig.getWidth() -
          ((sizeConfig.getWidth() / 100) * captionsX + (sizeConfig.getWidth() / 100) * captionsWidth) || 0,
      bottom_margin:
        (sizeConfig.getHeight() / 100) * (clip.asset_metadata.caption_positions?.textY || captionsY || 0) - yCorrection,
      rotation: 0,
      wrap_style: 1,
      style: getFontStyles(clip.asset_metadata, sizeConfig, 8)
    }
  ];
}

function getCaptionWords(clipMetadata: ClipMetadata): Word[] {
  const featureFlags = featureFlagStore.getSnapshot();
  const isCaptionsOverlayDisabled = featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false;
  if (isCaptionsOverlayDisabled || !clipMetadata.caption_styles?.caps) {
    return clipMetadata.captions;
  }

  return clipMetadata.captions.map(w => {
    return {
      ...w,
      content: w.content.toUpperCase()
    };
  });
}

function getFontStyles(
  clipMetadata: ClipMetadata,
  sizeConfig: ReturnType<typeof getSizeConfig>,
  // alignment values are based on keyboard numpad directions
  alignment: number = 2
): AssetConfigCaptionStyle {
  const captionsScale = clipMetadata.caption_styles?.scale || 1;
  const fontItem = getFontByLocation(clipMetadata.font_location);
  return {
    highlight_style: {
      ...getHighlightStyleBackground(clipMetadata),
      font_color:
        clipMetadata.highlight_type !== 'none' && !!clipMetadata.text_highlight_color
          ? clipMetadata.text_highlight_color
          : clipMetadata.magicLayout?.textColor,
      font_size: Math.round(
        getFontSize({
          playerHeight: sizeConfig.getHeight(),
          clipSize: clipMetadata.size,
          scale: captionsScale
        }) * (fontItem?.scale ? fontItem.scale / 100 : 1)
      ),
      font_opacity: '00',
      alignment,
      bold: 1,
      ...getFontProperties(clipMetadata)
    },
    regular_style: {
      font_color: clipMetadata.magicLayout?.textColor,
      font_size: Math.round(
        getFontSize({
          playerHeight: sizeConfig.getHeight(),
          clipSize: clipMetadata.size,
          scale: captionsScale
        }) * (fontItem?.scale ? fontItem.scale / 100 : 1)
      ),
      font_opacity: clipMetadata.highlight_type === 'text' ? '80' : '00',
      alignment,
      bold: 1,
      ...getFontProperties(clipMetadata),
      ...(clipMetadata.caption_styles?.animation === 'Background'
        ? {
            background_color: clipMetadata.caption_styles.background_style_color,
            background_opacity: '66',
            border_style: 4,
            shadow: 0,
            outline: 0
          }
        : getFontAnimation(clipMetadata))
    }
  };
}

function getFontAnimation(clipMetadata: ClipMetadata): {
  border_style: number;
  outline: number;
  shadow: number;
  font_opacity?: string;
} {
  const featureFlags = featureFlagStore.getSnapshot();
  const isCaptionsOverlayDisabled = featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false;
  const animation = clipMetadata.caption_styles?.animation;
  if (isCaptionsOverlayDisabled || !animation || animation === CaptionStyle.Basic) {
    // border_style: 1, outline: 0, shadow:0 will make background transparent
    return {
      border_style: 1,
      shadow: 0,
      outline: 0
    };
  }

  if (animation === CaptionStyle.Outline) {
    return {
      border_style: 1,
      shadow: 0,
      outline: 2,
      font_opacity: clipMetadata.highlight_type === 'text' ? '80' : '00'
    };
  } else {
    return {
      border_style: 1,
      shadow: 0,
      outline: 0
    };
  }
}

function getHighlightStyleBackground(clipMetadata: ClipMetadata) {
  // If it's not box - return transparent background
  if (clipMetadata.highlight_type === 'box') {
    return {
      background_color: clipMetadata.word_highlight_color
    };
  }
  if (clipMetadata.caption_styles?.animation === 'Background') {
    return {
      background_color: clipMetadata.caption_styles.background_style_color,
      border_style: 4,
      shadow: 0,
      outline: 0
    };
  }
  return getFontAnimation(clipMetadata);
}

export function getSpeakerPillNameTitleConfig(
  speaker: SpeakerWithDetails,
  sameVideo: boolean,
  start: number,
  end: number,
  totalVideos: number,
  currentVideoNumber: number,
  clip: Clip,
  sharedAPIStoreForClip: SharedAPIStore
) {
  const sizeConfig = getSizeConfig(clip.asset_metadata.size, clip.id, clip.asset_metadata.layout, 1);
  const shapes: any[] = [];
  const text: any[] = [];

  const showCaption = !!clip.asset_metadata.subtitle;

  const videoToUse = sameVideo
    ? sharedAPIStoreForClip.mainPlayerRef
    : sharedAPIStoreForClip.speakerMap[speaker.key]?.videoRef;

  if (!videoToUse) return;

  const finalCropPositions = sizeConfig.getCropPositions({
    videoWidth: videoToUse.videoWidth,
    videoHeight: videoToUse.videoHeight,
    showCaption,
    totalVideos,
    currentVideoNumber
  });

  const { name, title } = getSpeakerNameAndTitle(speaker);

  const { nameSize: defaultNameSize, titleSize: defaultTitleSize } =
    sizeConfig.getSpeakerNameTitlePillTextSizes(totalVideos);

  const {
    newTitle,
    newName,
    width: canvasPillWidth,
    isTruncated,
    nameHeight,
    titleHeight
  } = sizeConfig.getCanvasTextSizes(totalVideos, name, title, getCurrentClipFontName(clip.id));

  const nameSize = nameHeight || defaultNameSize;
  const titleSize = titleHeight || defaultTitleSize;

  const { pillX, pillHeight, pillWidth } = sizeConfig.getPillBoxSizes(
    currentVideoNumber,
    totalVideos,
    canvasPillWidth,
    isTruncated
  );

  shapes.push({
    start,
    end,
    shape: {
      type: 'SPEAKER_LABEL',
      x: finalCropPositions.target_crop_x + pillX,
      y: finalCropPositions.target_crop_y + finalCropPositions.target_crop_height - pillHeight - pillX,
      width: pillWidth,
      height: pillHeight,
      background: clip.asset_metadata.magicLayout?.textColor
    }
  });

  const nameY = pillHeight / 2 + nameSize / 16;
  const titleY = (nameY * 3) / 2 + nameSize / 2 - titleSize / 2;

  const x = finalCropPositions.target_crop_x + pillX + pillHeight / 2 - 5;
  const y = finalCropPositions.target_crop_y + finalCropPositions.target_crop_height - pillHeight - pillX;

  text.push(
    ...[
      {
        style: {
          regular_style: {
            background_color: clip.asset_metadata.magicLayout?.textColor,
            font_color: clip.asset_metadata.magicLayout?.backgroundColor,
            font_size: Math.round(nameSize),
            alignment: 1,
            ...getFontProperties(clip.asset_metadata, false)
          }
        },
        position: {
          x: x,
          y: y + nameY
        },
        rotation: 0,
        words: {
          content: newName,
          processed_start_time: start,
          processed_end_time: end
        }
      },
      {
        style: {
          regular_style: {
            background_color: clip.asset_metadata.magicLayout?.textColor,
            font_color: clip.asset_metadata.magicLayout?.backgroundColor,
            font_size: Math.round(titleSize),
            alignment: 1,
            font_opacity: '4D',
            ...getFontProperties(clip.asset_metadata)
          }
        },
        position: {
          x: x,
          y: y + titleY
        },
        rotation: 0,
        words: {
          content: newTitle,
          processed_start_time: start,
          processed_end_time: end
        }
      }
    ]
  );

  return {
    text,
    shapes
  };
}

export function getFontProperties(
  clipMetadata: ClipMetadata,
  isRegular: boolean = true
): { font: string; font_location: string } {
  return !!clipMetadata.font_location
    ? {
        font: getFileNameFromPath(clipMetadata.font_location),
        font_location: clipMetadata.font_location
      }
    : getDefaultFontProperties(isRegular);
}

export function formVideoWithAccumulatedWords(
  start: number,
  end: number,
  accumulatedWords: any[],
  sizeConfig: ReturnType<typeof getSizeConfig>,
  modifiedExcludes: any[],
  individualVideo: { url: string; start: number },
  showCaption: boolean,
  speakerKey,
  clip: Clip,
  sharedAPIStoreForClip: SharedAPIStore,
  totalVideos = 1,
  currentVideoNumber = 1
) {
  const { url: source, start: speakerStart } = individualVideo;

  const videoToUse = sharedAPIStoreForClip.speakerMap[speakerKey]?.videoRef;

  const {
    source_crop_x,
    source_crop_y,
    source_crop_width,
    source_crop_height,
    target_crop_x,
    target_crop_y,
    target_crop_width,
    target_crop_height
  } = sizeConfig.getCropPositions({
    videoWidth: videoToUse?.videoWidth || sizeConfig.getWidth(),
    videoHeight: videoToUse?.videoHeight || sizeConfig.getHeight(),
    showCaption,
    totalVideos,
    currentVideoNumber
  });

  return {
    start,
    end,
    x: target_crop_x,
    y: target_crop_y,
    z: 0,
    bounding_width: target_crop_width,
    bounding_height: target_crop_height,
    meta: {
      excludes: modifiedExcludes.map(exclude => ({
        start: exclude.start - speakerStart,
        end: exclude.end - speakerStart
      })),
      crop_x: source_crop_x,
      crop_y: source_crop_y,
      crop_width: source_crop_width,
      crop_height: source_crop_height,
      source,
      in_time: isNaN(speakerStart) ? start + clip.asset_metadata.start : accumulatedWords[0].start_time - speakerStart,
      radius: sizeConfig.getBorderVideoRadius()
    }
  };
}

export function getDeletedTimeInRange(
  start: number,
  end: number,
  modifiedExcludes: {
    start: number;
    end: number;
  }[]
) {
  return modifiedExcludes.reduce((acc, exclude) => {
    if (exclude.end < start) return acc;
    if (exclude.start > end) return acc;

    // min, max functions are required to handle case excludes are outside of range because of trimming
    return acc + (Math.min(exclude.end, end) - Math.max(exclude.start, start));
  }, 0);
}

export function getSectionEndTimesAfterExcludesForUpload(
  times: number[],
  newTimeAnalysis: {
    start: number;
    speakerId: string;
    facePositions?: FacePositions[];
  }[],
  modifiedExcludes: { start: number; end: number }[],
  introVideoDuration: number,
  clip: Clip
): number[] {
  let sum = introVideoDuration;

  const sectionEndTimes = newTimeAnalysis.map((_, index) => {
    const startRange = times[index];
    const endRange = index === times.length - 1 ? clip.asset_metadata.end : times[index + 1];

    // Calculate the total time to be deleted within the current section's range
    const totalTimeDeleted = modifiedExcludes.reduce((acc, exclude) => {
      // Case 1: Exclude range is entirely within the current section range
      if (exclude.start >= startRange && exclude.end <= endRange) {
        return acc + (exclude.end - exclude.start);
      }

      // Case 2: Exclude range spans across the entire current section range
      if (exclude.start < startRange && exclude.end > endRange) {
        return acc + (endRange - startRange);
      }

      // Case 3: Exclude range starts before the current section and ends within it
      if (exclude.start < startRange && exclude.end > startRange && exclude.end < endRange) {
        return acc + (exclude.end - startRange);
      }

      // Case 4: Exclude range starts within the current section and ends after it
      if (exclude.start > startRange && exclude.start < endRange && exclude.end > endRange) {
        return acc + (endRange - exclude.start);
      }

      // Default case: No overlap with the current section range
      return acc;
    }, 0);

    // Calculate the length of the current section after deletions
    const output = endRange - startRange - totalTimeDeleted;

    // Accumulate the effective section lengths
    sum += output;
    return roundToNDecimalPlaces(sum, 3);
  });

  return sectionEndTimes;
}

/**
 * This is for merging the speaker times split and the facial rec analysis times split
 * */
export function mergeSpeakerAndAnalysisTimesArray(
  speakerTimesArray: {
    speakerId: string;
    start: number;
    end: number;
    inTimeForMainVideo: number;
  }[],
  analysisTimesArray: number[],
  clipStart: number
) {
  const result: {
    speakerId: string;
    start: number;
    end: number;
    index: number;
    inTimeForMainVideo: number;
    timeAnalysisIndex: number;
  }[] = [];

  speakerTimesArray.forEach(speakerTimeObject => {
    analysisTimesArray.forEach((analysisTime, index) => {
      const start = analysisTime;
      const end = index === analysisTimesArray.length - 1 ? Infinity : analysisTimesArray[index + 1];

      if (start < speakerTimeObject.end && end > speakerTimeObject.start) {
        const isUsingAnalysisStart = speakerTimeObject.start < start;

        let newInterval = {
          start: Math.max(speakerTimeObject.start, start),
          end: Math.min(speakerTimeObject.end, end),
          speakerId: speakerTimeObject.speakerId,
          index: index,
          inTimeForMainVideo: isUsingAnalysisStart
            ? roundToNDecimalPlaces(clipStart + analysisTime, 3)
            : speakerTimeObject.inTimeForMainVideo,
          timeAnalysisIndex: index
        };

        if (newInterval.start < newInterval.end) {
          result.push(newInterval);
        }
      }
    });
  });

  return result;
}

export function getFontSize({
  playerHeight,
  clipSize,
  scale
}: {
  playerHeight: number;
  clipSize: SizeType;
  scale: number;
}) {
  return Math.round((clipSize === 'PORTRAIT' ? (48 / 1280) * playerHeight : (32 / 720) * playerHeight) * scale);
}
