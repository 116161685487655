import { COMPOSITION_DIMENSIONS, FPS_24, VIDEO_QUALITY } from '../../../constants';
import { getCaptionPages } from '../../../libs/caption';
import { CompositionConfig } from '../../types/CompositionConfig';
import { Page } from '../../../types';
import { TrackType } from '../../types/Track/Type';
import { SegmentsTrack } from '../../types/Track/SegmentsTrack';
import { Track } from '../../types/Track/Track';
import { createMainCompositeSegment } from './compositeSegment/compositeSegment';
import { createIntroVideoSegment } from './videoSegment/introVideoSegment';
import { createOutroVideoSegment } from './videoSegment/outroVideoSegment';
import { SegmentType } from '../../types/Segment/Type';
import { CompositeSegment } from '../../types/Segment/CompositeSegment';
import { Clip } from '@/domains/asset';
import { getFontFamilyStyle } from '@/App/remotion/libs/font';

export function getPlayerConfig(clip: Clip): CompositionConfig {
  const videoQuality = VIDEO_QUALITY.FULL_HD;
  const height = COMPOSITION_DIMENSIONS[clip.asset_metadata.size][videoQuality].HEIGHT;
  const width = COMPOSITION_DIMENSIONS[clip.asset_metadata.size][videoQuality].WIDTH;

  const fps = FPS_24;

  const showCaptions = !!clip.asset_metadata.subtitle;

  const pages: Page[] = getCaptionPages({
    clip
  });

  const segmentsTrack: SegmentsTrack = {
    id: 'main',
    name: 'Segments Track',
    type: TrackType.SEGMENTS,
    hideTrack: {
      inTimeline: false,
      inPlayer: false
    },
    segments: []
  };

  const segmentsTrackValue = clip.asset_metadata.player_config?.playerConfig.tracks.find(
    track => track.type === TrackType.SEGMENTS
  ) as SegmentsTrack;
  const existingCompositeSegment = segmentsTrackValue?.segments.find(
    segment => segment.type === SegmentType.COMPOSITE
  ) as CompositeSegment;

  let durationInFrames = 0;
  const introDuration = Math.ceil((clip.asset_metadata.introDuration || 0) * fps);
  if (clip.asset_metadata.intro && introDuration) {
    segmentsTrack.segments.push(createIntroVideoSegment(clip, introDuration));
    durationInFrames += introDuration;
  }

  const compositeSegmentDuration = Math.ceil((clip.asset_metadata?.duration || 0) * fps);
  const maxBounds = existingCompositeSegment?.maxBounds || {
    fromFrame: 0,
    toFrame: clip.asset_metadata.end * fps
  };

  const compositeSegment = createMainCompositeSegment(
    clip,
    fps,
    compositeSegmentDuration,
    durationInFrames,
    clip.asset_metadata.start * fps,
    clip.asset_metadata.end * fps
  );
  segmentsTrack.segments.push({ ...compositeSegment, maxBounds });
  durationInFrames += compositeSegmentDuration;

  const outroDuration = Math.ceil((clip.asset_metadata.outroDuration || 0) * fps);
  if (clip.asset_metadata.outro && outroDuration) {
    segmentsTrack.segments.push(createOutroVideoSegment(clip, outroDuration, durationInFrames));
    durationInFrames += outroDuration;
  }

  let tracks: Track[] = [];
  if (!!segmentsTrack.segments.length) {
    const fontLocation = clip.asset_metadata.font_location;
    const fontName = getFontFamilyStyle(clip.id, fontLocation);

    tracks = [
      {
        segmentId: 'random',
        name: 'Captions',
        id: 'captions',
        type: TrackType.CAPTIONS,
        hideTrack: {
          inPlayer: !showCaptions,
          inTimeline: true
        },
        pages,
        fromFrame: compositeSegment.fromFrame,
        toFrame: compositeSegment.toFrame,
        ...(fontLocation && fontName ? { font: { location: fontLocation, name: fontName } } : {})
      }
    ];

    tracks.unshift(segmentsTrack);
  } else {
    tracks = clip.asset_metadata.player_config?.playerConfig?.tracks || [];
  }

  return {
    height,
    width,
    fps,
    durationInFrames,
    version: '0.0.1',
    playerConfig: {
      tracks
    }
  };
}
