import { memo, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import IconButton from '../../atoms/Button/IconButton';
import RecordingPublishDialog from './RecordingPublishDialog';
import ClipPublishToLibraryDialog from './ClipPublishToLibraryDialog';
import ClipUsageDialog from './ClipUsageDialog';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import useDialog from '@/components/organisms/useDialog';
import { ClipPublishedStatus } from '@/domains/asset';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import TooltipV2 from '@/components/TooltipV2';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { useAppContext } from '@/context/AppContext/AppContext';
import useAssetUsage from '@/hooks/useAssetUsage';

function ClipPublishButton({ isPublishingAllowed }: { isPublishingAllowed: boolean }) {
  const { clipData } = useClipsContext();
  const { chaptersLastUpdatedAt } = useTranscriptContext();
  const { logger } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { checkAssetUsage, usageData: clipAssetUsage } = useAssetUsage();

  const {
    isOpen: isRecordingPublishDialogOpen,
    openDialog: openRecordingPublishDialog,
    closeDialog: closeRecordingPublishDialog
  } = useDialog();
  const {
    isOpen: isClipPublishToLibraryDialogOpen,
    openDialog: openClipPublishToLibraryDialog,
    closeDialog: closeClipPublishToLibraryDialog
  } = useDialog();

  const {
    isOpen: isClipUsageDialogOpen,
    openDialog: openClipUsageDialog,
    closeDialog: closeClipUsageDialog
  } = useDialog();

  const isFullLengthVideo = useMemo(() => {
    return clipData.asset_metadata.is_full_length;
  }, [clipData.asset_metadata.is_full_length]);

  const isPublishing = useMemo(() => {
    return clipData.asset_metadata.publish_status === ClipPublishedStatus.PROCESSING;
  }, [clipData.asset_metadata.publish_status]);

  const isPublishDisabled = useMemo(() => {
    const publishedStatus = clipData.asset_metadata.publish_status;
    const clipPublishedAtMoment = moment(clipData.asset_metadata.published_at);
    const clipCreatedAtMoment = moment(clipData.asset_metadata.created_at);
    const clipUpdatedAtMoment = moment(clipData.updated_at);
    const chaptersLastUpdatedAtMoment = moment(chaptersLastUpdatedAt);

    if (!isPublishingAllowed) {
      return true;
    }

    if (publishedStatus === ClipPublishedStatus.PROCESSING) {
      return true;
    }

    // if it is a clip, then..
    if (!isFullLengthVideo && publishedStatus !== ClipPublishedStatus.PUBLISHED) {
      return false;
    }

    if (isFullLengthVideo) {
      // This is for pre-video library clips where publish to video library was not a default
      if (publishedStatus === ClipPublishedStatus.DRAFT) {
        return clipUpdatedAtMoment.diff(clipCreatedAtMoment, 'second') < 10;
      }

      if (chaptersLastUpdatedAtMoment) {
        const areChaptersUpdatedAfterPublish = chaptersLastUpdatedAtMoment.isAfter(clipPublishedAtMoment);

        if (areChaptersUpdatedAfterPublish) {
          return false;
        }
      }
    }

    return clipUpdatedAtMoment.diff(clipPublishedAtMoment, 'second') < 10;
  }, [
    clipData.asset_metadata.publish_status,
    clipData.asset_metadata.published_at,
    clipData.asset_metadata.created_at,
    clipData.updated_at,
    chaptersLastUpdatedAt,
    isFullLengthVideo,
    isPublishingAllowed
  ]);

  const tooltipContent = useMemo(() => {
    if (!isPublishingAllowed) {
      return 'Coming soon';
    }

    if (isPublishing) {
      return 'Please wait for publishing to complete before publishing again';
    }

    if (isPublishDisabled) {
      return 'There are no unpublished changes';
    }

    return '';
  }, [isPublishingAllowed, isPublishing, isPublishDisabled]);

  const triggerPublish = useCallback(async () => {
    if (!isFullLengthVideo && !clipData?.asset_metadata?.video_library_id) {
      openClipPublishToLibraryDialog();
      return;
    }

    if (clipData?.asset_metadata?.video_library_id) {
      try {
        setIsLoading(true);
        const usageCount = await checkAssetUsage(clipData?.asset_metadata?.video_library_id);
        if (usageCount > 0) {
          openClipUsageDialog();
        } else {
          openClipPublishToLibraryDialog();
        }
        return;
      } catch (error) {
        logger.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    openRecordingPublishDialog();
  }, [
    isFullLengthVideo,
    clipData?.asset_metadata?.video_library_id,
    openRecordingPublishDialog,
    openClipPublishToLibraryDialog,
    checkAssetUsage,
    openClipUsageDialog
  ]);

  return (
    <>
      <TooltipV2
        place="bottom"
        tooltipId="publish-tooltip"
        tooltipContent={tooltipContent}
        size="xsmall"
        tooltipElement={
          <IconButton
            content="Publish"
            icon={isLoading ? 'IconLoader2' : 'IconArrowBarToUp'}
            iconClassName={isLoading ? 'animate-spin' : ''}
            variation="filled"
            trackingId="publish-recording-button"
            disabled={isPublishDisabled}
            onClick={triggerPublish}
            data-tooltip-id="publish-tooltip"
          />
        }
      />

      <ContentDialog
        isOpen={isRecordingPublishDialogOpen}
        hideCloseIcon={true}
        size="medium"
        setIsOpen={closeRecordingPublishDialog}
        disableBackdropClose={false}
      >
        <RecordingPublishDialog onClose={closeRecordingPublishDialog} />
      </ContentDialog>
      <ContentDialog
        isOpen={isClipPublishToLibraryDialogOpen}
        hideCloseIcon={true}
        size="medium"
        setIsOpen={closeClipPublishToLibraryDialog}
        disableBackdropClose={false}
      >
        <ClipPublishToLibraryDialog
          onClose={closeClipPublishToLibraryDialog}
          isClipAddedToLibrary={!!clipData?.asset_metadata?.video_library_id}
        />
      </ContentDialog>
      <ContentDialog
        isOpen={isClipUsageDialogOpen}
        hideCloseIcon={true}
        size="medium"
        setIsOpen={closeClipUsageDialog}
        disableBackdropClose={false}
      >
        <ClipUsageDialog onClose={closeClipUsageDialog} type={'PUBLISH'} clipAssetUsage={clipAssetUsage} />
      </ContentDialog>
    </>
  );
}

export default memo(ClipPublishButton);
