import { IconCheck, IconEye, IconEyeClosed, IconTrash } from '@tabler/icons-react';
import IconButtonGroup from '@/components/atoms/Button/IconButtonGroup';
import { CLIP_MIN_LENGTH } from '@/components/molecules/Transcript/constants';
import { Clip } from '@/domains/asset';
import { Word, WordType } from '@/domains/transcript';
import {
  deleteWords,
  saveClipChanges,
  trackClipUpdate,
  updateClipCaptionsLoadingState,
  updateClipWithId,
  updateStartEndTime
} from '@/stores/clip';
import { addDecimalIfNeeded } from '@/libs/utils';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';

interface TranscriptEditPanelProps {
  selectedWords: Word[];
  correctInputValue: string;
  setSelectedWords: (value: Word[]) => void;
  selectedWordString: string;
  wordsCount: number;
  clipData: Clip;
}

export default function TranscriptEditPanel({
  selectedWords,
  correctInputValue,
  setSelectedWords,
  selectedWordString,
  wordsCount,
  clipData
}: TranscriptEditPanelProps) {
  const speakerSegmentStore = useSpeakerSegmentContext();

  const editWord = (word: Word, value: string, values = clipData) => {
    const newValues = {
      ...values,
      asset_metadata: {
        ...values.asset_metadata,
        edits: {
          ...values.asset_metadata?.edits,
          [addDecimalIfNeeded(word.start_time)]: {
            start_time: word.start_time,
            end_time: word.end_time,
            word: value
          }
        }
      }
    };

    return newValues;
  };

  function saveAndResetSelection(value: Clip, updateType: 'EDIT_WORD' | 'HIDE_WORD' | 'SHOW_WORD' | 'DELETE_WORD') {
    updateClipCaptionsLoadingState(clipData.id, true);
    updateClipWithId(clipData.id, value, true);
    saveClipChanges(clipData.id, !!value, true, clipData.asset_metadata.is_edited);
    setSelectedWords([]);
    trackClipUpdate(clipData.id, updateType);
  }

  const onEdit = () => {
    if (selectedWordString.split(' ').length > 1) return onMerge(correctInputValue);

    const value = editWord(selectedWords[0], correctInputValue, clipData);
    saveAndResetSelection(value, 'EDIT_WORD');
  };

  const onDelete = () => {
    updateClipCaptionsLoadingState(clipData.id, true);
    const nextStartTime =
      clipData!.asset_metadata.captions?.[selectedWords[selectedWords.length - 1].index + 1]?.start_time;

    const firstSelectedWordIndex = selectedWords[0].index - 1;
    const lastSelectedWordIndex = selectedWords[selectedWords.length - 1].index + 1;

    if (firstSelectedWordIndex === -1) {
      updateStartEndTime(clipData.id, 'start', nextStartTime);
      setSelectedWords([]);
      return;
    }

    if (lastSelectedWordIndex === speakerSegmentStore?.captions.length) {
      updateStartEndTime(clipData.id, 'end', clipData!.asset_metadata.captions?.[firstSelectedWordIndex]?.end_time);
      setSelectedWords([]);
      return;
    }

    deleteWords(clipData.id, {
      prev_end_time: clipData!.asset_metadata.captions?.[selectedWords[0].index - 1]?.end_time,
      next_start_time: nextStartTime,
      bounds: [selectedWords[0].start_time, selectedWords[selectedWords.length - 1].end_time]
    });
    setSelectedWords([]);
  };

  const onMerge = (word = selectedWordString) => {
    const editedWord = { start_time: selectedWords[0].start_time, end_time: selectedWords.at(-1)?.end_time!, word };
    const values: Clip = {
      ...clipData,
      asset_metadata: {
        ...clipData.asset_metadata,
        merges: (clipData.asset_metadata.merges ?? [])
          .filter(({ start_time }) => start_time !== editedWord.start_time)
          .concat(editedWord),
        edits: { ...clipData.asset_metadata.edits, [editedWord.start_time]: editedWord }
      }
    };
    saveAndResetSelection(values, 'EDIT_WORD');
  };

  const onHide = () => {
    const value = selectedWords.reduce((acc, word) => editWord(word, '', acc), clipData);
    saveAndResetSelection(value, 'HIDE_WORD');
  };

  const onShow = () => {
    const edits = { ...clipData.asset_metadata.edits };
    selectedWords.forEach(({ start_time }) => delete edits[start_time]);
    saveAndResetSelection(
      {
        ...clipData,
        asset_metadata: {
          ...clipData.asset_metadata,
          edits
        }
      },
      'SHOW_WORD'
    );
  };

  let visibleSelectedWords = (selectedWords || []).filter(w => w.type !== WordType.Punctuation);

  const actions = [
    {
      content: 'Correct',
      description: 'Correct transcript',
      icon: IconCheck,
      disabled: selectedWords.length === 0 || correctInputValue === selectedWordString,
      action: onEdit,
      visible: true,
      trackingId: 'correct-transcript-button'
    },
    {
      content: 'Hide',
      description: 'Hide words',
      icon: IconEyeClosed,
      disabled: selectedWords.length === 0 || wordsCount - visibleSelectedWords.length < CLIP_MIN_LENGTH,
      action: onHide,
      visible: selectedWords.some(({ hidden }) => !hidden),
      trackingId: 'hide-transcript-button'
    },
    {
      content: 'Show',
      description: 'show words',
      icon: IconEye,
      disabled: selectedWords.length === 0,
      action: onShow,
      visible: selectedWords.every(({ hidden }) => hidden),
      trackingId: 'show-transcript-button'
    },
    {
      content: 'Delete',
      description: 'Delete word and video',
      icon: IconTrash,
      disabled: selectedWords.length === 0 || wordsCount - visibleSelectedWords.length < CLIP_MIN_LENGTH,
      action: onDelete,
      visible: true,
      trackingId: 'delete-transcript-button'
    }
  ].filter(({ visible }) => visible);

  return <IconButtonGroup variation="list" buttons={actions} size="base" />;
}
