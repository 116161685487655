import React, { useEffect, useState } from 'react';
import { loadFont } from '@remotion/fonts';
import { delayRender, continueRender } from 'remotion';
import { SpeakerLabelInterface, SpeakerLabelElementType } from '../../MainPlayer/types/Element/VideoElement';

export default function SpeakerLabel({ speakerLabel }: { speakerLabel?: SpeakerLabelInterface }) {
  const [handle] = useState(() => delayRender());
  useEffect(() => {
    if (
      speakerLabel?.type === SpeakerLabelElementType.CONTAINER &&
      speakerLabel?.font?.location &&
      speakerLabel?.font?.name
    ) {
      loadFont({
        family: speakerLabel.font.name,
        url: speakerLabel.font.location
      })
        .then(() => {
          // eslint-disable-next-line no-console
          console.log('Font loaded - ', speakerLabel.font?.name);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error loading font - ', speakerLabel.font?.name, speakerLabel.font?.location, error);
        })
        .finally(() => {
          continueRender(handle);
        });
    } else {
      continueRender(handle);
    }
  }, [speakerLabel?.type]);

  if (!speakerLabel) return null;

  return (
    <div style={speakerLabel?.style}>
      {speakerLabel?.type === SpeakerLabelElementType.TEXT
        ? speakerLabel?.content
        : speakerLabel?.elements?.map((child, index) => <SpeakerLabel speakerLabel={child} key={index} />)}
    </div>
  );
}
