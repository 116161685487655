import { MediaContent } from '@goldcast/api/content';
import { ASSETS_FILE_PATH, ContentStatesEnum, ContentError } from './constants';
import { getEnvConfig } from '@/constants';
import { ContentUploadError } from '@/stores/contentUploadStore';
import { PUSHER_STATE_MESSAGES } from '@/context/PusherContext/PusherContextConstants';

export const getUploadedVideoUrl = (contentId: string, projectId: string, isAudio: boolean) => {
  return `${getEnvConfig('CLOUDFRONT_STATIC_ASSET_URL')}${ASSETS_FILE_PATH}/${projectId}/${contentId}.${
    isAudio ? 'mp3' : 'mp4'
  }`;
};

export const getContentUploadedState = (mediaContent: MediaContent) => {
  let contentState: ContentStatesEnum = ContentStatesEnum.Uploading;
  let error: ContentUploadError | undefined;

  switch (mediaContent.batch_status) {
    case 'PROCESSING':
      contentState = ContentStatesEnum.Processing;
      break;
    case 'DONE':
      const isVideo = mediaContent.media_type === 'VIDEO';

      if (
        ['PROCESSING', null].includes(mediaContent.clip_generation_status) &&
        mediaContent.embedding_generation_status !== null
      ) {
        contentState = ContentStatesEnum.Generating;
      } else if (isVideo && mediaContent.ftux_facial_recognition_status === 'PROCESSING') {
        contentState = ContentStatesEnum.Generating;
      } else if (
        mediaContent.clip_generation_status === 'DONE' ||
        mediaContent.clip_generation_status === 'LIMIT_REACHED' ||
        (!mediaContent.embedding_generation_status && !mediaContent.clip_generation_status)
      ) {
        if (isVideo && ['DONE', 'NOT_APPLICABLE', null].includes(mediaContent.ftux_facial_recognition_status)) {
          contentState = ContentStatesEnum.ContentReadyForPreview;
        } else if (!isVideo) {
          contentState = ContentStatesEnum.ContentReadyForPreview;
        }
      }
      break;
    case 'FAILED':
      const errorType = `TRANSCRIPTION_UPDATED:${mediaContent.reason}`;
      contentState = ContentStatesEnum.Processing;
      error = { type: ContentError.PROCESSING_FAILED, message: PUSHER_STATE_MESSAGES[errorType] };
      break;
    default:
      if (mediaContent.clip_generation_status === 'FAILED' || mediaContent.embedding_generation_status === 'FAILED') {
        const errorType = `CLIP_GENERATION_STATUS:${mediaContent.reason}`;
        contentState = ContentStatesEnum.Generating;
        error = { type: ContentError.GENERATING_FAILED, message: PUSHER_STATE_MESSAGES[errorType] };
      }
  }

  return { contentState, error };
};

export const getImportVideoUrl = (projectId: string, contentId: string) => {
  return `${getEnvConfig('CUSTOM_UPLOAD_URL')}${projectId}/${contentId}/local.m3u8`;
};

export const getRemainingProcessingTimeInSec = (uploadedTime: Date, remainingSeconds: number) => {
  let currentTime = new Date();
  const endTimestamp = uploadedTime.getTime() / 1000;
  const currentTimestamp = currentTime.getTime() / 1000;
  const elapsedSeconds = currentTimestamp - endTimestamp;
  return Math.floor(remainingSeconds - elapsedSeconds);
};

export function getImportLinkType(url: string): 'YouTube' | 'Zoom' | 'Wistia' | 'Vimeo' | 'Hosted URL' {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  const zoomRegex = /^(https?:\/\/)?(www\.)?zoom\.us\/.+$/;
  const wistiaRegex = /^(https?:\/\/)?(www\.)?(wistia\.com|wi\.st)\/.+$/;
  const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/.+$/;

  if (youtubeRegex.test(url)) {
    return 'YouTube';
  } else if (zoomRegex.test(url)) {
    return 'Zoom';
  } else if (wistiaRegex.test(url)) {
    return 'Wistia';
  } else if (vimeoRegex.test(url)) {
    return 'Vimeo';
  } else {
    return 'Hosted URL';
  }
}
