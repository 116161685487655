import { AiClipPromptOption } from './types';

export const SEARCH_OPTIONS: AiClipPromptOption[] = [
  {
    icon: 'IconPlayerPlay',
    label: 'Find new clips about …',
    type: 'CLIP',
    description: 'Clips'
  },
  {
    icon: 'IconThumbUp',
    label: 'Generate social posts about …',
    type: 'SOCIAL',
    description: 'Social Posts'
  },
  {
    icon: 'IconArticle',
    label: 'Generate blog posts about …',
    type: 'BLOG',
    description: 'Blog Posts'
  }
];

export enum AI_SEARCH_ERROR_CODES {
  CLIP_NOT_EXIST_FOR_SEARCH_TERM = 'CLIP_NOT_EXIST_FOR_SEARCH_TERM',
  RELEVANT_SOURCES_NOT_FOUND = 'RELEVANT_SOURCES_NOT_FOUND'
}

export const SELECTION_ROUTE_MAPPING = {
  CLIP: 'clips',
  SOCIAL: 'social',
  BLOG: 'blog'
};
