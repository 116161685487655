import { memo } from 'react';
import { IconBrandYoutube, IconCalendar, IconCalendarEvent, IconLoader2 } from '@tabler/icons-react';
import Button from '@/components/atoms/Button/Button';
import usePublishAsset from '@/hooks/usePublishAsset';

function ClipPublishToLibraryDialog({
  onClose,
  isClipAddedToLibrary
}: {
  onClose: () => void;
  isClipAddedToLibrary: boolean;
}) {
  const { publishAsset, isPublishing } = usePublishAsset();

  const handlePublish = async () => {
    await publishAsset();
    onClose();
  };
  return (
    <div className="relative flex flex-col overflow-y-auto overflow-x-hidden">
      <div className="grow px-5 pb-5">
        <div className="grow pt-0.5">
          <h1 className="font-medium">{isClipAddedToLibrary ? 'Publish' : 'Publish to a new destination'}</h1>
          <div className="mt-1.5 w-full text-sm leading-tight text-slate-600">
            {!isClipAddedToLibrary
              ? `This video is currently not in use anywhere. Click publish to add it to Video Library and easily use it
            later in any of the following destinations.`
              : 'This video is currently not in use anywhere. Click publish to sync changes to Video Library and easily use it later in any of the following destinations.'}
          </div>

          <div className="mt-5">
            <div className="group flex w-full items-center space-x-3 rounded-lg p-2 py-3 pl-3 text-left hover:bg-slate-100">
              <IconCalendar />
              <div className="grow truncate">
                <div className="text-sm font-medium leading-tight">Webinar</div>
                <div className="mt-0.5 truncate text-xs leading-tight text-slate-600">
                  Single session with post-event AI content
                </div>
              </div>
            </div>
            <div className="group flex w-full items-center space-x-3 rounded-lg p-2 py-3 pl-3 text-left hover:bg-slate-100">
              <IconCalendarEvent />
              <div className="grow truncate">
                <div className="text-sm font-medium leading-tight">Event</div>
                <div className="mt-0.5 truncate text-xs leading-tight text-slate-600">
                  Multiple sessions with breakout spaces
                </div>
              </div>
            </div>
            <div className="group flex w-full items-center space-x-3 rounded-lg p-2 py-3 pl-3 text-left hover:bg-slate-100">
              <IconBrandYoutube />
              <div className="grow truncate">
                <div className="flex items-center">
                  <div className="text-sm font-medium leading-tight">Pages</div>
                  <div className="ml-1 flex rounded-xl bg-orange-100 px-1.5 py-0.5 text-2xs text-orange-600">
                    Coming soon
                  </div>
                </div>
                <div className="mt-0.5 truncate text-xs leading-tight text-slate-600">
                  Customizable Pages with videos or event
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-3 border-t border-t-slate-200 px-5 pb-3 pt-2.5">
        <Button variation="outline" trackingId="cancel-publishing" onClick={onClose}>
          Cancel
        </Button>
        <Button variation="filled" trackingId="publish-content" disabled={isPublishing} onClick={handlePublish}>
          {isClipAddedToLibrary ? 'Publish and update' : 'Publish changes'}
          {isPublishing && <IconLoader2 className="ml-2 animate-spin" size={16} />}
        </Button>
      </div>
    </div>
  );
}

export default memo(ClipPublishToLibraryDialog);
