import React, { memo, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { brandTemplateList } from '@goldcast/api/content';
import { pick, isEqual } from 'radash';
import { useQuery } from 'react-query';
import SpeakersTimelineZoom from './SpeakersTimelineZoom';
import {
  ClipPlayerSection,
  useIntroRefReturn,
  useMainSectionRefReturn,
  useOutroRefReturn
} from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import SpeakersTimelinePlaybackControls from './SpeakersTimelinePlaybackControls';
import ClipSpeakersTimelineView from './ClipSpeakersTimelineView';
import { PreviewTemplate } from '../../SideBar/types';
import { mapBrandedTemplatesResponse, propertiesToCompare } from '../../SideBar/Templates/utils';
import useClipTranscriptPlayPause from '../ClipPlayerControls/useClipTranscriptPlayPause';
import Button from '@/components/atoms/Button/Button';
import PlayerToggle from '@/Pages/TranscriptPage/PlayerControls/PlayerToggle';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';
import { resetFullClipChanges } from '@/libs/fullRecordingEdit';
import usePlayerControls from '@/Pages/TranscriptPage/PlayerControls/usePlayerControls';
import { isEmptyObject, removeUndefinedKeys } from '@/libs/utils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import useIsScrollingElement from '@/hooks/useIsScrollingElement';
import { useAppContext } from '@/context/AppContext/AppContext';
import { core } from '@/stores/core';
import { LayoutType } from '@/domains/asset';
import { shouldUseNewPlayerConfig, shouldUseNewRecordingsPlayer } from '@/stores/clip';
import Loader from '@/components/atoms/Loader';

function ClipSpeakersTimeline({
  outroPlayerProps,
  mainPlayerProps,
  introPlayerProps,
  currentSection,
  setCurrentSectionAndPlay
}: {
  outroPlayerProps: useOutroRefReturn;
  mainPlayerProps: useMainSectionRefReturn;
  introPlayerProps: useIntroRefReturn;
  currentSection: ClipPlayerSection;
  setCurrentSectionAndPlay: (section: ClipPlayerSection, shouldPlay: boolean, time?: number) => void;
}) {
  const { clipId, clipData } = useClipsContext();
  const { isTimelineCollapsed } = usePlayerControls();
  const timelineContainerRef = useRef<HTMLDivElement>(null);
  const cuePointerRef = useRef<HTMLDivElement>(null);
  const { logger } = useAppContext();
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const {
    isOpen: isResetChangesDialogOpen,
    openDialog: openResetChangesDialog,
    closeDialog: closeResetChangesDialog
  } = useDialog();

  const { pauseCurrentSection } = useClipTranscriptPlayPause({
    currentSection,
    introPlayerProps,
    mainPlayerProps,
    outroPlayerProps
  });

  const [zoomValue, setZoomValue] = useState(1);
  const { isScrolling, handleOnScroll } = useIsScrollingElement();
  const [defaultClipTemplate, setDefaultClipTemplate] = useState<PreviewTemplate | undefined>(undefined);
  const [revertingChanges, setRevertingChanges] = useState(false);

  const { isError: templateFetchError } = useQuery({
    queryKey: [],
    queryFn: () => {
      const filterLayout: LayoutType = coreStore.content?.media_type === 'AUDIO' ? 'AUDIOGRAM' : 'DEFAULT';
      return brandTemplateList({
        queryParams: {
          // Need to make is false once backend fix filter check.
          has_organization: true,
          limit: 10,
          layout: [filterLayout]
        }
      });
    },
    onSuccess: data => {
      const results = mapBrandedTemplatesResponse(clipId, data.results || []);
      const defaultTemplate = results.find(result => result.default === coreStore.content?.media_type);
      if (defaultTemplate) {
        setDefaultClipTemplate(defaultTemplate);
      } else {
        logger.error('Failed to find any default template in brand template list');
      }
    }
  });

  function resetFullRecordingChanges() {
    pauseCurrentSection();
    setRevertingChanges(true);
    resetFullClipChanges(clipId);
    closeResetChangesDialog();
  }
  useEffect(() => {
    setRevertingChanges(false);
  }, [clipData.updated_at]);

  useEffect(() => {
    const useNewPlayerConfig = shouldUseNewPlayerConfig(clipData);
    if (useNewPlayerConfig) return;

    if (!mainPlayerProps.mainSectionRef.current) return;

    const dispatchComputeFrame = () => {
      EventBus.dispatch(CustomEvents.ComputeFrame);
    };
    mainPlayerProps.mainSectionRef.current?.addEventListener('seeked', dispatchComputeFrame);

    return () => {
      mainPlayerProps.mainSectionRef.current?.removeEventListener('seeked', dispatchComputeFrame);
    };
  }, []);

  useEffect(() => {
    if (templateFetchError) {
      logger.error('Error while fetching branch template list');
    }
  }, [templateFetchError]);

  const isClipMetadataDifferentFromTemplate = useMemo(() => {
    if (!defaultClipTemplate) return false;

    const templateProperties = removeUndefinedKeys(pick(defaultClipTemplate, propertiesToCompare));
    const clipProperties = removeUndefinedKeys(pick(clipData.asset_metadata, propertiesToCompare));
    return !isEqual(templateProperties, clipProperties);
  }, [defaultClipTemplate, clipData.asset_metadata]);

  const isResetChangesVisible = useMemo(() => {
    if (
      shouldUseNewRecordingsPlayer({
        event_type: clipData.content?.event_type,
        live_end_time: clipData.content?.live_end_time,
        individual_recording_status: clipData.content?.individual_recording_status
      })
    ) {
      return false;
    }

    return (
      !isEmptyObject(clipData.asset_metadata.deletes || {}) ||
      !isEmptyObject(clipData.asset_metadata.edits || {}) ||
      !!clipData.asset_metadata.merges?.length ||
      !!clipData.asset_metadata.intro ||
      !!clipData.asset_metadata.outro ||
      isClipMetadataDifferentFromTemplate
    );
  }, [
    clipData.asset_metadata.deletes,
    clipData.asset_metadata.edits,
    clipData.asset_metadata.merges?.length,
    clipData.asset_metadata.intro,
    clipData.asset_metadata.outro,
    isClipMetadataDifferentFromTemplate,
    clipData.content?.event_type,
    clipData.content?.live_end_time
  ]);

  useEffect(() => {
    if (isScrolling) return;
    if (!cuePointerRef.current || !timelineContainerRef.current) return;

    const cuePointerDistance =
      cuePointerRef.current.getBoundingClientRect().left - timelineContainerRef.current.clientWidth;
    if (cuePointerDistance > 0 && cuePointerDistance < 20) {
      cuePointerRef.current.scrollIntoView({ inline: 'start' });
    }
  }, [mainPlayerProps.mainSectionCurrentTime, isScrolling]);

  return (
    <div
      className="mb-3 w-full shrink-0 overflow-x-auto overflow-y-hidden rounded-2xl bg-white px-1 py-3 shadow-lg"
      ref={timelineContainerRef}
      onScroll={handleOnScroll}
    >
      <div className="sticky left-0 mb-3 grid w-full grid-cols-3 justify-between px-1">
        <div className="flex h-9 w-72 cursor-pointer items-center justify-start gap-4 rounded-sm">
          {isResetChangesVisible && (
            <Button
              variation="filled"
              buttonSize="small"
              disabled={revertingChanges}
              trackingId="reset-full-edit-changes"
              onClick={openResetChangesDialog}
              className="flex gap-2"
            >
              {revertingChanges ? 'Reverting...' : 'Revert changes'}
              {revertingChanges && <Loader size="xs" />}
            </Button>
          )}
        </div>
        <SpeakersTimelinePlaybackControls
          currentSection={currentSection}
          introPlayerProps={introPlayerProps}
          outroPlayerProps={outroPlayerProps}
          mainPlayerProps={mainPlayerProps}
          setCurrentSectionAndPlay={setCurrentSectionAndPlay}
        />
        <div className="flex justify-end gap-4">
          <SpeakersTimelineZoom zoomValue={zoomValue} onChange={setZoomValue} />
          <PlayerToggle />
        </div>
      </div>
      <ClipSpeakersTimelineView
        currentSection={currentSection}
        introPlayerProps={introPlayerProps}
        outroPlayerProps={outroPlayerProps}
        mainPlayerProps={mainPlayerProps}
        cuePointerRef={cuePointerRef}
        visible={!isTimelineCollapsed}
        setCurrentSectionAndPlay={setCurrentSectionAndPlay}
        zoom={zoomValue}
      />
      <ConfirmationDialog
        isOpen={isResetChangesDialogOpen}
        onClose={closeResetChangesDialog}
        onConfirm={resetFullRecordingChanges}
        title="Revert Changes"
        content="Are you sure you want to revert to the original version of this video? Your current changes will be lost."
        confirmLabel="Revert Changes"
      />
    </div>
  );
}

export default memo(ClipSpeakersTimeline);
